import React, {useEffect, useState} from 'react'
import Module from './Module';
import axios from 'axios';
import Config from '../../util/config';

const Modules = () => {
    const [modules, setModules] = useState([]);
    
    const getModules = () => {
        axios.get(Config.apiserver+"modules")
            .then(res => {
                setModules(res.data.data);
            })
            .catch(error => console.log(error))
    }
    
    useEffect(() => {
        getModules()
    }, []);
    
    return (
        <>
            <main id="content" role="main">
                {/* Hero Section */}
                <div className="position-relative bg-primary overflow-hidden my-4">
                    {/* SVG Shapes */}
                    <figure className="position-absolute top-0 left-0 w-60">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1246 1078">
                            <g opacity=".4">
                                <linearGradient id="doubleEllipseTopLeftID1" gradientUnits="userSpaceOnUse" x1="2073.5078" y1="1.7251" x2="2273.4375" y2="1135.5818" gradientTransform="matrix(-1 0 0 1 2600 0)">
                                    <stop offset="0.4976" style={{ stopColor: '#559bff' }} />
                                    <stop offset={1} style={{ stopColor: '#377DFF' }} />
                                </linearGradient>
                                <polygon fill="url(#doubleEllipseTopLeftID1)" points="519.8,0.6 0,0.6 0,1078 863.4,1078   " />
                                <linearGradient id="doubleEllipseTopLeftID2" gradientUnits="userSpaceOnUse" x1="1717.1648" y1="3.779560e-05" x2="1717.1648" y2="644.0417" gradientTransform="matrix(-1 0 0 1 2600 0)">
                                    <stop offset="1.577052e-06" style={{ stopColor: '#559bff' }} />
                                    <stop offset={1} style={{ stopColor: '#377DFF' }} />
                                </linearGradient>
                                <polygon fill="url(#doubleEllipseTopLeftID2)" points="519.7,0 1039.4,0.6 1246,639.1 725.2,644   " />
                            </g>
                        </svg>
                    </figure>
                    <figure className="position-absolute right-0 bottom-0 left-0 mb-n1">
                        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                            <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                        </svg>
                    </figure>
                    {/* End SVG Shapes */}
                </div>
                {/* End Hero Section */}
                {/* Clients Section */}
                <div className="border-bottom pb-4 pt-0">
                    <div className="container">
                        <div className="w-lg-80 mx-lg-auto">
                            <div className="row justify-content-center">
                                <h1>List Of Modules</h1>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Clients Section */}
                {/* Features Section */}
                <div id="featuresSection" className="container my-5">
                    <div className="row align-items-lg-center">
                        {
                            modules.map((item, index) =>
                                <Module key={index} link={item.mdl_id} icon={item.mdl_icon} title={item.mdl_name} />
                            )
                        }

                    </div>
                </div>
                {/* End Features Section */}
            </main>
        </>
    )
}

export default Modules
