import React from 'react'

const Breadcrumb = () => {
    return (
        <>
            <nav className="d-lg-none border-bottom">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col">
                            <ol className="breadcrumb breadcrumb-links px-0">
                                <li className="breadcrumb-item"><a href="https://webpixels.io/docs/purpose-css/1.0/getting-started/installation" className>Docs</a></li>
                                <li className="breadcrumb-item active" aria-current="page"><span className="text-muted">Installation</span></li>
                            </ol>
                        </div>
                        <div className="col-auto">
                            <div className="navbar-light">
                                <button className="navbar-toggler mr-n3" type="button" data-toggle="collapse" data-target="#docsSidenavCollapse" aria-controls="docsSidenavCollapse" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon" /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Breadcrumb
