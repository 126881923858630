import React from 'react'
import { Routes, Route } from "react-router-dom";

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import Home from './pages/Home';
import Details from './pages/Details';
import NotFound from './pages/NotFound';

const App = () => {
	return (
		<>
			<Header />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/module/:id" element={<Details />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
			<div className="customFooter">
				<Footer />
			</div>
		</>
	)
}

export default App
