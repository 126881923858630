import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Logo/Logo'

const Header = () => {
    return (
        <header className="header" id="header-main">
            <nav className="navbar navbar-main navbar-expand-lg navbar-light bg-white border-bottom py-1" id="navbar-main">
                <div className="container justify-content-between">
                    <Link className="navbar-brand" to="/">
                        <div style={{ width: "220px" }}>
                            <Logo />
                        </div>
                    </Link>
                    <a href="https://support.creativeitsoft.net" target="_blank" rel="noreferrer" className="btn btn-sm btn-primary ml-4 btn-icon d-lg-inline-flex">
                        <span className="btn-inner--text">Support</span>
                    </a>
                </div>
            </nav>
        </header>

    )
}

export default Header
