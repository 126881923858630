module.exports = {
	footerText: 'All rights reserved by - The Church of Pentecost U.S.A., Inc',
	// apiserver: 'https://fmsstaginapi.yottaerp.com/api/',
	// baseurl: 'https://fmsstaginapi.yottaerp.com',
	
	apiserver: 'https://fmsapi.yottaerp.com/api/',

	// baseurl: 'https://fms.yottaerp.com/',

	// baseurl: 'http://localhost:3000/',
	// apiserver: 'http://localhost:8000/api/',
	//
	// baseurl: 'https://cophqapi.yottaerp.com/',
	// apiserver: 'https://cophqapi.yottaerp.com/',
	//
	// profile_pic_url: 'http://localhost:8000/upload/profile/',
	profile_pic_url: 'https://fmsapi.yottaerp.com/public/upload/profile/',
	
	logourl : '/assets/images/coplogo.png',
	print_logourl : '/assets/images/cop_nlogo.png',
	company_name : 'The Church of Pentecost U.S.A., Inc',
	address : 'Wayne, New Jersey, USA',
	city : 'Wayne',
	state : 'New Jersey',
	zipcode : '11111',
	phone : "",
	email : "info@copusa.org",
	website : "https://copusa.org"
}