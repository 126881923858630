import React, {useEffect, useState} from 'react'
import Breadcrumb from '../components/Docs/Breadcrumb'
import DocContent from '../components/Docs/DocContent'
import axios from "axios";
import Config from "../util/config";
import {Link, useParams} from "react-router-dom";

const Details = () => {
    const params = useParams();
    const [data, setData] = useState([]);
    const [id, setID] = useState({});
    
    const getModuleData = () => {
        axios.get(Config.apiserver+"modules/"+ params.id)
            .then(res => {
                console.log(res.data.data)
                setData(res.data.data);
            })
            .catch(error => console.log(error))
    }
    
    useEffect(() => {
        console.log(data);
        getModuleData()
    }, []);
    
    const getArticleData = (module_id,article_id) => {
        setID({
            mdl_id: module_id,
            article_id: article_id
        })
    }
    
    useEffect(() => {
        getArticleData(params.id, 1)
    }, []);
    
    return (
        <>
            <Breadcrumb />
            <div className="container py-4 py-lg-5">
                <div className="row">
                    {/*<SideMenu />*/}
    
                    <div className="col-12 col-lg-3">
                        <Link to={`/`} className="btn btn-sm btn-primary btn-icon d-lg-inline-flex"><i className="icon icon-arrow-left" style={{ margin: "2px 10px 0 0"}} />Back To Home</Link>
                        <div className="docs-sidebar pr-5 position-lg-sticky top-lg-6">
                            <div className="d-lg-block collapse" id="docsSidenavCollapse">
                                {data.map((item, index) =>
                                        <div key={index}>
                                            <h6 className="mt-4">{ item.sub_module_name }</h6>
                                            <ul className="nav flex-column">
                                                {
                                                    item.articles.map((article, i) =>
                                                        <li className="nav-item" key={i}>
                                                            {/*<Link to={action.act_id} class="nav-link">{action.act_name}</Link>*/}
                                                            <button
                                                                className="btn btn-secondary btn-sm"
                                                                style={{ background:"#fff", border:"#fff", padding:"0 0 0 10px", color:"#4a5568" }}
                                                                onClick={() => getArticleData(item.module_id, article.id)}
                                                            >
                                                                {article.title}
                                                            </button>
                                                        </li>
                                                    )
                                                }
                                                
                                            </ul>
                                        </div>
                                    )}
                                
                            </div>
                        </div>
                    </div>
                    {
                        Object.keys(id).length !== 0 && (
                            <DocContent data={id}/>
                        )
                    }
    
                </div>
            </div>
        </>
    )
}

export default Details
