import React from 'react'

const Logo = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 572.5 119" style={{ enableBackground: 'new 0 0 572.5 119' }} xmlSpace="preserve">
            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:#072F4C;}\n\t.st1{fill:#FFFFFF;}\n\t.st2{fill:#293793;}\n\t.st3{fill:#F1E606;}\n\t.st4{fill:#FFFFFF;stroke:#2B3187;stroke-miterlimit:10;}\n\t.st5{fill-rule:evenodd;clip-rule:evenodd;fill:#2B3187;}\n\t.st6{fill:none;stroke:#EB2227;stroke-width:2;}\n\t.st7{fill:none;stroke:#2B3187;stroke-width:0.5;stroke-miterlimit:10;}\n\t.st8{fill:none;stroke:#2A2A85;stroke-miterlimit:10;}\n\t.st9{fill:#ED1C24;}\n\t.st10{fill:#ED1C24;stroke:#ED1C24;stroke-width:3;stroke-miterlimit:10;}\n" }} />
            <g>
                <path className="st0" d="M34.2,104.6c-7.7-4.1-13.7-9.9-18.1-17.2c-4.4-7.3-6.6-15.6-6.6-24.8c0-9.2,2.2-17.5,6.6-24.8
              s10.4-13.1,18.1-17.2c7.7-4.1,16.3-6.2,25.9-6.2c8.1,0,15.4,1.4,21.9,4.3c6.5,2.8,12,6.9,16.4,12.3L84.6,43.7
              c-6.3-7.3-14.1-10.9-23.4-10.9c-5.8,0-10.9,1.3-15.5,3.8s-8.1,6.1-10.6,10.6c-2.5,4.5-3.8,9.7-3.8,15.5c0,5.8,1.3,10.9,3.8,15.5
              c2.5,4.5,6.1,8.1,10.6,10.6c4.5,2.5,9.7,3.8,15.5,3.8c9.3,0,17.1-3.7,23.4-11.1l13.9,12.8c-4.4,5.4-9.9,9.5-16.5,12.4
              c-6.6,2.8-13.9,4.3-22,4.3C50.5,110.8,41.9,108.8,34.2,104.6z" />
                <path className="st0" d="M172.2,20.1c6.1,2.8,10.8,6.7,14.1,11.7c3.3,5.1,4.9,11.1,4.9,18c0,6.8-1.6,12.8-4.9,17.9
              c-3.3,5.1-8,9-14.1,11.7c-6.1,2.7-13.3,4.1-21.5,4.1h-18.8v25.7h-21.6V16h40.4C158.9,16,166.1,17.4,172.2,20.1z M164.2,61.8
              c3.4-2.8,5.1-6.8,5.1-11.9c0-5.2-1.7-9.3-5.1-12.1c-3.4-2.8-8.3-4.2-14.8-4.2h-17.6v32.4h17.6C155.9,65.9,160.9,64.5,164.2,61.8z" />
            </g>
            <circle className="st1" cx="64.8" cy="61.8" r="33.7" />
            <g>
                <path className="st2" d="M65.1,92.6c19.4,0,31.3-17.6,30.4-31.3l-61.2,0.1C33.1,74,44.9,92.6,65.1,92.6z" />
                <path className="st3" d="M64.8,31.2c-15.9,0-30.9,13.2-30.5,30.2l61-0.1C96.1,44.6,80.7,31.2,64.8,31.2z" />
                <path className="st4" d="M88.6,61.5c0,13-10.5,23.5-23.5,23.5c-13,0-23.5-10.5-23.5-23.5c0-13,10.5-23.5,23.5-23.5
              C78.1,38,88.6,48.5,88.6,61.5z" />
                <path className="st5" d="M64.8,53.4l0.4,0.9c0,0,0.4-0.9,0.4-0.9c1-0.8,0.4-1.9,0.4-1.9c3.1,0.1,3.5-2.3,3.5-2.3
              c-2.7,0.9-3.5,0.3-3.5,0.3c-0.3-2.5,0.8-3.9,0.8-3.9s-1.7,1-3.3,0c0,0,1.1,1.4,0.8,3.9c0,0-0.8,0.6-3.5-0.3c0,0,0.4,2.4,3.5,2.3
              C64.4,51.5,63.9,52.6,64.8,53.4c0,0,0.4,0.9,0.4,0.9l0.4-0.9" />
                <g>
                    <line className="st6" x1="65.4" y1="43.1" x2="65.4" y2="44.1" />
                    <line className="st6" x1="65.4" y1={56} x2="65.4" y2="57.1" />
                    <line className="st6" x1="64.5" y1="43.1" x2="64.7" y2="44.2" />
                    <line className="st6" x1="66.1" y1={56} x2="66.3" y2={57} />
                    <line className="st6" x1="63.7" y1="43.3" x2={64} y2="44.3" />
                    <line className="st6" x1="66.9" y1="55.8" x2="67.1" y2="56.9" />
                    <line className="st6" x1="62.9" y1="43.6" x2="63.2" y2="44.5" />
                    <line className="st6" x1="67.6" y1="55.6" x2={68} y2="56.6" />
                    <line className="st6" x1="62.1" y1="43.9" x2="62.6" y2="44.9" />
                    <line className="st6" x1="68.2" y1="55.3" x2="68.7" y2="56.2" />
                    <line className="st6" x1="61.3" y1="44.4" x2={62} y2="45.2" />
                    <line className="st6" x1="68.9" y1="54.9" x2="69.5" y2="55.8" />
                    <line className="st6" x1="60.7" y1="44.9" x2="61.4" y2="45.7" />
                    <line className="st6" x1="69.4" y1="54.5" x2="70.2" y2="55.2" />
                    <line className="st6" x1="60.1" y1="45.6" x2="60.9" y2="46.2" />
                    <line className="st6" x1={70} y1="53.9" x2="70.8" y2="54.6" />
                    <line className="st6" x1="59.5" y1="46.3" x2="60.4" y2="46.8" />
                    <line className="st6" x1="70.4" y1="53.3" x2="71.3" y2="53.9" />
                    <line className="st6" x1="59.1" y1={47} x2="60.1" y2="47.5" />
                    <line className="st6" x1="70.8" y1="52.7" x2="71.7" y2="53.1" />
                    <line className="st6" x1="58.8" y1="47.8" x2="59.8" y2="48.2" />
                    <line className="st6" x1={71} y1={52} x2={72} y2="52.3" />
                    <line className="st6" x1="58.6" y1="48.7" x2="59.6" y2="48.9" />
                    <line className="st6" x1="71.2" y1="51.3" x2="72.3" y2="51.5" />
                    <line className="st6" x1="58.4" y1="49.5" x2="59.5" y2="49.6" />
                    <line className="st6" x1="71.3" y1="50.6" x2="72.4" y2="50.6" />
                    <line className="st6" x1="58.4" y1="50.4" x2="59.5" y2="50.3" />
                    <line className="st6" x1="71.4" y1="49.8" x2="72.4" y2="49.8" />
                    <line className="st6" x1="58.5" y1="51.2" x2="59.5" y2="51.1" />
                    <line className="st6" x1="71.3" y1="49.1" x2="72.3" y2="48.9" />
                    <line className="st6" x1="58.7" y1="52.1" x2="59.7" y2="51.8" />
                    <line className="st6" x1="71.1" y1="48.4" x2="72.1" y2="48.1" />
                    <line className="st6" x1={59} y1="52.9" x2={60} y2="52.5" />
                    <line className="st6" x1="70.9" y1="47.7" x2="71.8" y2="47.3" />
                    <line className="st6" x1="59.4" y1="53.7" x2="60.3" y2="53.1" />
                    <line className="st6" x1="70.5" y1={47} x2="71.4" y2="46.5" />
                    <line className="st6" x1="59.9" y1="54.4" x2="60.7" y2="53.7" />
                    <line className="st6" x1="70.1" y1="46.4" x2="70.9" y2="45.8" />
                    <line className="st6" x1="60.5" y1={55} x2="61.2" y2="54.3" />
                    <line className="st6" x1="69.6" y1="45.9" x2="70.3" y2="45.1" />
                    <line className="st6" x1="61.1" y1="55.6" x2="61.8" y2="54.8" />
                    <line className="st6" x1="69.1" y1="45.4" x2="69.7" y2="44.5" />
                    <line className="st6" x1="61.8" y1="56.1" x2="62.4" y2="55.2" />
                    <line className="st6" x1="68.4" y1={45} x2={69} y2="44.1" />
                    <line className="st6" x1="62.6" y1="56.5" x2={63} y2="55.5" />
                    <line className="st6" x1="67.8" y1="44.6" x2="68.2" y2="43.7" />
                    <line className="st6" x1="63.4" y1="56.8" x2="63.7" y2="55.8" />
                    <line className="st6" x1="67.1" y1="44.4" x2="67.4" y2="43.4" />
                    <line className="st6" x1="64.3" y1={57} x2="64.5" y2={56} />
                    <line className="st6" x1="66.4" y1="44.2" x2="66.5" y2="43.2" />
                </g>
                <g>
                    <path className="st5" d="M57.1,58.2c0-0.1,0.2,0,0.3-0.1c0,0,0,0.1-0.1,0.1C57.3,58.2,57.2,58.2,57.1,58.2z" />
                    <path className="st5" d="M59.6,58.2c0-0.1,0.3-0.1,0.3,0C59.8,58.2,59.7,58.2,59.6,58.2z" />
                    <path className="st5" d="M56.3,58.2c0-0.1,0.1,0,0.2-0.1c0,0,0,0,0,0.1C56.5,58.2,56.4,58.2,56.3,58.2z" />
                    <path className="st5" d="M56.5,58.2C56.5,58.2,56.5,58.2,56.5,58.2c0.2-0.1,0.4-0.1,0.6-0.1C57,58.3,56.7,58.2,56.5,58.2z" />
                    <path className="st5" d="M59.3,58.2c0-0.1,0.1,0,0.2-0.1c0,0,0,0,0,0.1C59.4,58.2,59.3,58.2,59.3,58.2z" />
                    <path className="st5" d="M59.5,58.2C59.5,58.2,59.5,58.2,59.5,58.2c0.1-0.1,0.1-0.1,0.2-0.1C59.6,58.2,59.5,58.2,59.5,58.2z" />
                    <path className="st5" d="M60.1,58.2c0,0.1-0.2,0.1-0.2,0C60,58.2,60.1,58.2,60.1,58.2z" />
                    <path className="st5" d="M55.7,58.3c0-0.1,0.1,0,0.2-0.1c0,0,0,0,0,0.1C55.8,58.3,55.8,58.3,55.7,58.3z" />
                    <path className="st5" d="M59.1,58.3c0-0.1,0.1,0,0.2-0.1C59.3,58.3,59.1,58.3,59.1,58.3z" />
                    <path className="st5" d="M55.7,58.3c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0.1C55.8,58.3,55.7,58.4,55.7,58.3z" />
                    <path className="st5" d="M56,58.3c0-0.1,0.3-0.1,0.2,0C56.1,58.3,56,58.3,56,58.3z" />
                    <path className="st5" d="M58.3,58.3c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0.1C58.4,58.3,58.3,58.4,58.3,58.3z" />
                    <path className="st5" d="M58.8,58.3C58.8,58.3,58.8,58.3,58.8,58.3c0.1-0.1,0.2-0.1,0.2-0.1C59,58.4,58.9,58.3,58.8,58.3z" />
                    <path className="st5" d="M61.3,58.3c-0.1,0-0.2,0-0.2-0.1C61.2,58.3,61.2,58.3,61.3,58.3C61.3,58.3,61.3,58.3,61.3,58.3z" />
                    <path className="st5" d="M56.8,58.4c0-0.1,0.1-0.1,0.2-0.1C57,58.5,56.9,58.4,56.8,58.4z" />
                    <path className="st5" d="M56.5,58.5c0-0.1,0.1-0.1,0.2-0.1C56.7,58.5,56.6,58.5,56.5,58.5z" />
                    <path className="st5" d="M56.8,58.6c0-0.1,0.2,0,0.3-0.1c0,0,0,0,0,0.1c0,0,0,0-0.1,0C56.9,58.6,56.8,58.6,56.8,58.6z" />
                    <path className="st5" d="M57.1,58.6C57.1,58.5,57.1,58.5,57.1,58.6c0.1-0.1,0.1-0.1,0.2-0.1C57.2,58.6,57.1,58.6,57.1,58.6z" />
                    <path className="st5" d="M56.8,58.6C56.8,58.6,56.8,58.6,56.8,58.6c0.1-0.1,0.2-0.1,0.2-0.1C57,58.6,56.9,58.6,56.8,58.6z" />
                    <path className="st5" d="M72,58.8c0-0.1,0.3-0.1,0.3,0C72.2,58.8,72.1,58.8,72,58.8z" />
                    <path className="st5" d="M70.8,58.8c0-0.1,0.3-0.1,0.2,0C70.9,58.8,70.8,58.8,70.8,58.8z" />
                    <path className="st5" d="M70.4,59c0-0.1,0.1,0,0.2-0.1c0,0,0,0,0.1,0C70.6,59,70.5,59,70.4,59z" />
                    <path className="st5" d="M54.5,59c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0.1C54.7,59,54.5,59.1,54.5,59z" />
                    <path className="st5" d="M54.8,59C54.8,59,54.8,59,54.8,59c0.1,0,0.4-0.1,0.4,0C55.1,59,55,59,54.8,59z" />
                    <path className="st5" d="M54.1,59.1c0-0.1,0.2-0.1,0.2,0C54.2,59.1,54.1,59.1,54.1,59.1z" />
                    <path className="st5" d="M52,59.1c0.1,0,0.1,0.1,0.1,0.1c-0.1,0-0.1,0-0.2,0C52,59.2,52,59.1,52,59.1C52,59.1,52,59.1,52,59.1z" />
                    <path className="st5" d="M57.6,59.1c0.1,0,0.1,0.1,0.1,0.2C57.6,59.3,57.6,59.2,57.6,59.1z" />
                    <path className="st5" d="M51.8,59.2c0-0.1,0.1-0.1,0.2-0.1C52,59.3,51.9,59.2,51.8,59.2z" />
                    <path className="st5" d="M75.5,59.2c0-0.1,0.3-0.1,0.2,0c0,0,0,0-0.1,0C75.6,59.2,75.6,59.2,75.5,59.2C75.5,59.2,75.5,59.2,75.5,59.2z
                  " />
                    <path className="st5" d="M75.5,59.4c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0.1,0C75.6,59.3,75.5,59.3,75.5,59.4z" />
                    <path className="st5" d="M75.7,59.2C75.7,59.2,75.7,59.2,75.7,59.2c0.1,0,0.3,0,0.3,0.1C75.9,59.3,75.7,59.4,75.7,59.2z" />
                    <path className="st5" d="M51.7,59.3c0.1,0,0.1,0.2,0.2,0.1c0,0,0,0,0,0.1c-0.1,0-0.2,0-0.3,0C51.6,59.4,51.7,59.4,51.7,59.3z" />
                    <path className="st5" d="M47.9,59.4c0-0.1,0.3-0.1,0.2,0C48.1,59.4,48,59.4,47.9,59.4z" />
                    <path className="st5" d="M48.1,59.4c0.1,0,0.2,0,0.2,0.1C48.2,59.5,48.1,59.5,48.1,59.4z" />
                    <path className="st5" d="M54.5,59.5c0-0.1,0.2-0.1,0.2,0C54.7,59.5,54.6,59.5,54.5,59.5z" />
                    <path className="st5" d="M65.1,59.4c0,0.1-0.2,0.1-0.2,0C65,59.4,65.1,59.4,65.1,59.4z" />
                    <path className="st5" d="M47.3,59.5c0-0.1,0.1,0,0.2-0.1c0,0,0,0,0,0.1C47.4,59.5,47.3,59.5,47.3,59.5z" />
                    <path className="st5" d="M47.5,59.5C47.5,59.5,47.5,59.5,47.5,59.5c0.1-0.1,0.2-0.1,0.2-0.1C47.7,59.6,47.5,59.5,47.5,59.5z" />
                    <path className="st5" d="M48.4,59.5c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0.1C48.5,59.5,48.4,59.5,48.4,59.5z" />
                    <path className="st5" d="M48.5,59.5C48.5,59.5,48.5,59.5,48.5,59.5c0.1,0,0.3-0.1,0.3,0C48.7,59.5,48.6,59.5,48.5,59.5z" />
                    <path className="st5" d="M54.4,59.5c0.1,0,0.1,0,0.2,0C54.5,59.5,54.4,59.5,54.4,59.5z" />
                    <path className="st5" d="M54.7,59.5c0.1,0,0.2,0,0.2,0.1C54.8,59.5,54.7,59.5,54.7,59.5z" />
                    <path className="st5" d="M77,59.5c0.1,0,0.2,0,0.3,0C77.3,59.6,77,59.6,77,59.5z" />
                    <path className="st5" d="M50.8,59.6c0-0.1,0.3-0.1,0.2,0C50.9,59.6,50.8,59.6,50.8,59.6z" />
                    <path className="st5" d="M78.1,59.6c0-0.1,0.3-0.1,0.2,0C78.2,59.6,78.2,59.6,78.1,59.6z" />
                    <path className="st5" d="M78.8,59.7c0-0.1-0.1-0.1-0.1-0.2c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0.1C78.8,59.6,78.8,59.6,78.8,59.7
                  C78.8,59.7,78.8,59.7,78.8,59.7z" />
                    <path className="st5" d="M60,59.6c0-0.1,0.1-0.1,0.2-0.1C60.2,59.7,60.1,59.6,60,59.6z" />
                    <path className="st5" d="M65.7,59.6c0-0.1,0.2-0.1,0.2,0C65.9,59.6,65.8,59.6,65.7,59.6z" />
                    <path className="st5" d="M79.4,59.6C79.4,59.6,79.4,59.6,79.4,59.6c0.1-0.1,0.2-0.1,0.2,0C79.5,59.6,79.5,59.6,79.4,59.6z" />
                    <path className="st5" d="M49.8,59.6c0,0.1-0.4,0.1-0.4,0C49.5,59.6,49.7,59.6,49.8,59.6z" />
                    <path className="st5" d="M52.5,59.6c0.1,0,0.1,0,0.2,0C52.7,59.7,52.5,59.7,52.5,59.6z" />
                    <path className="st5" d="M59.4,59.7c0-0.1,0.3-0.1,0.2,0C59.5,59.7,59.5,59.7,59.4,59.7z" />
                    <path className="st5" d="M67.5,59.7c0-0.1,0.2-0.1,0.2,0C67.6,59.7,67.6,59.7,67.5,59.7z" />
                    <path className="st5" d="M79.6,59.6c0.1,0,0.2,0,0.2,0.1C79.7,59.7,79.6,59.7,79.6,59.6z" />
                    <path className="st5" d="M52.8,59.8c0-0.1,0.2-0.1,0.2,0C52.9,59.8,52.9,59.8,52.8,59.8z" />
                    <path className="st5" d="M51.5,59.8c0.1,0,0.2,0,0.3,0C51.8,59.9,51.4,59.9,51.5,59.8z" />
                    <path className="st5" d="M52.7,59.8c0.1,0,0.1,0,0.2,0C52.8,59.9,52.7,59.9,52.7,59.8z" />
                    <path className="st5" d="M46.1,60c0-0.1,0.3-0.1,0.2,0C46.3,60,46.2,60,46.1,60z" />
                    <path className="st5" d="M58.6,60c0-0.1,0.1-0.1,0.2-0.1C58.8,60.1,58.7,60,58.6,60z" />
                    <path className="st5" d="M60.6,60c0.1,0,0.1,0,0.2,0C60.8,60.1,60.6,60.1,60.6,60z" />
                    <path className="st5" d="M80.2,60.2c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0.1-0.1,0.1C80.3,60.2,80.2,60.2,80.2,60.2z" />
                    <path className="st5" d="M80.4,60.2C80.4,60.2,80.4,60.2,80.4,60.2c0.1,0,0.1,0.1,0.1,0.1C80.4,60.3,80.3,60.3,80.4,60.2
                  C80.4,60.2,80.4,60.2,80.4,60.2z" />
                    <path className="st5" d="M60.4,60.3c-0.1,0-0.2,0-0.1-0.1C60.3,60.2,60.4,60.2,60.4,60.3z" />
                    <path className="st5" d="M55.5,60.5c0.1,0,0.1,0,0.2,0C55.6,60.6,55.5,60.6,55.5,60.5z" />
                    <path className="st5" d="M47.6,60.8c-0.1,0-0.2,0-0.2-0.1C47.5,60.7,47.6,60.7,47.6,60.8z" />
                    <path className="st5" d="M65.1,60.7c0,0.1-0.2,0.1-0.2,0C64.9,60.7,65,60.7,65.1,60.7z" />
                    <path className="st5" d="M48.1,61.1C48.1,61.1,48.1,61.1,48.1,61.1c0.1-0.1,0.1,0.1,0,0.1C48.1,61.2,48.1,61.2,48.1,61.1z" />
                    <path className="st5" d="M64.7,61.1c0-0.1,0.2-0.1,0.2,0C64.8,61.1,64.8,61.1,64.7,61.1z" />
                    <path className="st5" d="M48.1,61.3c0.1,0,0.1,0.2,0,0.2C48.1,61.4,48.1,61.4,48.1,61.3z" />
                    <path className="st5" d="M62.4,61.9c0.2,0.1-0.1,0.1-0.2,0.1c0,0,0,0,0-0.1C62.3,62,62.4,62,62.4,61.9z" />
                    <path className="st5" d="M62,62C62,62,62,62,62,62c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0.1C62.1,62,62.1,62,62,62z" />
                    <path className="st5" d="M68.2,62.2C68,62.2,68.1,62,68.2,62.2C68.2,62.2,68.2,62.2,68.2,62.2z" />
                    <path className="st5" d="M68.2,62.2C68.2,62.2,68.2,62.2,68.2,62.2c0.1,0,0,0,0.1,0.1c0,0,0,0-0.1,0C68.2,62.3,68.2,62.3,68.2,62.2z" />
                    <path className="st5" d="M56,62.4c0,0.1-0.3,0.1-0.3,0C55.8,62.4,55.9,62.4,56,62.4z" />
                    <path className="st5" d="M52.2,62.7C52.2,62.7,52.2,62.6,52.2,62.7C52.4,62.6,52.4,62.7,52.2,62.7z" />
                    <path className="st5" d="M53,63c0,0,0.1,0,0.1,0C53.3,63,52.9,63.1,53,63z" />
                    <path className="st5" d="M61.3,63c0-0.1,0.2-0.1,0.2,0C61.5,63,61.4,63,61.3,63z" />
                    <path className="st5" d="M61.5,63c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0.1C61.6,63.1,61.5,63.1,61.5,63z" />
                    <path className="st5" d="M61.7,63.1C61.7,63.1,61.7,63,61.7,63.1c0.1-0.1,0.3-0.1,0.4-0.1C62.1,63.1,61.8,63,61.7,63.1z" />
                    <path className="st5" d="M65.6,63.1c0,0.1,0,0.1,0,0.2C65.6,63.3,65.6,63.1,65.6,63.1z" />
                    <path className="st5" d="M66.2,63.3c0-0.1,0.1,0,0.2-0.1c0,0,0,0,0,0.1C66.3,63.3,66.3,63.3,66.2,63.3z" />
                    <path className="st5" d="M66.4,63.3C66.4,63.3,66.4,63.3,66.4,63.3c0.1-0.1,0.2-0.1,0.2,0C66.5,63.3,66.5,63.3,66.4,63.3z" />
                    <path className="st5" d="M52.4,63.3c0.1,0,0.1,0,0.2,0C52.5,63.4,52.4,63.4,52.4,63.3z" />
                    <path className="st5" d="M64.6,63.3c0.1,0,0.1,0.1,0.1,0.2C64.6,63.5,64.6,63.4,64.6,63.3z" />
                    <path className="st5" d="M63.4,63.4c0.1,0,0.1,0.3,0,0.2C63.4,63.6,63.4,63.5,63.4,63.4z" />
                    <path className="st5" d="M67.1,63.5C67.1,63.5,67.1,63.4,67.1,63.5c0.1-0.1,0.1-0.1,0.2-0.1C67.2,63.5,67.1,63.5,67.1,63.5z" />
                    <path className="st5" d="M61.2,63.9C61.2,63.9,61.2,63.9,61.2,63.9c0.1-0.1,0.2-0.1,0.2,0C61.3,63.9,61.3,63.9,61.2,63.9z" />
                    <path className="st5" d="M47.3,64c0.1,0,0.1,0.1,0.1,0.2C47.3,64.2,47.3,64.1,47.3,64z" />
                    <path className="st5" d="M66.7,64.1c0,0.1,0.1,0.3-0.1,0.3C66.7,64.3,66.6,64.1,66.7,64.1z" />
                    <path className="st5" d="M47.6,64.7c0,0.1,0,0.1,0,0.2C47.6,64.9,47.5,64.7,47.6,64.7z" />
                    <path className="st5" d="M65.5,64.8C65.5,64.8,65.5,64.7,65.5,64.8c0.1-0.1,0.2-0.1,0.2,0C65.6,64.8,65.6,64.8,65.5,64.8z" />
                    <path className="st5" d="M65.5,64.8c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0.1C65.6,64.8,65.5,64.9,65.5,64.8z" />
                    <path className="st5" d="M65.7,64.8C65.7,64.8,65.7,64.8,65.7,64.8c0.1-0.1,0.2-0.1,0.2,0c0,0,0,0-0.1,0
                  C65.8,64.8,65.7,64.8,65.7,64.8z" />
                    <path className="st5" d="M52,64.8c0,0.1,0,0.2,0,0.2C51.9,65.1,51.9,64.8,52,64.8z" />
                    <path className="st5" d="M65.8,64.8C65.8,64.8,65.8,64.8,65.8,64.8c0.1,0,0.1,0,0.2,0C66,64.9,65.8,64.9,65.8,64.8z" />
                    <path className="st5" d="M66.4,65c0,0,0-0.1,0-0.1c0.1,0,0.1,0,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.1C66.5,65.1,66.4,65.1,66.4,65z
                  " />
                    <path className="st5" d="M77.4,65C77.4,65,77.4,65,77.4,65c0.1,0,0.2,0,0.2,0.1C77.5,65,77.4,65,77.4,65z" />
                    <path className="st5" d="M48,65.4c0.1,0,0,0.1,0.1,0.2c0,0-0.1,0-0.1-0.1C48,65.4,48,65.4,48,65.4z" />
                    <path className="st5" d="M69.9,65.6c-0.1,0-0.2,0-0.2-0.1C69.7,65.6,69.8,65.6,69.9,65.6z" />
                    <path className="st5" d="M70,65.6c0-0.1,0.3-0.1,0.3,0C70.2,65.6,70.1,65.6,70,65.6z" />
                    <path className="st5" d="M69.9,65.6c0.1,0,0.1,0,0.2,0C70,65.7,69.9,65.7,69.9,65.6z" />
                    <path className="st5" d="M70.3,65.6c0.1,0,0.1,0,0.2,0C70.5,65.7,70.3,65.7,70.3,65.6z" />
                    <path className="st5" d="M52.5,65.7c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0C52.5,65.8,52.5,65.7,52.5,65.7z" />
                    <path className="st5" d="M68.9,65.8c0.1,0,0.1,0,0.2,0C69.1,65.9,68.9,65.9,68.9,65.8z" />
                    <path className="st5" d="M52.6,66c0,0,0.1,0,0.1,0c0,0.1-0.1,0.1-0.2,0.1C52.6,66.1,52.6,66.1,52.6,66z" />
                    <path className="st5" d="M52.1,66.2c0,0,0.1,0,0.1,0.1C52.1,66.4,52.1,66.3,52.1,66.2z" />
                    <path className="st5" d="M50.1,66.6c0-0.1,0.1,0,0.2-0.1C50.2,66.6,50.2,66.6,50.1,66.6z" />
                    <path className="st5" d="M52.7,66.6C52.7,66.6,52.7,66.6,52.7,66.6C52.7,66.6,52.7,66.6,52.7,66.6C52.7,66.7,52.6,66.7,52.7,66.6
                  C52.6,66.6,52.7,66.6,52.7,66.6z" />
                    <path className="st5" d="M60.1,66.6c0.1,0,0.1,0.2,0,0.2C60.1,66.7,60.1,66.6,60.1,66.6z" />
                    <path className="st5" d="M54.1,66.9c-0.1,0-0.1-0.1-0.2-0.1C53.9,66.6,54.1,66.8,54.1,66.9z" />
                    <path className="st5" d="M67.7,66.8C67.8,66.8,67.8,66.8,67.7,66.8c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0-0.1,0
                  C67.7,67,67.7,66.9,67.7,66.8z" />
                    <path className="st5" d="M51,67c0-0.1,0.2-0.1,0.2,0C51.1,67,51.1,67,51,67z" />
                    <path className="st5" d="M67.7,67C67.8,67,67.8,67,67.7,67c0.1,0.1,0.1,0.1,0.1,0.2C67.7,67.2,67.7,67.1,67.7,67z" />
                    <path className="st5" d="M78,67.5c-0.1,0-0.1-0.1-0.1-0.2C78,67.4,78,67.4,78,67.5C78,67.5,78,67.5,78,67.5z" />
                    <path className="st5" d="M68.4,67.7c0-0.1,0.1,0,0.2-0.1C68.6,67.7,68.5,67.7,68.4,67.7z" />
                    <path className="st5" d="M51.8,67.9c0.1,0,0.1,0,0.1,0.1c0,0,0,0,0,0.1c-0.1,0-0.1,0-0.2,0C51.7,67.9,51.8,67.9,51.8,67.9z" />
                    <path className="st5" d="M72.7,68c0,0.1,0,0.1,0,0.2C72.6,68.1,72.6,68,72.7,68z" />
                    <path className="st5" d="M61.4,68.5c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0.1C61.5,68.5,61.5,68.5,61.4,68.5z" />
                    <path className="st5" d="M75.7,69c0,0,0.1-0.1,0.1-0.1C75.9,68.9,75.9,69.2,75.7,69C75.7,69.1,75.7,69,75.7,69z" />
                    <path className="st5" d="M77.9,69.3C77.9,69.3,77.9,69.3,77.9,69.3c0.1,0.1,0.1,0.2,0,0.2C77.9,69.5,77.9,69.4,77.9,69.3z" />
                    <path className="st5" d="M78.1,69.5C78.1,69.5,78.1,69.4,78.1,69.5c0-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0,0.1
                  C78.2,69.5,78.2,69.5,78.1,69.5C78.1,69.5,78.1,69.5,78.1,69.5z" />
                    <path className="st5" d="M56.8,69.6c-0.1,0-0.2,0-0.2-0.1C56.7,69.6,56.8,69.6,56.8,69.6z" />
                    <path className="st5" d="M76.8,69.7C76.8,69.7,76.8,69.7,76.8,69.7c0.1,0,0.2-0.1,0.2,0C76.9,69.7,76.8,69.7,76.8,69.7z" />
                    <path className="st5" d="M77.9,69.9c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0C77.9,69.9,77.9,69.9,77.9,69.9C77.9,70,77.9,70,77.9,69.9z" />
                    <path className="st5" d="M81.9,70.1C81.9,70.1,81.9,70,81.9,70.1C82,70,82,70,82,70C82,70.1,82,70.1,81.9,70.1
                  C81.9,70.1,81.9,70.1,81.9,70.1z" />
                    <path className="st5" d="M76.4,70.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1c-0.1,0-0.1,0-0.2,0C76.4,70.3,76.4,70.3,76.4,70.2z" />
                    <path className="st5" d="M77.9,70.6c0.1,0,0.1,0.1,0,0.1C77.8,70.7,77.9,70.7,77.9,70.6z" />
                    <path className="st5" d="M67.4,71.1c0-0.1,0-0.2,0.1-0.2C67.5,70.9,67.5,71,67.4,71.1C67.4,71.1,67.4,71.1,67.4,71.1z" />
                    <path className="st5" d="M65.3,71c0.1,0,0,0.1,0,0.2C65.2,71.1,65.3,71.1,65.3,71z" />
                    <path className="st5" d="M57.1,71.4c0.1,0,0.1,0.2,0,0.2C57.1,71.5,57.1,71.4,57.1,71.4z" />
                    <path className="st5" d="M63.8,71.8c-0.1,0-0.1-0.4,0-0.3C63.8,71.6,63.8,71.7,63.8,71.8z" />
                    <path className="st5" d="M80.6,71.5C80.6,71.5,80.6,71.5,80.6,71.5c0.1,0.1,0,0.1-0.1,0.1C80.5,71.5,80.6,71.5,80.6,71.5z" />
                    <path className="st5" d="M67.7,72.6c0-0.1,0-0.2,0.1-0.2C67.8,72.4,67.8,72.5,67.7,72.6C67.8,72.6,67.8,72.6,67.7,72.6z" />
                    <path className="st5" d="M67.7,72.6C67.8,72.6,67.8,72.6,67.7,72.6c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1
                  C67.7,72.8,67.7,72.6,67.7,72.6z" />
                    <path className="st5" d="M67.8,72.6c0.1,0,0.1,0.1,0.1,0.2c0,0,0,0-0.1,0C67.8,72.7,67.8,72.6,67.8,72.6z" />
                    <path className="st5" d="M64.1,73c0-0.1,0-0.2,0-0.3C64.3,72.7,64.2,72.9,64.1,73C64.2,73,64.2,73,64.1,73z" />
                    <path className="st5" d="M53.2,73C53.2,73,53.2,73,53.2,73c0.1,0.1,0.1,0.2,0.1,0.3c0,0,0,0-0.1,0C53.2,73.2,53.2,73.1,53.2,73z" />
                    <path className="st5" d="M76.7,73.2c0,0.1,0,0.2,0,0.3C76.6,73.5,76.6,73.2,76.7,73.2z" />
                    <path className="st5" d="M55.9,73.4c0,0.1,0,0.2-0.1,0.2C55.9,73.5,55.8,73.4,55.9,73.4z" />
                    <path className="st5" d="M53.2,73.6c0.1,0,0.1,0.1,0.1,0.2C53.2,73.7,53.2,73.7,53.2,73.6z" />
                    <path className="st5" d="M53.3,73.9c0-0.1,0-0.1,0-0.2C53.3,73.8,53.3,73.8,53.3,73.9C53.3,73.9,53.3,73.9,53.3,73.9z" />
                    <path className="st5" d="M78.3,73.8c0-0.1,0.2,0,0.3-0.1c0,0,0,0,0,0.1C78.4,73.8,78.3,73.8,78.3,73.8z" />
                    <path className="st5" d="M78.5,73.8C78.5,73.8,78.5,73.8,78.5,73.8c0.1,0,0.2-0.1,0.2,0C78.7,73.8,78.6,73.8,78.5,73.8z" />
                    <path className="st5" d="M53.3,73.9C53.3,73.9,53.3,73.9,53.3,73.9c0.1,0.2,0.1,0.3,0.1,0.5c0,0,0,0-0.1,0
                  C53.3,74.3,53.3,74.1,53.3,73.9z" />
                    <path className="st5" d="M64.9,74.1c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0.1C65,74.1,64.9,74.1,64.9,74.1z" />
                    <path className="st5" d="M65.1,74.1C65.1,74.1,65.1,74.1,65.1,74.1c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0.1
                  C65.2,74.1,65.1,74.1,65.1,74.1z" />
                    <path className="st5" d="M77.2,74.1c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0.1C77.3,74.1,77.2,74.1,77.2,74.1z" />
                    <path className="st5" d="M77.4,74.1C77.4,74.1,77.4,74.1,77.4,74.1c0.1-0.1,0.2-0.1,0.2-0.1C77.6,74.1,77.5,74.1,77.4,74.1z" />
                    <path className="st5" d="M53.3,74.4C53.3,74.4,53.3,74.4,53.3,74.4c0.1,0.1,0.1,0.2,0.1,0.2C53.3,74.7,53.3,74.5,53.3,74.4z" />
                    <path className="st5" d="M80.3,74.7c0-0.1,0.2-0.1,0.2,0C80.4,74.7,80.4,74.7,80.3,74.7z" />
                    <path className="st5" d="M55.9,58.3C55.9,58.3,55.9,58.2,55.9,58.3c0.1-0.1,0.2-0.1,0.3-0.1c0,0.1,0.2,0.1,0.1,0.2
                  c0-0.1-0.1,0-0.1-0.1C56.2,58.2,55.9,58.2,55.9,58.3C55.9,58.3,55.9,58.3,55.9,58.3C55.9,58.3,55.9,58.3,55.9,58.3z" />
                    <path className="st5" d="M57.4,58.1c0.1,0,0.1,0,0.2,0c0,0.1-0.1,0.1-0.2,0.1C57.3,58.2,57.3,58.2,57.4,58.1
                  C57.4,58.2,57.4,58.1,57.4,58.1z" />
                    <path className="st5" d="M57.3,58.8c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0.1C57.4,59,57.4,58.9,57.3,58.8z" />
                    <path className="st5" d="M57.6,59.3c0.1,0,0.1,0.1,0.1,0.2C57.7,59.4,57.7,59.4,57.6,59.3z" />
                    <path className="st5" d="M60.4,59.5c0-0.1,0-0.1-0.1-0.2C60.4,59.3,60.6,59.5,60.4,59.5z" />
                    <path className="st5" d="M60.8,58.9c0-0.1,0.1-0.1,0.2-0.2C60.9,58.8,60.8,58.9,60.8,58.9z" />
                    <path className="st5" d="M61.3,58.3c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.1-0.3,0.1C61.3,58.4,61.3,58.3,61.3,58.3
                  C61.3,58.3,61.3,58.3,61.3,58.3z" />
                    <path className="st5" d="M55.4,58.7c0.1,0,0.1,0,0.1-0.1c0,0,0,0,0.1,0c0,0.1,0,0.1-0.1,0.1C55.5,58.7,55.4,58.7,55.4,58.7
                  C55.4,58.7,55.4,58.7,55.4,58.7z" />
                    <path className="st5" d="M60.7,58.9c0-0.1,0.2-0.1,0.2-0.2c0,0,0.1,0,0.1,0.1C60.9,58.8,60.8,58.8,60.7,58.9
                  C60.7,58.9,60.7,58.9,60.7,58.9z" />
                    <path className="st5" d="M60.1,59.2c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0-0.2-0.1-0.3-0.2C60.2,59.3,60.2,59.2,60.1,59.2
                  z" />
                    <path className="st5" d="M60,59.5c0,0,0-0.2,0.1-0.2C60.1,59.4,60.1,59.5,60,59.5z" />
                    <path className="st5" d="M57.5,59c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0-0.1C57.4,58.8,57.5,58.9,57.5,59z" />
                    <path className="st5" d="M55.4,58.7C55.4,58.7,55.4,58.8,55.4,58.7c-0.2,0.1-0.3,0-0.4,0C55,58.6,55.3,58.7,55.4,58.7
                  C55.4,58.7,55.4,58.7,55.4,58.7z" />
                    <path className="st5" d="M69.8,59.3c0-0.1,0.1-0.1,0.2-0.2C69.9,59.2,69.9,59.3,69.8,59.3z" />
                    <path className="st5" d="M69.3,59.5c0.1,0,0-0.1,0.1-0.2c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0.1c-0.1,0-0.1,0-0.1,0.1c-0.1,0,0,0,0,0.1
                  C69.4,59.6,69.4,59.5,69.3,59.5z" />
                    <path className="st5" d="M69.1,59.8c0-0.1-0.1-0.1-0.1-0.2C69.1,59.6,69.2,59.8,69.1,59.8z" />
                    <path className="st5" d="M63.7,60c0.1-0.1,0.1-0.2,0.2-0.2c0,0,0,0,0,0.1C63.9,59.9,63.8,60,63.7,60C63.8,60,63.8,60,63.7,60z" />
                    <path className="st5" d="M62.1,63.9c0-0.1,0.1-0.1,0.2-0.2C62.2,63.8,62.2,63.9,62.1,63.9z" />
                    <path className="st5" d="M63.6,63.1c0.1,0,0.1,0,0.2,0.1C63.7,63.2,63.6,63.2,63.6,63.1z" />
                    <path className="st5" d="M64.1,62.9C64.1,62.9,64.1,62.9,64.1,62.9c0.1,0.1,0.2,0.1,0.2,0.2C64.2,63,64.1,63,64.1,62.9z" />
                    <path className="st5" d="M64.8,64.6c0-0.1,0.2-0.2,0.2-0.1C64.9,64.6,64.8,64.5,64.8,64.6C64.8,64.6,64.8,64.6,64.8,64.6z" />
                    <path className="st5" d="M63.7,64.5c-0.1,0-0.1-0.1-0.2-0.2c0,0,0-0.1,0.1-0.1C63.5,64.4,63.7,64.4,63.7,64.5z" />
                    <path className="st5" d="M63.6,64.2c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0.1,0C63.6,64.1,63.7,64.2,63.6,64.2z" />
                    <path className="st5" d="M61.1,64.6c0-0.1,0.1-0.1,0.2-0.1c0,0.1,0,0.1-0.1,0.1C61.2,64.6,61.1,64.6,61.1,64.6z" />
                    <path className="st5" d="M60.8,65.1c0-0.1,0.1-0.1,0.2-0.2C61,65,60.9,65.1,60.8,65.1z" />
                    <path className="st5" d="M60,67.1c-0.1,0,0-0.2,0.1-0.2C60.1,67,60,67,60,67.1z" />
                    <path className="st5" d="M60.9,68.4c0.1,0,0.1,0.1,0.2,0.1C61,68.6,60.9,68.5,60.9,68.4z" />
                    <path className="st5" d="M63.4,68.7c0.1,0,0.2,0.2,0.1,0.2C63.5,68.8,63.4,68.7,63.4,68.7z" />
                    <path className="st5" d="M63.4,69.5c0.1,0.1,0.2,0.2,0.3,0.3C63.6,69.7,63.5,69.6,63.4,69.5z" />
                    <path className="st5" d="M64.3,73.3c0.1,0,0.1,0.1,0.2,0.2C64.3,73.4,64.3,73.4,64.3,73.3z" />
                    <path className="st5" d="M64.9,74.1c0.1,0,0.1,0,0.2,0c0,0.1-0.1,0.1-0.3,0.1C64.8,74.1,64.9,74.2,64.9,74.1z" />
                    <path className="st5" d="M65.7,73.9c0.1-0.1,0.1-0.2,0.2-0.2C65.9,73.8,65.8,73.9,65.7,73.9z" />
                    <path className="st5" d="M66,73.6c0.1,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1C66.1,73.6,66.1,73.6,66,73.6C66,73.7,66,73.7,66,73.6z" />
                    <path className="st5" d="M66.2,73.4c0-0.1,0.1-0.1,0.2-0.2C66.3,73.3,66.3,73.4,66.2,73.4z" />
                    <path className="st5" d="M66.5,72.8c0-0.1,0.2,0,0.2-0.2c0,0,0,0,0.1,0C66.8,72.7,66.7,72.8,66.5,72.8z" />
                    <path className="st5" d="M66.7,72.1c0.1-0.1,0.1-0.2,0.2-0.3C66.9,72,66.8,72,66.7,72.1z" />
                    <path className="st5" d="M67.5,69.6c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0.1,0C67.6,69.6,67.5,69.6,67.5,69.6z" />
                    <path className="st5" d="M67.9,69.1C68,69,68,69,68.1,68.9C68.1,69,68,69.1,67.9,69.1z" />
                    <path className="st5" d="M68.7,67.1c0-0.1,0.1-0.1,0.2-0.1C68.9,67.1,68.8,67.1,68.7,67.1z" />
                    <path className="st5" d="M69.8,66c-0.1,0-0.1-0.1-0.2-0.2C69.7,65.8,69.8,65.9,69.8,66z" />
                    <path className="st5" d="M69.3,65.6c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.3C69.3,65.8,69.4,65.4,69.3,65.6
                  C69.3,65.6,69.3,65.6,69.3,65.6z" />
                    <path className="st5" d="M69.2,65.8c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0.1,0C69.3,65.7,69.2,65.7,69.2,65.8z" />
                    <path className="st5" d="M68.5,65.5C68.7,65.4,68.7,65.5,68.5,65.5C68.6,65.6,68.6,65.6,68.5,65.5C68.5,65.6,68.5,65.5,68.5,65.5z" />
                    <path className="st5" d="M70.9,65.8c0.1,0,0.2,0.1,0.3,0.2C71,66,71,65.9,70.9,65.8z" />
                    <path className="st5" d="M71.4,66.2c0.1,0,0-0.3,0.2-0.2c0,0,0,0.1,0,0.1C71.5,66.2,71.5,66.3,71.4,66.2
                  C71.4,66.3,71.4,66.2,71.4,66.2z" />
                    <path className="st5" d="M72.1,67.9c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1C72.2,68,72.1,68,72.1,67.9z" />
                    <path className="st5" d="M75.6,68.4c-0.1,0-0.1-0.1-0.2-0.2C75.5,68.2,75.5,68.3,75.6,68.4z" />
                    <path className="st5" d="M75.6,67.6c0.1,0,0.1,0.1,0.2,0.1C75.8,67.9,75.6,67.7,75.6,67.6z" />
                    <path className="st5" d="M76.1,66.8c0.1,0,0.1,0.1,0.2,0.2C76.2,66.9,76.2,66.9,76.1,66.8C76.1,66.8,76.1,66.8,76.1,66.8z" />
                    <path className="st5" d="M76.1,66.8c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0-0.1C76,66.6,76,66.7,76.1,66.8z" />
                    <path className="st5" d="M75.9,66.3c0-0.1,0.1-0.1,0.2-0.2C76,66.2,75.9,66.3,75.9,66.3z" />
                    <path className="st5" d="M76.7,63.6C76.7,63.6,76.6,63.6,76.7,63.6c-0.1,0.2,0.1,0.2,0.1,0.3C76.6,63.8,76.4,63.6,76.7,63.6z" />
                    <path className="st5" d="M77.5,63.7c0,0-0.1-0.1-0.1-0.1C77.5,63.6,77.6,63.8,77.5,63.7C77.5,63.7,77.5,63.7,77.5,63.7z" />
                    <path className="st5" d="M77.5,63.7C77.5,63.7,77.5,63.7,77.5,63.7c0,0.1,0.1,0.1,0.2,0.1c0,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0.1
                  C77.8,63.9,77.5,63.9,77.5,63.7C77.5,63.7,77.5,63.7,77.5,63.7z" />
                    <path className="st5" d="M78.1,64c0-0.1-0.1-0.1-0.1-0.2C78.1,63.8,78.2,63.9,78.1,64C78.2,64,78.2,64,78.1,64z" />
                    <path className="st5" d="M77.9,63.3c0,0,0.1,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1C77.9,63.5,77.7,63.3,77.9,63.3z" />
                    <path className="st5" d="M78.1,61.8c0.1,0.1,0-0.3,0.1-0.1c0,0.1,0.2,0.3,0.2,0.2c0,0,0.1,0,0.1,0.1c-0.1,0,0,0.1,0.1,0.1
                  c0,0,0,0,0,0.1C78.4,62.1,78.3,61.7,78.1,61.8C78.2,61.9,78.1,61.9,78.1,61.8z" />
                    <path className="st5" d="M78.9,62.6C78.9,62.6,78.9,62.6,78.9,62.6C78.9,62.5,78.8,62.5,78.9,62.6c-0.2-0.1-0.2-0.3-0.2-0.3
                  c0.2,0,0.1-0.1,0.1-0.2c0.1,0,0.1,0.1,0.2,0.1c0,0.1-0.1,0-0.1,0.1C78.8,62.4,79.2,62.7,78.9,62.6z" />
                    <path className="st5" d="M79.2,60.5c0-0.1,0.2,0,0.2,0.1C79.3,60.5,79.3,60.5,79.2,60.5z" />
                    <path className="st5" d="M79.5,61.5c0.1,0,0.1,0.1,0.2,0.2C79.5,61.6,79.5,61.6,79.5,61.5z" />
                    <path className="st5" d="M79.7,61.7C79.8,61.7,79.8,61.7,79.7,61.7c0.1,0.1,0.2,0.1,0.2,0.2C79.8,61.8,79.8,61.8,79.7,61.7z" />
                    <path className="st5" d="M79.9,61.9C79.9,61.9,79.9,61.9,79.9,61.9c0.1,0,0.1,0.1,0.2,0.1C80.1,62.1,79.9,61.9,79.9,61.9z" />
                    <path className="st5" d="M80,61.3c0.1,0,0.2,0.2,0.1,0.2C80.1,61.4,80,61.4,80,61.3z" />
                    <path className="st5" d="M80.4,60c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0.1C80,60.1,80.4,60.1,80.4,60
                  C80.3,60,80.3,60,80.4,60z" />
                    <path className="st5" d="M81,60c0.1,0,0.1,0.1,0.2,0.2c0,0,0,0-0.1,0C81.1,60.1,81,60.1,81,60z" />
                    <path className="st5" d="M78.5,59.6c0.1,0,0.1-0.1,0.2-0.1c0,0.1,0.1,0.1,0.1,0.2C78.7,59.7,78.6,59.6,78.5,59.6
                  C78.5,59.6,78.5,59.6,78.5,59.6z" />
                    <path className="st5" d="M72.5,59c0-0.1,0.1-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0.1,0.1,0.1,0.1C72.6,59,72.5,59,72.5,59z" />
                    <path className="st5" d="M72.3,58.8c0.1,0,0.1,0,0.2,0.1c0,0,0,0,0,0.1C72.4,58.8,72.3,58.9,72.3,58.8z" />
                    <path className="st5" d="M72,58.8c0,0.1-0.3,0.1-0.3-0.1C71.7,58.8,71.8,58.8,72,58.8z" />
                    <path className="st5" d="M71.3,58.8c0-0.1,0.3-0.2,0.3-0.1C71.5,58.7,71.4,58.7,71.3,58.8z" />
                    <path className="st5" d="M79.9,60.7c0-0.1,0.2-0.2,0.2-0.1C80,60.7,80,60.7,79.9,60.7z" />
                    <path className="st5" d="M79.9,61.9c0-0.1-0.1-0.1-0.1-0.2C79.9,61.7,79.9,61.8,79.9,61.9C79.9,61.9,79.9,61.9,79.9,61.9z" />
                    <path className="st5" d="M77.9,61.6c0.1,0.1,0.2,0.1,0.3,0.2C78,61.8,77.9,61.7,77.9,61.6z" />
                    <path className="st5" d="M78.3,63.1c0-0.1,0-0.2,0.1-0.2C78.3,62.9,78.4,63.1,78.3,63.1z" />
                    <path className="st5" d="M78.1,64C78.2,64,78.2,64,78.1,64c0,0.1,0.2,0.2,0,0.2C78.2,64.1,78.1,64.1,78.1,64z" />
                    <path className="st5" d="M77.9,64c-0.1,0-0.1-0.1-0.2-0.2C77.8,63.8,77.8,63.9,77.9,64z" />
                    <path className="st5" d="M77.1,63.6c0.1,0,0.1-0.1,0.2-0.1C77.3,63.6,77.1,63.7,77.1,63.6z" />
                    <path className="st5" d="M77.2,64.6c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0.1C77.3,64.8,77.3,64.7,77.2,64.6
                  C77.2,64.7,77.2,64.6,77.2,64.6z" />
                    <path className="st5" d="M77.4,65c0-0.1,0-0.1,0.1-0.2C77.5,64.8,77.4,64.9,77.4,65C77.4,65,77.4,65,77.4,65z" />
                    <path className="st5" d="M75.9,66.7c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0.1C76,66.8,75.9,66.8,75.9,66.7z" />
                    <path className="st5" d="M74.9,67.2c0.1,0.1,0.3,0,0.3,0.1c-0.1,0-0.1,0-0.1,0.1c-0.1-0.1-0.1,0-0.2,0C75,67.3,74.9,67.3,74.9,67.2z" />
                    <path className="st5" d="M72.3,68c0.1,0,0.1,0,0.2-0.1C72.4,68,72.3,68,72.3,68C72.3,68,72.3,68,72.3,68z" />
                    <path className="st5" d="M68.3,65c0-0.1,0.2-0.2,0.2-0.1C68.4,64.9,68.4,64.9,68.3,65C68.3,65,68.3,65,68.3,65z" />
                    <path className="st5" d="M68.6,65.6c0,0.1,0.4-0.1,0.2,0.1C68.8,65.6,68.5,65.8,68.6,65.6C68.6,65.6,68.6,65.6,68.6,65.6z" />
                    <path className="st5" d="M69.3,66.7c0-0.1,0.1-0.1,0.2-0.2C69.5,66.6,69.4,66.7,69.3,66.7z" />
                    <path className="st5" d="M67.7,66.8c0-0.1-0.1-0.1-0.1-0.2C67.7,66.7,67.8,66.8,67.7,66.8C67.8,66.8,67.8,66.8,67.7,66.8z" />
                    <path className="st5" d="M67.2,65.9c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0-0.1C67.1,65.8,67.2,65.8,67.2,65.9z" />
                    <path className="st5" d="M66.4,64c0-0.1,0.3-0.2,0.3-0.1C66.6,64,66.5,64,66.4,64z" />
                    <path className="st5" d="M65.9,64c0.1,0,0.2-0.2,0.3,0C66,63.9,65.9,64.2,65.9,64z" />
                    <path className="st5" d="M65.6,63.8c-0.1,0,0-0.3-0.2-0.2c0-0.1,0.2,0,0.3-0.1c0,0,0,0.1-0.1,0.1C65.6,63.7,65.6,63.7,65.6,63.8z" />
                    <path className="st5" d="M71.3,58.8c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0.1,0c0,0.1,0.3,0.2,0.3,0.1c0.1,0,0.2,0,0.3,0
                  c0,0.1,0.2,0,0.2,0.1c0.1,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.2,0.1c0.2,0,0.5-0.1,0.7,0.1c0.1,0,0.2,0,0.2-0.1c0.2,0,0.1,0.1,0.3,0.1
                  c0,0.1,0.2,0.1,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4,0c0.3,0,0.4,0.4,0.7,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.2-0.1
                  c0.1,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0.1,0.2,0,0.3,0.1c0.3-0.1,0.4,0.1,0.6,0.1c0,0.1,0.3,0,0.5,0.1c0,0.1,0.3,0.1,0.3,0
                  c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.3,0.1c0,0.1,0.3,0.1,0.3,0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0c0,0.1,0.1,0,0.2,0.1
                  c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0.1,0c0-0.1,0-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0c0,0.1,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0
                  c0,0.1,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0,0.2,0.1c0,0.1,0.1,0,0.2,0.1c0.1,0,0.1,0,0.1,0.1c0,0.1-0.1,0-0.1,0.1
                  c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0.1,0.2-0.3,0.1-0.1,0.2c0,0,0.1,0,0.1,0c0,0.1,0.1,0,0.1,0.1c0,0.1,0.3,0,0.3,0.2
                  c-0.3-0.1-0.4,0.1-0.5,0.2c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.2,0-0.2,0.1c-0.1,0-0.2,0-0.2,0.1C79.4,61,80,61.1,80,61.3
                  c0,0.1,0.1,0.1,0.1,0.2c-0.1,0-0.1,0.3,0,0.3c0,0.1,0.1,0.1,0,0.1c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.2-0.2c0,0,0,0-0.1,0
                  c0-0.1-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.2-0.2c0-0.2-0.3-0.3-0.1-0.5c0.1,0,0-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0-0.2
                  c0-0.1-0.2-0.2-0.2-0.1c-0.1,0,0,0.1-0.1,0.2c-0.2,0-0.2-0.2-0.5-0.1c-0.1,0,0,0.1-0.1,0.1c-0.1,0-0.1,0.2,0,0.2
                  c-0.2,0.1-0.5-0.1-0.8,0.1c0-0.1-0.3-0.1-0.2,0c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0,0,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2
                  c0.2,0.1,0.4,0.3,0.6,0.2c0,0.1,0.2,0.2,0.3,0.2c0,0,0,0.1,0.1,0.1c0.2,0.2,0.3,0.6,0.2,1c-0.1,0-0.1,0.1-0.1,0.2
                  c-0.1,0-0.4,0-0.4,0.2c-0.1,0,0.1,0.2-0.1,0.2c-0.2,0.1,0.1,0.3,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0,0.2,0.1,0.1,0,0.2
                  c0,0.1-0.1,0-0.1,0.1c-0.1,0-0.1-0.3-0.2-0.3c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2,0-0.2-0.1c0.1,0,0-0.2-0.1-0.2
                  c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0.1-0.2,0.1c0-0.3-0.3-0.1-0.4,0c-0.2,0-0.1,0.3,0.1,0.3c0.2,0.1,0.3,0,0.5,0
                  c0,0.2-0.3,0.1-0.2,0.3c0,0.2,0.3,0.2,0.2,0.4c0,0,0,0,0,0.1c0.1,0,0.1,0.2,0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0,0.2,0.1
                  c0,1-1,1.3-1.5,1.1c-0.1,0-0.1,0.1-0.2,0.2c0,0.1,0,0.1-0.1,0.1c0,0.1,0,0.2,0.1,0.2c0,0,0,0,0,0.1c0,0.1,0.1,0.1,0.2,0.2
                  c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.1,0,0.2C76.3,67.7,76,67.8,76,68
                  c-0.1,0,0.1-0.3-0.2-0.2c-0.1,0-0.1-0.1-0.2-0.1c0-0.2-0.3-0.2-0.3-0.3c-0.1-0.1-0.2,0-0.3-0.1c0-0.2-0.1-0.2-0.1-0.3
                  c-0.2,0-0.2,0.1-0.3,0.1c0-0.6-0.4-0.6-0.6-1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2,0.1c0,0,0,0-0.1,0
                  c-0.2,0.6-0.9,0.5-0.9,1.4c-0.1,0,0,0.2-0.1,0.3c0,0,0,0.2-0.1,0.2c0,0-0.1,0.1-0.2,0.1c-0.1,0,0-0.1-0.1-0.1
                  c-0.1-0.5-0.4-0.8-0.5-1.3c0-0.1,0-0.2-0.1-0.2c0,0,0-0.1,0-0.1c0.1,0,0.1-0.2,0-0.2c0,0,0-0.1,0-0.1c-0.2-0.1,0,0.2-0.2,0.2
                  c-0.1,0-0.1,0-0.2,0c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
                  c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c0-0.1-0.3-0.1-0.3,0c-0.1,0-0.1,0-0.2,0c0-0.1-0.1,0-0.2-0.1c-0.2,0-0.2-0.2-0.3-0.2
                  c-0.5,0.2-0.7-0.2-0.9-0.5c0-0.1-0.2,0-0.2,0.1c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0.1c0,0.2,0.2,0,0.2,0.1
                  c0,0.1,0.1,0,0.1,0.1c0,0.1,0.2,0.1,0.2,0c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.2c0.1-0.2,0,0.2,0.1,0.2c0,0,0.1,0.1,0.2,0.1
                  c0,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0,0.1,0.1c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0-0.1,0.1-0.2,0.2c-0.2,0.1-0.4,0.1-0.5,0.3
                  c0,0-0.2,0-0.2,0.1c-0.3,0-0.4,0.3-0.8,0.3c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.2
                  c0,0,0,0-0.1,0c0-0.3-0.3-0.3-0.3-0.6c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.3-0.3-0.3-0.6c0-0.1,0-0.2-0.1-0.2
                  c0,0,0-0.1-0.1-0.1c0-0.2-0.1-0.1,0-0.2c0,0,0,0,0-0.1c0.1,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0.1,0,0-0.2,0.1-0.3c0,0,0-0.1,0-0.1
                  c0-0.1-0.3-0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1-0.1-0.1-0.1c0-0.2-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c0,0-0.1-0.1-0.1-0.2
                  c0-0.1,0-0.2-0.1-0.2c0.1,0,0.1,0,0.1-0.1c0.2-0.2,0.3-0.1,0.5-0.2c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.3,0.1
                  c0,0.1,0.1,0,0.2,0.1c0.1,0,0.2,0,0.2-0.1c0,0,0,0,0-0.1c0.1,0,0.1,0,0.1-0.1c0.2,0,0.1,0.1,0.3,0.1c0-0.1-0.2-0.1-0.3-0.1
                  c0-0.1-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0-0.1,0.1-0.1
                  c0.1,0,0-0.1,0.1-0.1c0,0-0.2-0.1-0.2,0c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c-0.2,0.1-0.2-0.2-0.3-0.3
                  c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0-0.1,0.2,0,0.2c0,0.1-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c0-0.1-0.2-0.1-0.2,0c-0.1,0.1-0.3,0-0.2,0.2
                  c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.2-0.3-0.2c0-0.1-0.1-0.1-0.2-0.2
                  c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0.1c-0.1,0.3,0.3,0.6,0.6,0.7c0,0.1-0.3,0-0.2,0.2
                  c0,0,0,0-0.1,0c-0.1-0.2-0.3-0.2-0.5-0.3c0-0.1-0.1-0.1-0.2-0.1c-0.1-0.4-0.4-0.1-0.6-0.1c0-0.1-0.3-0.1-0.2,0
                  c-0.1,0.3-0.5,0.3-0.5,0.7c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0-0.1,0c-0.1,0-0.3-0.1-0.3,0.1c0,0-0.1,0.1-0.2,0.1c0-0.1,0-0.1-0.1-0.1
                  c0-0.1-0.1-0.1-0.2-0.1c0-0.1,0-0.1-0.1-0.1c0-0.2,0.1-0.1,0.1-0.3c0.1,0,0-0.4,0.1-0.5c0.1,0,0.1,0,0.2,0c0,0.1,0.1,0,0.2,0.1
                  c0.1,0,0.4,0.1,0.4-0.1c0.1,0,0-0.1,0.1-0.2c0.1,0,0.1-0.3,0-0.2c0-0.2-0.1-0.1-0.2-0.2c0-0.1,0.1,0,0.1-0.1
                  c0.1-0.2,0.3,0,0.5-0.2c0,0,0.2,0,0.2-0.1c0-0.2,0.3-0.1,0.3-0.3c0.1,0,0.3,0.1,0.3-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.2-0.1
                  c0,0,0.3,0.1,0.3,0c0.2,0.1,1,0.1,0.9-0.4c0.1,0,0.1,0,0.2,0c0,0.1,0.1,0,0.2,0c0.1-0.1-0.2-0.2-0.1-0.2c0.1,0,0.2,0,0.2,0
                  c0.1,0,0.1-0.1,0.3-0.1c0-0.1-0.1-0.1-0.2-0.1c0-0.1-0.2-0.1-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1
                  c0-0.2,0.2-0.2,0.2-0.5c0,0,0.1,0,0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0,0,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.2
                  c-0.3-0.1-0.4,0.3-0.2,0.4c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.2,0.2c0,0.1,0,0.2-0.1,0.2c0,0.1-0.2,0-0.2,0.1
                  c0,0,0,0-0.1,0c0-0.1,0-0.1-0.1-0.1c0-0.2-0.1-0.2-0.2-0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1c0,0-0.1-0.1-0.1-0.1
                  c0.1-0.1,0-0.1,0-0.3c0.3-0.1,0.6-0.2,0.7-0.5c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0,0,0-0.1c0.2-0.2,0.5-0.2,0.7-0.3
                  c0.1,0,0.2,0,0.2-0.1c0,0,0,0,0.1,0c0,0.1,0.2,0.1,0.2,0c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.1,0,0.2,0c0.2,0.1,0.3,0.2,0.6,0.2
                  c0,0.3-0.3,0.1-0.5,0.1c0-0.1-0.2,0-0.3-0.1c0,0.1,0,0.1,0.1,0.1c0.2,0.1,0.2,0.3,0.4,0.3c0,0,0.2,0,0.2-0.1c0,0,0,0,0-0.1
                  c0,0,0,0,0.1,0c0,0.1,0.2,0,0.2-0.1c0.1-0.1,0.3,0,0.3-0.2c0.1,0,0.2,0.2,0.3,0.1c0.2,0,0.3-0.1,0.4-0.2c0.1,0,0.1,0,0.2,0
                  c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1c0.3,0,0.3-0.2,0.6-0.1c0.1,0.1,0.3,0.1,0.2,0.3c0.1,0,0.1-0.1,0.1-0.2
                  c0.1,0,0.1,0,0.2,0c0.1,0,0-0.2-0.1-0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.2,0,0.2-0.1c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0,0.1,0.1,0.1
                  c0,0.2,0.2,0.2,0,0.3c-0.2,0,0.1,0.2,0.1,0.1c0.4-0.1,0-0.4-0.1-0.5c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1
                  c0.1,0,0.1-0.1,0.2-0.2c0.2-0.1,0.4,0.1,0.5-0.2c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1,0,0.1-0.1c0.1,0,0.2,0,0.2,0
                  C71.1,59,71.2,58.8,71.3,58.8z M67.7,63.4c0.1-0.1,0.2,0.2,0.3,0.1C67.9,63.4,67.7,63.2,67.7,63.4z M68.2,63.4
                  c0,0.2-0.1,0.1,0,0.2c0,0.1,0,0.3,0.1,0.3c0.2,0,0.3,0.3,0.5,0.1c0.1,0,0.2-0.3,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2
                  c0.1-0.1,0.3-0.2,0.1-0.2c0-0.2-0.2,0-0.3-0.2c0-0.1-0.1-0.1-0.2-0.1c0-0.1,0-0.1,0-0.2c0.1-0.1,0.2-0.1,0.3,0
                  c0-0.2-0.1-0.2-0.2-0.3c0,0,0,0,0-0.1c-0.1,0-0.1-0.2-0.2-0.2c0-0.1,0-0.2-0.1-0.2c-0.1-0.2-0.2,0.1,0,0.1c0,0,0,0.1,0,0.1
                  c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.2c0.2-0.1,0,0.2,0.2,0.2
                  c0,0.1,0.1,0.1,0.2,0.2C68.1,63.4,68.2,63.4,68.2,63.4z M68.5,60.1c0.1,0,0.1-0.1,0.2-0.1C68.6,60,68.4,60.1,68.5,60.1z
                   M68.4,60.1c0,0.1,0,0.1,0.1,0.2C68.5,60.2,68.5,60.1,68.4,60.1z M68.7,61c0-0.2,0-0.7-0.2-0.5C68.8,60.5,68.5,60.9,68.7,61z
                   M68.8,61.2c0,0.1-0.3,0.1-0.1,0.2c0.1-0.1,0.2-0.1,0.2-0.2c-0.1,0,0-0.2-0.2-0.2C68.7,61.1,68.7,61.1,68.8,61.2z M68.8,61.6
                  c0.1-0.1,0.1-0.1,0.2-0.2c0,0.1,0.1,0,0.1-0.1c-0.2,0-0.1,0.2-0.3,0.1c0-0.1-0.1-0.1-0.1-0.1C68.6,61.6,68.8,61.6,68.8,61.6z
                   M69.1,61.6C68.9,62,69.2,61.8,69.1,61.6L69.1,61.6z M68.7,61.8c0.1-0.1,0.2,0.2,0.2,0C68.9,61.8,68.7,61.7,68.7,61.8z M68.2,61.9
                  c0.1,0,0.3,0,0.3-0.1C68.4,61.7,68.2,61.8,68.2,61.9z" />
                    <path className="st5" d="M68,63.4c-0.1,0.2-0.1-0.1-0.3-0.1C67.7,63.2,67.9,63.4,68,63.4z" />
                    <path className="st5" d="M68.2,63.6c-0.1-0.1,0-0.1,0-0.2C68.3,63.4,68.2,63.6,68.2,63.6C68.2,63.6,68.2,63.6,68.2,63.6z" />
                    <path className="st5" d="M68.3,63.6c0,0.1,0,0.2,0.1,0.3C68.2,63.9,68.2,63.8,68.3,63.6C68.2,63.6,68.2,63.6,68.3,63.6z" />
                    <path className="st5" d="M68.8,63.7c0.1,0,0.1,0.4-0.1,0.3C68.8,64,68.8,63.8,68.8,63.7z" />
                    <path className="st5" d="M68.3,63c0,0,0.2,0,0.2,0.1C68.4,63.1,68.3,63.1,68.3,63z" />
                    <path className="st5" d="M68,62.7c0.1-0.1,0.1-0.2,0.2-0.2C68.1,62.6,68,62.7,68,62.7z" />
                    <path className="st5" d="M68,63.1c0.1,0,0.1,0.1,0.2,0.2C68.1,63.3,68,63.2,68,63.1z" />
                    <path className="st5" d="M67.2,63.1c-0.1,0-0.1,0-0.2-0.1C67.1,63,67.1,63,67.2,63.1z" />
                    <path className="st5" d="M66.7,62.5c0-0.1,0.1,0,0.2,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0-0.1C66.8,62.5,66.8,62.5,66.7,62.5z" />
                    <path className="st5" d="M65.5,63.4c0-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0,0-0.1,0.1-0.1,0.1C65.6,63.4,65.5,63.4,65.5,63.4z" />
                    <path className="st5" d="M64.3,63.1c0-0.1-0.1-0.1-0.1-0.2C64.2,62.9,64.3,63,64.3,63.1C64.3,63.1,64.3,63.1,64.3,63.1z" />
                    <path className="st5" d="M63.8,62.7C63.8,62.7,63.8,62.7,63.8,62.7c0.1,0,0.1,0,0.2,0.1C63.9,62.8,63.9,62.7,63.8,62.7z" />
                    <path className="st5" d="M61.2,63.5c0-0.2-0.1-0.4,0.1-0.5C61.2,63.1,61.4,63.4,61.2,63.5z" />
                    <path className="st5" d="M62,62.4c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1,0,0.2-0.1C62.1,62.4,62,62.4,62,62.4z" />
                    <path className="st5" d="M62.5,62.1c0-0.1,0.1-0.1,0.2-0.1C62.7,62.1,62.6,62.1,62.5,62.1z" />
                    <path className="st5" d="M65,60.9c-0.2,0.1,0.1,0.1,0.1,0.2c-0.1,0-0.1,0.1-0.2,0C65.2,61.1,64.7,60.9,65,60.9
                  C65,60.9,65,60.9,65,60.9z" />
                    <path className="st5" d="M64.7,60c0-0.1,0.1-0.1,0.2-0.1c0,0.1,0,0.1,0.1,0.1c0,0,0,0.1-0.1,0.1C64.9,60,64.8,60,64.7,60z" />
                    <path className="st5" d="M64.3,60.7c0.1,0,0.1,0.1,0.1,0.1C64.4,60.8,64.3,60.8,64.3,60.7z" />
                    <path className="st5" d="M64.1,61.1c0-0.1,0-0.2,0.2-0.2C64.3,61,64.2,61,64.1,61.1z" />
                    <path className="st5" d="M63.9,61.4c0-0.1,0.1-0.1,0.2-0.1C64.1,61.3,64,61.4,63.9,61.4z" />
                    <path className="st5" d="M63.3,61c0,0.1-0.3,0-0.2-0.1C63.2,60.9,63.2,61,63.3,61z" />
                    <path className="st5" d="M63.8,60c0-0.1,0.1-0.1,0.2-0.2C63.9,60,63.9,60,63.8,60z" />
                    <path className="st5" d="M66.4,60.2c0.1,0,0.1-0.1,0.2-0.1C66.6,60.2,66.4,60.3,66.4,60.2z" />
                    <path className="st5" d="M68.7,59.6c0.1,0,0.2,0,0.3,0.1c0,0.1,0,0.1-0.1,0.2C69,59.8,68.7,59.7,68.7,59.6z" />
                    <path className="st5" d="M68.7,60c0,0.1-0.1,0.1-0.2,0.1" />
                    <line className="st5" x1="69.1" y1="61.6" x2="69.1" y2="61.6" />
                    <path className="st5" d="M68.8,59.4c0-0.1,0.1-0.1,0.2-0.1C69,59.3,68.9,59.3,68.8,59.4z" />
                    <path className="st5" d="M51.5,59.3c0-0.1,0.1,0,0.2,0c0,0.1-0.1,0.1-0.1,0.2C51.4,59.5,51.7,59.3,51.5,59.3z" />
                    <path className="st5" d="M51.2,59.6c0.2,0,0.4,0,0.2,0.2C51.5,59.7,51.4,59.7,51.2,59.6z" />
                    <path className="st5" d="M46.6,59.7c0,0.1,0,0.1,0.1,0.2C46.6,59.9,46.5,59.7,46.6,59.7z" />
                    <path className="st5" d="M45.5,61c-0.2,0-0.2-0.2-0.1-0.2c0,0,0,0.1-0.1,0.1C45.3,60.9,45.4,60.9,45.5,61z" />
                    <path className="st5" d="M46,61.1c0,0.1-0.1,0-0.2,0.1C45.8,61.1,45.8,61.1,46,61.1C46,61.1,46,61.1,46,61.1z" />
                    <path className="st5" d="M47.7,60.8c0.1,0,0.1,0.1,0.2,0.1C47.8,61,47.8,60.9,47.7,60.8z" />
                    <path className="st5" d="M48.1,61.1C48,61.3,47.9,61.2,48.1,61.1C48,61.1,48.1,61.1,48.1,61.1C48.1,61.1,48.1,61.1,48.1,61.1z" />
                    <path className="st5" d="M47.5,63.1c0.1-0.1,0.1-0.2,0.2-0.2C47.7,62.9,47.6,63,47.5,63.1z" />
                    <path className="st5" d="M47.3,63.5c-0.2-0.1,0-0.2,0.1-0.2C47.4,63.4,47.2,63.4,47.3,63.5z" />
                    <path className="st5" d="M48.3,65.4c0.1,0.1,0.1,0.2,0.2,0.3C48.4,65.7,48.2,65.6,48.3,65.4z" />
                    <path className="st5" d="M48.6,66.4c0.1,0.1,0.2,0.1,0.2,0.2C48.7,66.6,48.6,66.5,48.6,66.4z" />
                    <path className="st5" d="M50.5,67.3c0.1,0,0.3-0.1,0.3,0.1C50.8,67.3,50.6,67.4,50.5,67.3z" />
                    <path className="st5" d="M50.8,67.5c0.1,0,0.1,0.2,0.2,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0.1C50.9,67.7,50.9,67.5,50.8,67.5z" />
                    <path className="st5" d="M51.1,67.9c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0.1C51.2,68,51.1,68,51.1,67.9z" />
                    <path className="st5" d="M52.1,70.9c0.1,0,0.1,0.1,0.2,0.2C52.2,71,52.1,71,52.1,70.9z" />
                    <path className="st5" d="M53.4,75.1c0.1,0.1,0.2,0.1,0.2,0.2C53.5,75.2,53.4,75.2,53.4,75.1z" />
                    <path className="st5" d="M54.4,76.7c0.1,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0,0,0,0.1,0.1,0.1C54.5,76.7,54.4,76.8,54.4,76.7z" />
                    <path className="st5" d="M54.6,76.8c0,0.1,0,0.2,0.1,0.2C54.6,77,54.5,76.9,54.6,76.8C54.6,76.8,54.6,76.8,54.6,76.8z" />
                    <path className="st5" d="M54.7,77c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1C54.8,77.1,54.7,77.1,54.7,77z" />
                    <path className="st5" d="M55.1,77c0.1,0,0.2,0.2,0.1,0.2c0,0,0,0,0-0.1C55.2,77,55.1,77,55.1,77z" />
                    <path className="st5" d="M54.7,76.7c0.1,0.1,0.3,0.1,0.3,0.2c0,0-0.1,0-0.1-0.1c-0.2,0,0.1,0.2,0.1,0.1c0,0,0,0,0.1,0
                  c0,0,0.1,0.1,0.1,0.1c-0.1,0-0.3-0.1-0.3,0.1c0,0,0,0-0.1,0c0-0.1,0-0.1-0.1-0.1C54.9,77,54.6,76.8,54.7,76.7z" />
                    <path className="st5" d="M54.4,75.8c0.1,0.1,0.2,0.1,0.2,0.2C54.6,76,54.5,75.9,54.4,75.8z" />
                    <path className="st5" d="M54.5,75.5c0.1,0,0,0.2-0.1,0.2C54.5,75.6,54.6,75.6,54.5,75.5z" />
                    <path className="st5" d="M56,73c-0.1,0,0-0.2,0.1-0.2C56.1,72.8,56,72.9,56,73z" />
                    <path className="st5" d="M56.4,69.6C56.4,69.8,56.1,69.6,56.4,69.6C56.3,69.6,56.3,69.6,56.4,69.6z" />
                    <path className="st5" d="M55.5,68.7c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0-0.1C55.3,68.5,55.5,68.6,55.5,68.7
                  C55.5,68.8,55.4,68.8,55.5,68.7z" />
                    <path className="st5" d="M52.6,67.6c0-0.1,0.1-0.1,0.2-0.2C52.7,67.6,52.7,67.6,52.6,67.6z" />
                    <path className="st5" d="M51,67c0,0.1-0.3,0-0.3,0c0,0,0-0.1,0.1-0.1C50.7,67,50.9,66.9,51,67z" />
                    <path className="st5" d="M52.7,64.3c0.1-0.1,0.1-0.2,0.2-0.3C52.9,64.2,52.8,64.3,52.7,64.3z" />
                    <path className="st5" d="M54.8,62.6c-0.2-0.1,0-0.2,0.1-0.2C54.9,62.4,54.7,62.4,54.8,62.6z" />
                    <path className="st5" d="M54.5,62.2c0-0.1,0.1-0.1,0.2-0.1C54.7,62.2,54.6,62.2,54.5,62.2z" />
                    <path className="st5" d="M55.5,62.4c-0.1,0,0.1-0.2,0.2-0.2c0,0.1,0,0.1-0.1,0.1C55.6,62.4,55.5,62.4,55.5,62.4z" />
                    <path className="st5" d="M56,62.2c0.1,0,0.1,0,0.2,0.1c0,0,0,0-0.1,0C56.1,62.3,56,62.3,56,62.2z" />
                    <path className="st5" d="M55.9,61.6c0-0.1,0.3-0.1,0.3,0.1C56.1,61.6,56,61.6,55.9,61.6z" />
                    <path className="st5" d="M53.4,61.9c0,0.1-0.2,0-0.2-0.1C53.3,61.9,53.3,61.9,53.4,61.9z" />
                    <path className="st5" d="M54.3,59.8c0-0.1,0.1-0.1,0.2-0.2C54.4,59.7,54.3,59.7,54.3,59.8z" />
                    <path className="st5" d="M53.2,59.6c0.1-0.2,0.2,0.1,0.3-0.1c0,0.1,0,0.1,0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1
                  C53.4,59.6,53.3,59.6,53.2,59.6z" />
                    <path className="st5" d="M51.9,59c0.2,0,0.2,0.1,0.3,0.2c0,0,0,0-0.1,0c0-0.1-0.1-0.1-0.1-0.1C52,59.1,51.9,59.1,51.9,59z" />
                    <path className="st5" d="M53.5,59.3c-0.1,0.2-0.2-0.2,0-0.2C53.5,59.2,53.3,59.3,53.5,59.3z" />
                    <path className="st5" d="M53.6,59.3c0-0.1,0.1-0.1,0.2-0.1C53.7,59.3,53.7,59.3,53.6,59.3z" />
                    <path className="st5" d="M53.5,59.1c0,0,0.3-0.1,0.2,0.1c0,0-0.2,0-0.2,0.1c0,0,0,0-0.1,0C53.3,59.3,53.5,59.2,53.5,59.1z" />
                    <path className="st5" d="M54.2,59.2c0-0.1,0-0.1,0.1-0.1C54.3,59.1,54.3,59.2,54.2,59.2z" />
                    <path className="st5" d="M54.5,59.2c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0.1C54.6,59.1,54.6,59.2,54.5,59.2z" />
                    <path className="st5" d="M54.8,60.2c0-0.1,0.2,0,0.2-0.1c0.1,0,0.1,0,0.2,0c0,0.1-0.1,0.1,0,0.2C55.1,60.2,55,60.2,54.8,60.2z" />
                    <path className="st5" d="M55.3,60.4c0.1,0,0.1,0,0.2,0.1C55.4,60.5,55.3,60.5,55.3,60.4z" />
                    <path className="st5" d="M54.7,59.1C54.7,59.1,54.7,59.1,54.7,59.1c0.2,0-0.1,0.1-0.1,0.2C54.5,59.3,54.7,59.2,54.7,59.1z" />
                    <path className="st5" d="M54.7,59.4c0-0.1,0.1-0.1,0.2-0.2C54.8,59.3,54.8,59.3,54.7,59.4z" />
                    <path className="st5" d="M56.1,60c0.1,0,0.1,0,0.2-0.1C56.4,59.9,56.1,60.2,56.1,60z" />
                    <path className="st5" d="M52.2,59.2c0.3,0.1,0.4,0.1,0.7,0c0,0.2-0.2,0.3,0.1,0.3c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0-0.1,0-0.2,0
                  c-0.2-0.1-0.6,0.2-0.9,0c0-0.1,0.3,0,0.3-0.2c0,0,0-0.1-0.1-0.1c-0.2,0.1-0.1-0.1-0.2-0.1c0,0-0.2-0.1-0.2,0
                  c-0.2,0-0.2,0.1-0.4,0.1c0.1-0.2,0.4-0.4,0.8-0.3c0,0.1,0.1,0,0.1,0.1c0,0,0,0.1-0.1,0.1c-0.2,0-0.2,0.1-0.3,0.1
                  c0,0.1,0.1,0,0.2,0.1c0.1,0,0.2,0,0.2-0.1C52,59.2,52.1,59.2,52.2,59.2C52.2,59.2,52.2,59.2,52.2,59.2z" />
                    <path className="st5" d="M51.9,59.5C51.9,59.5,51.9,59.4,51.9,59.5C51.9,59.4,52,59.4,51.9,59.5c0.1,0.2-0.2,0-0.2,0.2
                  C51.3,59.5,51.8,59.6,51.9,59.5z" />
                    <path className="st5" d="M53.5,59.5c0.1,0,0.2-0.1,0.3-0.1c0,0.2-0.1,0.4,0.2,0.3c0.1,0.1-0.2,0.2,0,0.2c0,0-0.1,0.1-0.1,0.1
                  c-0.1,0.1-0.2,0-0.3,0c0,0.1,0.1,0.1,0.1,0.2c-0.2,0-0.2,0.1-0.4,0.1c-0.1-0.2-0.2,0.1-0.2,0.2c-0.3,0.1-0.6,0.2-0.7,0.5
                  c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.1c0,0.1,0.1,0,0.2,0.1c0.2,0.1,0.2,0.2,0.5,0.2c0,0.2-0.2,0.3-0.1,0.5
                  c0,0.1,0.2,0.2,0.2,0.1c0.2-0.3,0.5-0.5,0.7-0.7c0.1,0,0.1-0.2,0-0.2c0.1-0.2,0.3-0.3,0.3-0.5c0.3,0,0.4,0.1,0.6,0.1
                  c0,0.2,0,0.3,0.1,0.4c0.3,0.1,0.3-0.2,0.5-0.2c0.1,0.4,0,0.8,0.2,0.8c0.1,0,0.2,0,0.3,0.1c0,0.2-0.3,0.3-0.5,0.3
                  c-0.1,0-0.2,0-0.2,0.1c-0.2,0-0.2,0.1-0.3,0.1c0-0.1-0.4-0.1-0.3,0c0,0-0.1,0-0.1,0c0,0.1-0.2,0-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.2
                  c0.1,0.2,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.2,0.1c-0.1,0-0.3,0.2-0.1,0.2c0,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0.1,0
                  c-0.2,0.1-0.3,0.2-0.5,0.2c0,0-0.1,0-0.1-0.1c0.1,0,0.2,0,0.2-0.1c-0.3-0.1-0.9,0.1-0.9,0.5c-0.5,0-0.7,0.4-1,0.6
                  c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.3,0.1-0.5,0.3-0.7,0.5c-0.1,0-0.1,0.3,0,0.2c0,0,0,0,0,0.1
                  c0,0.1,0,0.2,0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.2,0-0.2-0.4-0.2-0.5c0-0.1-0.1-0.1-0.2-0.2c-0.3-0.1-0.7,0.1-1,0.1
                  c0-0.1-0.2-0.1-0.2,0c-0.5,0.1-0.7,0.6-0.8,1.1c0,0.1,0,0.2,0.1,0.2c0.1,0.2,0.1,0.4,0.4,0.4c0.1,0,0.2,0,0.2-0.1
                  c0.1,0,0.2-0.1,0.3-0.2c0.1,0,0.1-0.1,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.2-0.2,0.4-0.2,0.6
                  c-0.1,0,0,0.1-0.1,0.1c0,0,0.3,0.1,0.3,0c0.1,0,0.1,0,0.2,0c0,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0,0.1c-0.1,0,0,0.1-0.1,0.2
                  c-0.1,0,0,0.1-0.1,0.2c0,0,0,0,0,0.1c0,0,0,0-0.1,0c0-0.1-0.1,0-0.1-0.1c-0.2,0-0.1-0.1-0.2-0.1c0,0,0,0,0-0.1
                  c0.1-0.2-0.2-0.1-0.3-0.1c-0.5-0.4-1.1-0.3-1.7-0.7c-0.1-0.1-0.1-0.2-0.2-0.2c0.1-0.3,0-0.4-0.1-0.6c-0.1-0.1-0.2-0.2-0.2-0.3
                  c0-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.2-0.2-0.2c0,0-0.1,0.1-0.1,0.1c0.1,0.2,0.1,0.4,0.2,0.6c0,0,0,0.1,0,0.1
                  c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c0.2-0.1,0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0.1-0.3-0.2-0.3-0.3-0.5
                  c0-0.1,0-0.2-0.1-0.2c0.1-0.3-0.1-0.3,0-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.2
                  c0-0.1,0.2-0.2,0.2-0.4c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.3,0-0.2c0-0.2-0.1-0.1-0.2-0.2
                  c-0.1-0.1,0.2-0.2,0-0.2c0-0.1,0-0.2,0.1-0.2c0.1,0,0.1-0.2,0-0.2c0,0,0,0,0-0.1c0.1,0,0.1-0.2,0-0.2c0,0,0,0-0.1,0
                  c0-0.1,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c0-0.1-0.2-0.1-0.2,0
                  c-0.5,0.1-1,0.3-1.5,0.5c0,0,0,0,0-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0,0,0.1,0c0-0.1-0.2-0.2-0.2-0.1c0,0-0.1,0.1-0.1,0.1
                  c0-0.1-0.1,0-0.1-0.1c0,0,0.1,0,0.1-0.1c0.2,0.1,0-0.3,0.2-0.3c0,0,0.1,0,0.1,0c0.1,0,0.3,0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.2
                  c-0.1-0.1-0.3,0-0.5-0.1c0-0.2,0.1-0.1,0.2-0.2c0.1,0,0.2,0,0.2,0c0.1,0,0.3,0,0.3-0.1c0,0-0.1-0.1-0.1-0.2c0,0,0,0,0.1,0
                  c0.1,0,0.3,0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2,0,0.2,0
                  c0,0.1,0.1,0,0.2,0.1c0,0,0,0,0.1,0c0,0.1,0.1,0,0.2,0.1c0.1,0,0.2,0,0.3,0c0,0.1,0.2,0,0.3,0.1c0.1,0,0.1,0.1,0.3,0.1
                  c0,0.1,0.4,0.1,0.4,0c0.2,0,0.3-0.1,0.5-0.1c0,0.1,0.2,0.1,0.2,0c0.2,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0.1
                  c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.3,0.1,0.3,0c0.1,0,0.2,0,0.2,0.2c0.1-0.1,0.3-0.2,0.5-0.3c0.1,0,0.1,0.1,0.3,0.1
                  c0,0.1,0.2,0.1,0.2,0c0.1,0,0.1,0,0.2,0c0.2,0.1,0.5,0,0.6-0.2C53.6,59.5,53.5,59.5,53.5,59.5z M52.3,62.3c-0.1,0.1-0.3,0-0.5,0.1
                  c0-0.1-0.3,0-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0.2,0,0.4-0.1,0.5,0c0,0,0,0,0,0.1c-0.3,0.1-0.3,0.4-0.5,0.5c-0.1,0,0.1,0.2,0.2,0.2
                  c0-0.1,0-0.1,0.1-0.1c0-0.1,0.2-0.1,0.1-0.3c0.1-0.1,0.2-0.1,0.2-0.2c0.1,0,0.1,0,0.2,0c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0.1,0,0.1
                  c0,0.1,0.3,0,0.1,0.2c-0.1,0-0.2,0-0.2,0.1c0,0.1,0.2,0.1,0.2,0c0.1-0.1,0.2,0,0.2-0.2c0-0.1-0.1-0.1-0.1-0.1
                  c0.1-0.1,0.1-0.2,0.3-0.1c0-0.2-0.1-0.3-0.2-0.3c-0.1,0-0.1,0-0.2-0.1c0,0,0,0,0-0.1C52.6,62.4,52.4,62.2,52.3,62.3z M53,63
                  C53,63,53,63,53,63c-0.2,0,0,0.3,0,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.2,0.1-0.2c0,0-0.1,0-0.1,0.1C53.1,63,53,63,53,63z" />
                    <path className="st5" d="M52.4,61c0.1,0,0.1,0,0.1,0.1C52.4,61.1,52.4,61,52.4,61z" />
                    <path className="st5" d="M54.7,62.3c0-0.2,0.4,0,0.2,0.1C54.9,62.3,54.8,62.3,54.7,62.3z" />
                    <path className="st5" d="M51.8,65.1c-0.1,0-0.1-0.1-0.2-0.2C51.7,64.9,51.8,65,51.8,65.1z" />
                    <path className="st5" d="M50.5,66.4c0-0.1,0-0.1,0.1-0.2C50.6,66.3,50.6,66.3,50.5,66.4z" />
                    <path className="st5" d="M50.9,66.3c0-0.1,0-0.1-0.1-0.1C51,66.1,51,66.3,50.9,66.3z" />
                    <path className="st5" d="M51.2,67c0.1,0,0.1,0,0.2,0.1C51.3,67,51.2,67,51.2,67z" />
                    <path className="st5" d="M47.3,63.5c0-0.2,0.1-0.1,0.1-0.2c0,0,0,0,0.1,0C47.4,63.4,47.4,63.5,47.3,63.5
                  C47.3,63.6,47.3,63.5,47.3,63.5z" />
                    <path className="st5" d="M48,61.9c-0.2,0,0.1-0.1,0-0.2C48.2,61.7,48,61.8,48,61.9z" />
                    <path className="st5" d="M45.6,60.9c0-0.1,0.2,0,0.2,0.1c0,0,0,0-0.1,0C45.7,60.9,45.6,60.9,45.6,60.9z" />
                    <path className="st5" d="M69.6,60c0,0.2-0.3-0.1-0.1-0.1C69.5,60,69.5,60,69.6,60z" />
                    <path className="st5" d="M53.2,59.6c0.1,0,0.2,0,0.2,0.1C53.3,59.6,53.1,59.8,53.2,59.6C53.2,59.6,53.2,59.6,53.2,59.6z" />
                    <path className="st5" d="M54.3,59.8c0.1,0,0.1-0.1,0.2-0.2c0.1,0,0.4,0.1,0.2,0.1C54.5,59.5,54.3,59.8,54.3,59.8
                  C54.3,59.8,54.3,59.8,54.3,59.8z" />
                    <path className="st5" d="M54.7,59.7c-0.1,0.2-0.3,0.3-0.5,0.3c0-0.1,0-0.1-0.1-0.1c0,0,0.1-0.1,0.2-0.1C54.3,59.8,54.5,59.5,54.7,59.7
                  z" />
                    <path className="st5" d="M64.8,60.9c0,0,0.1,0.1,0.1,0.1C64.9,61.1,64.6,61,64.8,60.9z" />
                    <path className="st5" d="M63.5,61.5C63.5,61.5,63.5,61.5,63.5,61.5c0.1,0,0.2,0,0.3,0C63.8,61.7,63.5,61.6,63.5,61.5z" />
                    <path className="st5" d="M63.6,61.4c0,0,0.1,0,0.1,0c0-0.1,0-0.1-0.1-0.1c0-0.1,0.1,0,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
                  C63.8,61.4,63.7,61.6,63.6,61.4z" />
                    <path className="st5" d="M64.5,60.3c0-0.1,0.1-0.1,0.2-0.2C64.6,60.2,64.6,60.3,64.5,60.3z" />
                    <path className="st5" d="M53.9,60.3c-0.1,0,0-0.2,0.1-0.2C53.9,60.2,53.9,60.3,53.9,60.3z" />
                    <path className="st5" d="M54.2,60.2c0.1,0,0.2,0.2,0.1,0.2C54.3,60.3,54.2,60.2,54.2,60.2z" />
                    <path className="st5" d="M46,61.1C46.1,60.9,46.2,61.2,46,61.1C46,61.1,46,61.1,46,61.1z" />
                    <path className="st5" d="M61.9,61.9c0.1,0,0.2,0,0.2,0.1C62,62,62,62,62,62c0,0-0.1,0-0.1,0C62,61.9,62,62,61.9,61.9z" />
                    <path className="st5" d="M62.5,61.9c0.2-0.2-0.1-0.1-0.1-0.2C62.6,61.7,62.7,61.8,62.5,61.9z" />
                    <path className="st5" d="M47.9,61.1C48,61,47.7,61.3,47.9,61.1L47.9,61.1z" />
                    <path className="st5" d="M61.9,61.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.2,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0,0-0.1
                  C62,61.2,62,61.2,61.9,61.1z" />
                    <path className="st5" d="M45.2,61.2c0-0.1,0.2-0.1,0.3-0.1c0,0,0,0,0,0.1C45.3,61.2,45.3,61.2,45.2,61.2
                  C45.2,61.3,45.2,61.3,45.2,61.2C45.2,61.3,45.2,61.3,45.2,61.2z" />
                    <path className="st5" d="M62.1,61.4c0.1,0,0.2,0.2,0.2,0.3C62.2,61.6,62.2,61.4,62.1,61.4z" />
                    <path className="st5" d="M62.4,61.7c0,0.1,0.3,0,0.1,0.2c0,0-0.1,0-0.1,0.1c0,0.1-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0
                  c0-0.1-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c0-0.1,0-0.2,0.2-0.2c0-0.2,0-0.3-0.2-0.3c0-0.1-0.1-0.3,0.1-0.3c0.1,0,0.1,0,0.1,0.1
                  c-0.1,0,0,0.1,0,0.1c0,0.1,0.1,0,0.1,0.1C62.2,61.4,62.2,61.6,62.4,61.7C62.4,61.6,62.4,61.7,62.4,61.7z" />
                    <path className="st5" d="M63.3,61.5c0-0.1-0.1-0.3,0.1-0.3c0.2,0,0,0,0.1,0.2C63.4,61.4,63.4,61.5,63.3,61.5z" />
                    <path className="st5" d="M47.9,61.3c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0C47.9,61.4,47.9,61.3,47.9,61.3z" />
                    <path className="st5" d="M63.6,61.3c0.1,0,0.1,0,0.1,0.1c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1-0.1
                  C63.6,61.4,63.6,61.3,63.6,61.3z" />
                    <path className="st5" d="M61.6,61.6c0-0.1,0.2,0,0.2-0.1c0.1,0,0,0.1-0.1,0.1C61.6,61.6,61.6,61.6,61.6,61.6z" />
                    <path className="st5" d="M61.5,61.5C61.5,61.5,61.5,61.5,61.5,61.5c0.1,0,0.1,0,0.1-0.1c0.1,0,0.2,0,0.2,0.1c0,0.1-0.2,0-0.2,0.1
                  C61.5,61.6,61.5,61.5,61.5,61.5z" />
                    <path className="st5" d="M63.3,61.5c0.1,0,0.1,0,0.1-0.1c0,0,0,0,0.1,0c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0-0.1,0-0.1,0.1
                  c-0.1,0-0.1,0-0.2,0.1C63.3,61.5,63.3,61.5,63.3,61.5z" />
                    <path className="st5" d="M78.4,61.9c0,0.1-0.2-0.1-0.2-0.2C78.4,61.7,78.3,61.9,78.4,61.9z" />
                    <path className="st5" d="M78.5,62c-0.1,0-0.1-0.1-0.1-0.1C78.5,62,78.6,62,78.5,62C78.6,62,78.6,62,78.5,62z" />
                    <path className="st5" d="M78.5,62.1C78.5,62.1,78.5,62.1,78.5,62.1C78.6,62,78.6,62,78.5,62.1c0.1,0,0.1,0,0.2,0
                  c0,0.1,0.1,0.2-0.1,0.2C78.6,62.2,78.5,62.2,78.5,62.1z" />
                    <path className="st5" d="M56,62.4c0-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0C56.2,62.4,56.1,62.4,56,62.4z" />
                    <path className="st5" d="M51.7,63.2c0,0.1,0.2,0.1,0.2,0.1c0,0-0.1,0.1-0.1,0.1C51.8,63.4,51.6,63.2,51.7,63.2z" />
                    <path className="st5" d="M52.1,63c0.1-0.1,0.1-0.2,0.2-0.2C52.3,62.9,52.2,62.9,52.1,63z" />
                    <path className="st5" d="M52.4,63c0.1,0,0.1,0,0.2,0c0,0.1,0.1,0,0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1C52.7,63,52.5,63.1,52.4,63z" />
                    <path className="st5" d="M52.7,62.7c-0.1,0-0.1,0-0.2-0.1C52.5,62.6,52.6,62.6,52.7,62.7C52.7,62.6,52.7,62.7,52.7,62.7z" />
                    <path className="st5" d="M52.5,62.5c0.1-0.2-0.1-0.1-0.2-0.2C52.4,62.2,52.6,62.4,52.5,62.5z" />
                    <path className="st5" d="M67.3,63.2c-0.1,0-0.1-0.1-0.1-0.2C67.2,63.1,67.3,63.1,67.3,63.2C67.3,63.2,67.3,63.2,67.3,63.2z" />
                    <path className="st5" d="M68.8,63.3c0.2,0.1,0,0.1-0.1,0.2C68.5,63.4,69,63.4,68.8,63.3z" />
                    <path className="st5" d="M78.5,64.4c0,0.1,0.1,0.1,0.1,0.2C78.5,64.6,78.3,64.4,78.5,64.4z" />
                    <path className="st5" d="M79,64.3c0.1,0.1,0.2-0.1,0.5-0.1c0,0.2-0.3,0-0.3,0.1c0,0-0.1,0.1-0.1,0.1C79.1,64.3,79,64.3,79,64.3
                  C79,64.3,79,64.3,79,64.3z" />
                    <path className="st5" d="M79.1,63.2c-0.2,0,0.2-0.2-0.1-0.3c0-0.1,0.2,0,0.2,0.1C79.1,63,79.1,63.1,79.1,63.2z" />
                    <path className="st5" d="M53.2,62.9c0.1,0,0,0.2-0.1,0.2C53.2,63,53.2,63,53.2,62.9z" />
                    <path className="st5" d="M78.8,64.3c-0.1,0-0.2,0-0.2,0c0.2-0.2,0.6-0.2,0.8-0.5c0-0.2-0.2-0.2-0.2-0.4c0.2,0,0.3,0.1,0.3,0.2
                  c0,0.1,0,0.2,0.1,0.2c0,0.2,0.1,0.1,0.1,0.3c0,0-0.1,0.1-0.1,0.1c-0.3-0.1-0.3,0.2-0.5,0.1C78.9,64.3,78.8,64.2,78.8,64.3z" />
                    <path className="st5" d="M64.3,63.6C64.3,63.6,64.3,63.6,64.3,63.6c0.1,0.1,0,0.1,0,0.2C64.2,63.8,64.2,63.7,64.3,63.6
                  C64.2,63.6,64.2,63.6,64.3,63.6z" />
                    <path className="st5" d="M64,63.9c-0.1,0-0.3-0.1-0.1-0.1C63.9,63.9,64,63.9,64,63.9z" />
                    <path className="st5" d="M64,63.9c0,0-0.1-0.1-0.1-0.1c0,0,0.1,0,0.1,0c0,0.1,0,0.1,0.1,0.1C64.1,64,64,63.9,64,63.9z" />
                    <path className="st5" d="M63.4,63.9c0-0.1,0.2,0.1,0.2,0.1c0,0,0,0-0.1,0C63.5,64,63.4,64,63.4,63.9z" />
                    <path className="st5" d="M63.5,64c0,0,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1c0,0-0.1,0-0.1,0.1c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.1,0.1,0.2,0.1
                  c0,0,0,0,0,0.1c-0.3,0-0.3-0.2-0.5-0.2c0.1-0.1,0.1-0.3-0.1-0.4c0,0,0,0,0-0.1C63.4,64,63.5,64,63.5,64z" />
                    <path className="st5" d="M64,64.6C64,64.6,64.1,64.6,64,64.6c0.1,0,0.1,0.1,0.2,0.2c0,0,0,0,0,0.1C64.1,64.8,64.1,64.7,64,64.6z" />
                    <path className="st5" d="M64.3,64.8C64.3,64.8,64.3,64.8,64.3,64.8c0.2,0,0.2,0.1,0.4,0.1C64.7,65.1,64.4,64.9,64.3,64.8z" />
                    <path className="st5" d="M64.8,64.6c0-0.1,0.1,0,0.2-0.1c0.1,0.1,0.2,0.1,0.3,0.2C65.1,64.7,65.1,64.6,64.8,64.6z" />
                    <path className="st5" d="M66.4,64.8C66.4,64.8,66.4,64.8,66.4,64.8c0,0,0,0.1,0,0.2c-0.1,0-0.1,0-0.1,0.1c0,0.2,0.3,0.6,0.5,0.9
                  c0,0,0,0,0,0.1c0.1,0.1,0.1,0.2,0.2,0.2c0,0.1,0,0.2,0,0.2c0,0.2,0.2,0.2,0.3,0.3c0,0,0,0.1,0,0.1c0,0.2,0.2,0.2,0.3,0.3
                  c0,0,0,0,0.1,0c0.1,0.1,0.1,0.2,0.2,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0.1,0,0.6,0.1,0.5,0c0-0.1,0.1,0,0.2-0.1
                  c0.1,0,0.2,0,0.2-0.1c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0,0,0.1-0.1,0.1
                  c-0.1,0.1-0.2,0.2-0.3,0.3C68,69,68,69,67.9,69.1c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0-0.1,0c-0.1,0.1-0.3,0.2-0.3,0.3
                  c0,0.2-0.1,0.2-0.1,0.4c0,0.2,0,0.3,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3c-0.1,0,0,0.1-0.1,0.2c0,0.1,0,0.2,0,0.3
                  c-0.3,0.2-0.6,0.4-0.8,0.7c0,0.2,0.1,0.2,0.1,0.4c0.1,0.2-0.1,0.1-0.2,0.2c-0.1,0-0.2,0-0.2,0c0.1,0.4-0.1,0.5-0.2,0.7
                  c0,0.1-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0
                  c-0.1,0-0.2,0-0.2,0.1c-0.2,0-0.1,0-0.3,0c0,0,0,0,0-0.1c-0.1-0.1,0.1-0.2,0-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1-0.1-0.1-0.2-0.2
                  c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.3-0.1-0.3c0-0.1,0-0.1-0.1-0.1c0-0.3-0.2-0.5-0.3-0.7c0-0.1,0-0.2,0-0.3
                  c0-0.1-0.1-0.3,0.1-0.3c0,0.1,0.3-0.3,0.2-0.3c0,0,0,0,0-0.1c0-0.2-0.1-0.1-0.1-0.3c0-0.1,0-0.1,0-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
                  c0,0,0-0.1,0-0.1c0-0.3-0.3-0.4-0.4-0.6c0-0.2,0.1-0.3,0.1-0.5c0.1,0,0-0.3-0.1-0.2c0,0,0-0.1-0.1-0.1c0-0.2-0.3-0.1-0.4-0.2
                  c0,0,0,0-0.1,0c-0.3-0.4-0.7,0.1-1.1,0.1c0-0.1-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.1
                  c0-0.1-0.1-0.1-0.2-0.1c-0.4-0.1-0.4-0.7-0.7-0.9c-0.1-0.2,0-0.5,0-0.7c0,0,0-0.1,0-0.1c0.1,0,0.1-0.2,0-0.2c0-0.1,0-0.2-0.1-0.2
                  c0-0.1,0-0.1,0-0.2c0-0.2,0.2-0.4,0.3-0.5c0.2-0.3,0.5-0.4,0.7-0.7c0,0,0-0.1,0-0.1c0.1,0,0-0.1,0.1-0.2c0,0,0,0,0-0.1
                  c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.2,0.3-0.3c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0.3,0.2,0.5-0.2,0.8-0.2c0,0,0.1,0,0.1-0.1
                  c0,0,0.1,0,0.1,0c0,0.1,0.5,0.1,0.5,0c0,0,0.1,0,0.1,0c0.1,0.1,0.1,0.3,0.1,0.4c0.2,0.1,0.2,0.3,0.5,0.2c0,0,0.1,0,0.1,0
                  c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0,0.1,0c0.1,0.1,0.4,0.3,0.4,0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0.1,0c0.2,0,0.3,0.1,0.5,0.1
                  c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0.1c0,0.1,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0c0,0.1,0.2,0.1,0.2,0C66.1,64.7,66.2,64.9,66.4,64.8z
                   M65.3,71.2c0-0.1,0.1-0.1,0-0.2C65.3,71.1,65.2,71.1,65.3,71.2z" />
                    <path className="st5" d="M66.8,65.9c-0.2-0.2-0.5-0.6-0.5-0.9c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0.1,0.1C66.5,65.6,66.7,65.7,66.8,65.9
                  z" />
                    <path className="st5" d="M66.8,66c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0.1,0.1C66.8,66.2,66.8,66.1,66.8,66z" />
                    <path className="st5" d="M66.9,66.4C67,66.4,67,66.4,66.9,66.4c0.1,0.2,0.2,0.2,0.3,0.3C67.1,66.7,67,66.6,66.9,66.4z" />
                    <path className="st5" d="M67.2,66.9c0.1,0.1,0.2,0.2,0.3,0.3C67.4,67.1,67.2,67.1,67.2,66.9z" />
                    <path className="st5" d="M67.8,67.4c-0.1-0.1-0.2-0.1-0.2-0.2C67.7,67.2,67.7,67.3,67.8,67.4z" />
                    <path className="st5" d="M67.8,67.6c0.1,0.1,0.2,0.2,0.5,0.2c0.1,0.1-0.4,0-0.5,0C67.8,67.7,67.8,67.6,67.8,67.6z" />
                    <path className="st5" d="M68.5,68.5c0-0.2,0.1-0.3,0.2-0.3C68.6,68.3,68.5,68.4,68.5,68.5z" />
                    <path className="st5" d="M68.1,68.9c0.1-0.1,0.2-0.2,0.3-0.3C68.4,68.8,68.2,68.8,68.1,68.9z" />
                    <path className="st5" d="M67.3,69.8c0-0.2,0.2-0.2,0.3-0.3c0,0.1-0.1,0.1-0.1,0.2C67.4,69.7,67.4,69.7,67.3,69.8
                  C67.3,69.8,67.3,69.8,67.3,69.8z" />
                    <path className="st5" d="M66.7,72.6c0-0.2-0.2-0.2-0.1-0.4c0.2-0.3,0.5-0.5,0.8-0.7c0-0.1,0-0.2,0-0.3c0,0,0,0,0.1,0
                  c0.2,0.4-0.3,0.5-0.5,0.7c-0.1,0.1-0.2,0.2-0.2,0.3C66.5,72.2,66.8,72.4,66.7,72.6C66.7,72.6,66.7,72.6,66.7,72.6z" />
                    <path className="st5" d="M66.1,73.5c0.1-0.2,0.3-0.3,0.2-0.7c0.1,0,0.2,0,0.2,0c0,0.1-0.1,0-0.2,0.1c0,0.1,0,0.3,0,0.4
                  c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0,0,0.1C66.1,73.5,66.1,73.5,66.1,73.5z" />
                    <path className="st5" d="M65.7,73.9c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0,0.1C65.9,73.8,65.8,73.8,65.7,73.9
                  C65.7,73.9,65.7,73.9,65.7,73.9z" />
                    <path className="st5" d="M64.6,74c-0.1,0,0-0.2,0-0.2C64.7,73.8,64.5,73.9,64.6,74z" />
                    <path className="st5" d="M63.9,71.2c0.1-0.1,0.1-0.2,0.2-0.3C64.1,70.9,63.9,71.3,63.9,71.2z" />
                    <path className="st5" d="M63.9,70.5C63.9,70.5,63.9,70.5,63.9,70.5c0,0.1,0.2,0.1,0.1,0.3C63.9,70.7,63.9,70.6,63.9,70.5z" />
                    <path className="st5" d="M63.8,70.1c0.1,0,0.1,0.2,0.2,0.2c0,0,0,0-0.1,0C63.9,70.2,63.8,70.3,63.8,70.1z" />
                    <path className="st5" d="M63.7,69.8c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0-0.1,0-0.1c0.1,0.2,0.4,0.3,0.4,0.6C63.7,70,63.8,69.8,63.7,69.8z" />
                    <path className="st5" d="M62.9,68.4c0.1,0.1,0.4,0,0.4,0.2c-0.1,0-0.2-0.1-0.3-0.1C63,68.6,62.9,68.5,62.9,68.4z" />
                    <path className="st5" d="M60,67.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0.1-0.1c0,0.2-0.1,0.5,0,0.7C60,67.5,60,67.3,60,67.1z" />
                    <path className="st5" d="M60.4,65.6c0.1-0.2,0.3-0.4,0.5-0.5c0.1,0,0.1-0.1,0.2-0.2c0,0,0,0,0.1,0C60.9,65.2,60.6,65.4,60.4,65.6z" />
                    <path className="st5" d="M61.3,64.4c0.2,0,0.2-0.4,0.4-0.2c0,0,0,0,0,0.1c-0.1,0-0.1,0-0.1-0.1c-0.1,0.2-0.2,0.3-0.3,0.3
                  C61.2,64.5,61.3,64.5,61.3,64.4z" />
                    <path className="st5" d="M61.9,64.3c0.3,0,0.3-0.2,0.6-0.2c0,0,0.1,0,0.1,0C62.3,64.1,62.1,64.4,61.9,64.3
                  C61.8,64.3,61.8,64.3,61.9,64.3z" />
                    <path className="st5" d="M65.5,64.2c0.2,0.2-0.7-0.1-0.2-0.1C65.3,64.2,65.4,64.2,65.5,64.2z" />
                    <path className="st5" d="M79.1,64.4c0.1,0,0.1,0,0.1-0.1C79.2,64.4,79.1,64.5,79.1,64.4z" />
                    <path className="st5" d="M66.4,64.8c0-0.1,0.2,0,0.2-0.1c-0.1,0.1,0,0.1,0,0.2c0,0,0,0.1-0.1,0.1c0,0,0,0,0-0.1
                  C66.5,64.9,66.5,64.9,66.4,64.8C66.4,64.8,66.4,64.8,66.4,64.8z" />
                    <path className="st5" d="M66.4,65c0-0.1,0-0.1,0.1-0.2c0.1,0,0.1,0.1,0.1,0.1c0,0-0.1-0.1-0.1-0.1C66.4,64.9,66.4,65,66.4,65
                  C66.4,65,66.4,65,66.4,65z" />
                    <path className="st5" d="M66.5,65C66.6,65,66.6,65,66.5,65c0.1-0.1,0.1,0,0.1,0C66.6,65,66.6,65,66.5,65c0.1,0.1,0.1,0.2,0,0.2
                  c0-0.1,0-0.1-0.1-0.1C66.5,65.1,66.5,65.1,66.5,65z" />
                    <path className="st5" d="M66.3,65.1C66.3,65,66.3,65,66.3,65.1c0,0,0.2,0.2,0.1,0.2C66.4,65.2,66.3,65.1,66.3,65.1z" />
                    <path className="st5" d="M66.4,65.2c0.1,0-0.1-0.2-0.1-0.2c0,0,0,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
                  C66.5,65.2,66.5,65.3,66.4,65.2C66.4,65.3,66.4,65.3,66.4,65.2z" />
                    <path className="st5" d="M48,65.8c-0.2-0.1-0.1-0.4-0.2-0.5c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0,0.1,0.1,0.1C48,65.6,48.1,65.7,48,65.8
                  C48,65.7,48,65.8,48,65.8z" />
                    <path className="st5" d="M67.1,65.8c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0.1,0,0.1C67.1,66,67.1,65.9,67.1,65.8z" />
                    <path className="st5" d="M67.6,66.7C67.6,66.7,67.6,66.7,67.6,66.7c0.1,0.1,0.2,0.1,0.2,0.2C67.7,66.8,67.6,66.8,67.6,66.7z" />
                    <path className="st5" d="M66.9,66.2c0,0-0.1-0.1-0.1-0.1c0.1,0,0.1,0.2,0.1,0.3c0,0,0,0-0.1,0C66.9,66.4,66.9,66.3,66.9,66.2z" />
                    <path className="st5" d="M52.1,65.6c0.1-0.1,0.3-0.2,0.1-0.1c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0c0,0.1,0,0.1,0,0.2
                  c-0.1-0.1-0.2,0-0.2,0.2C52.2,65.7,52.2,65.6,52.1,65.6z" />
                    <path className="st5" d="M52.3,65.7c0,0.1-0.2,0-0.1-0.1C52.2,65.6,52.2,65.7,52.3,65.7z" />
                    <path className="st5" d="M52.2,65.5c-0.1,0,0-0.1,0.1-0.1C52.2,65.4,52.2,65.4,52.2,65.5z" />
                    <path className="st5" d="M77.6,65.8c0-0.1,0.2-0.2,0.2,0C77.7,65.7,77.7,65.7,77.6,65.8z" />
                    <path className="st5" d="M77.8,65.8c0,0.1-0.1,0.1-0.1,0.3c-0.1,0-0.1-0.1-0.1-0.3C77.7,65.7,77.7,65.7,77.8,65.8z" />
                    <path className="st5" d="M51.9,66c0.3,0,0.4,0.3,0.7,0.3c-0.1,0.2-0.2,0-0.5,0.1c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1-0.2-0.3-0.2
                  C51.8,66,51.8,66,51.9,66z" />
                    <path className="st5" d="M53.6,66.6C53.6,66.6,53.6,66.6,53.6,66.6C53.8,66.6,53.7,66.8,53.6,66.6z" />
                    <path className="st5" d="M53.6,66.6C53.6,66.6,53.6,66.7,53.6,66.6c-0.1,0.1-0.2,0-0.2-0.1C53.5,66.6,53.6,66.6,53.6,66.6
                  C53.6,66.6,53.6,66.6,53.6,66.6z" />
                    <path className="st5" d="M53.9,66.6c0.1,0.1,0.2,0.1,0.2,0.2C54.1,66.7,53.9,66.7,53.9,66.6z" />
                    <path className="st5" d="M78,67.5C78,67.5,78,67.5,78,67.5c0,0,0.3,0.1,0.2,0.1C78.2,67.6,78.1,67.6,78,67.5
                  C78.1,67.5,78.1,67.5,78,67.5z" />
                    <path className="st5" d="M78.1,67.6c0,0,0,0.1,0.1,0.1C78.2,67.9,77.9,67.6,78.1,67.6z" />
                    <path className="st5" d="M78.1,67.2C78.1,67.2,78,67.2,78,67c0,0,0-0.1,0.1-0.1C78,67.1,78.1,67.1,78.1,67.2z" />
                    <path className="st5" d="M78,67.2c-0.3-0.1-0.2-0.3-0.2-0.6c0.2-0.1,0.1,0.1,0.2,0.1c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0.1
                  c-0.1,0-0.1,0-0.1,0.1C77.9,67.2,78.1,67.2,78,67.2z" />
                    <path className="st5" d="M59.2,67c0-0.1-0.2-0.1-0.2-0.2C59.1,66.7,59.2,66.8,59.2,67z" />
                    <path className="st5" d="M78,67c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0.1,0,0.1c-0.1,0-0.1,0-0.2-0.1C78.1,67.2,77.9,67.2,78,67
                  C77.9,67,77.9,67,78,67z" />
                    <path className="st5" d="M78.1,67.2c0.1,0,0.2,0,0.2,0.1c0,0.1-0.1,0-0.2,0.1C78.1,67.3,78.1,67.3,78.1,67.2z" />
                    <path className="st5" d="M75,67.4c0.1,0,0.1-0.1,0.2,0c0,0,0,0,0,0.1c-0.1,0,0,0.3-0.1,0.3C74.9,67.7,75.1,67.4,75,67.4z" />
                    <path className="st5" d="M54.1,67.5c0.1-0.1,0.2,0.1,0.2,0.2C54.2,67.7,54.2,67.5,54.1,67.5z" />
                    <path className="st5" d="M78.4,67.6c0.2-0.1,0.1,0.1,0.1,0.2C78.4,67.9,78.4,67.8,78.4,67.6z" />
                    <path className="st5" d="M55.2,68.5c0.1,0.1,0.2,0.1,0.2,0.2C55.4,68.7,55.3,68.6,55.2,68.5z" />
                    <path className="st5" d="M55.3,69.4c0-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0,0.1C55.4,69.3,55.4,69.3,55.3,69.4z" />
                    <path className="st5" d="M55.3,69.4c0,0,0,0.1,0.1,0.1C55.4,69.6,55.1,69.4,55.3,69.4z" />
                    <path className="st5" d="M57.1,69.8c-0.1,0-0.1-0.1-0.2-0.1C57,69.6,57,69.7,57.1,69.8z" />
                    <path className="st5" d="M57.6,70c0.1,0.1,0.1,0.3,0,0.4C57.6,70.2,57.6,70.3,57.6,70z" />
                    <path className="st5" d="M54.7,74.9c-0.2-0.1,0.1-0.2,0.3-0.2C55,74.9,54.6,74.7,54.7,74.9z" />
                    <path className="st5" d="M54.4,75.8c-0.1,0,0-0.2,0.1-0.2C54.5,75.7,54.4,75.7,54.4,75.8z" />
                    <path className="st5" d="M54.5,76.4c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0c0,0,0,0,0-0.1C54.5,76.6,54.5,76.5,54.5,76.4z" />
                    <path className="st5" d="M51,67.7c0-0.1,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1C51.1,67.7,51.1,67.6,51,67.7C51,67.7,51,67.7,51,67.7z" />
                    <path className="st5" d="M51.2,67.6C51.2,67.6,51.2,67.6,51.2,67.6c0.1,0,0.1,0.1,0.1,0.1C51.2,67.8,51.2,67.7,51.2,67.6
                  c0,0.1-0.1,0.1-0.1,0.1C51.1,67.6,51.1,67.7,51.2,67.6z" />
                    <path className="st5" d="M77.6,67.8c0.1-0.1,0.1-0.2,0.2-0.2c0,0.1,0,0.2-0.1,0.2c0,0,0,0-0.1,0c0,0,0.1-0.1,0.1-0.2
                  C77.7,67.7,77.7,67.8,77.6,67.8z" />
                    <path className="st5" d="M77.6,68c-0.1,0.2-0.2-0.1,0-0.1C77.6,67.9,77.5,68,77.6,68z" />
                    <path className="st5" d="M51.2,67.7c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1C51.3,67.8,51.2,67.8,51.2,67.7z" />
                    <path className="st5" d="M51,67.8C51,67.7,51,67.7,51,67.8C51,67.7,51,67.7,51,67.8c0.1-0.1,0.1-0.1,0.2-0.1c0,0.1,0.1,0.1,0.2,0.2
                  c0.1,0,0.1,0.2,0.3,0.1c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.3-0.1c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.2-0.2C51,67.8,51,67.8,51,67.8
                  z" />
                    <path className="st5" d="M51.3,68c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0-0.1C51.2,67.8,51.2,67.9,51.3,68z" />
                    <path className="st5" d="M54,67.8c0.2,0.1,0-0.2,0.2-0.1c0,0.1-0.1,0.1-0.1,0.2C54.1,67.9,54.1,67.9,54,67.8C54,67.9,54,67.8,54,67.8z
                  " />
                    <path className="st5" d="M75.1,67.8c0,0.2,0.1,0.2,0.2,0.4c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1C75,67.9,75.1,67.9,75.1,67.8
                  C75.1,67.8,75.1,67.8,75.1,67.8z" />
                    <path className="st5" d="M77.6,67.8c0.1,0,0.1-0.1,0.2-0.1c0,0.1,0,0.1-0.1,0.2c-0.1,0,0,0.1-0.1,0.1C77.5,68,77.6,67.9,77.6,67.8
                  C77.6,67.9,77.6,67.8,77.6,67.8z" />
                    <path className="st5" d="M51.6,68c0-0.1,0.1-0.1,0.2-0.1c0,0-0.1,0.1-0.1,0.1C51.7,68,51.7,68,51.6,68C51.7,68,51.6,68,51.6,68z" />
                    <path className="st5" d="M72.7,68c0-0.2,0.1-0.1,0.1,0C72.7,68,72.7,68,72.7,68z" />
                    <path className="st5" d="M51.9,68C51.9,68,51.9,67.9,51.9,68c0,0,0.1,0,0.1,0c0,0.1,0,0.1-0.1,0.1C52,68,52,68,51.9,68z" />
                    <path className="st5" d="M52,68.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0.1,0.1,0.1c0,0.1-0.2,0.1-0.1,0.2C52,68.3,51.9,68.1,52,68.1z" />
                    <path className="st5" d="M72.7,68c0,0,0.1,0,0.1,0c0,0.1,0.2,0.4,0,0.3c-0.1,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1C72.7,68.1,72.7,68,72.7,68
                  z" />
                    <path className="st5" d="M72.7,68.2c0.1,0,0.1,0.1,0.1,0.1C72.8,68.5,72.6,68.3,72.7,68.2z" />
                    <path className="st5" d="M78.4,68.1c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.2,0,0.2,0.2C78.6,68,78.6,68.1,78.4,68.1z" />
                    <path className="st5" d="M78.4,68.2c-0.1,0-0.1,0-0.1,0.1c-0.1,0,0-0.2,0.1-0.2C78.4,68.1,78.4,68.1,78.4,68.2
                  C78.4,68.1,78.4,68.1,78.4,68.2z" />
                    <path className="st5" d="M75.3,68.3c-0.1-0.1-0.1-0.2-0.2-0.2c0.1,0,0.1,0,0.1,0.1c0,0.1,0.1,0,0.1,0.1c0,0.1,0.1,0.1,0.2,0.2
                  c0,0,0,0,0,0.1C75.5,68.5,75.4,68.2,75.3,68.3z" />
                    <path className="st5" d="M76.6,69c0.1,0,0.1-0.1,0.2-0.1C76.8,69,76.6,69.2,76.6,69z" />
                    <path className="st5" d="M76.6,69.6c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0.1C76.7,69.7,76.6,69.7,76.6,69.6z" />
                    <path className="st5" d="M77.6,68.3c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0,0.2-0.1,0.3c0-0.1,0.1-0.1,0.1-0.2C77.7,68.4,77.6,68.4,77.6,68.3z
                  " />
                    <path className="st5" d="M77.6,68.8c0.1,0,0.1,0.1,0.1,0.2C77.6,69,77.6,68.9,77.6,68.8z" />
                    <path className="st5" d="M77.3,68.6c0-0.1,0.1-0.1,0.2-0.2C77.4,68.5,77.4,68.5,77.3,68.6z" />
                    <path className="st5" d="M74.8,68.6c0.1,0.1,0.2,0.2,0.3,0.3C74.9,68.8,74.8,68.7,74.8,68.6z" />
                    <path className="st5" d="M75.2,69.2c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0.1C75.3,69.3,75.2,69.3,75.2,69.2z" />
                    <path className="st5" d="M75.5,69.7c0.1,0.1,0.2,0.1,0.2,0.2C75.6,69.9,75.6,69.8,75.5,69.7z" />
                    <path className="st5" d="M75.9,69.5c0.1,0,0.1,0.1,0.2,0.2C75.9,69.7,75.9,69.6,75.9,69.5z" />
                    <path className="st5" d="M75.7,69.2c0.1,0,0.1,0.1,0.1,0.2C75.7,69.4,75.7,69.3,75.7,69.2z" />
                    <path className="st5" d="M75.3,69.3c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0-0.1C75.2,69.2,75.3,69.2,75.3,69.3z" />
                    <path className="st5" d="M77.9,69.8c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0.1c-0.1-0.1-0.1,0-0.1,0.2c0,0,0,0-0.1,0
                  C77.9,69.9,77.9,69.9,77.9,69.8z" />
                    <path className="st5" d="M78,69.3c0,0.1,0,0.1,0.1,0.1c0,0,0,0,0,0.1C78,69.5,78,69.4,78,69.3c-0.1,0-0.1,0-0.1,0
                  C77.8,69.3,77.9,69.2,78,69.3C77.9,69.3,77.9,69.3,78,69.3z" />
                    <path className="st5" d="M78.1,69.2c0-0.1,0-0.1,0.1-0.1c0.1,0,0.2,0,0.2,0.1c0,0-0.1,0-0.1,0.1C78.2,69.2,78.1,69.2,78.1,69.2z" />
                    <path className="st5" d="M78.3,69.1c0.1,0,0.1-0.1,0.2-0.1C78.5,69.2,78.3,69.3,78.3,69.1C78.3,69.2,78.3,69.1,78.3,69.1z" />
                    <path className="st5" d="M77.9,69.2c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0,0.1C78,69.2,78,69.3,77.9,69.2C77.9,69.3,77.9,69.3,77.9,69.2z" />
                    <path className="st5" d="M79,69.2c0,0.2-0.2-0.1-0.1-0.1C79,69.2,78.9,69.2,79,69.2z" />
                    <path className="st5" d="M55.3,69.4c0.1,0,0.1-0.1,0.2-0.1c-0.1,0.1,0,0.1,0,0.2c0,0-0.1,0-0.1,0.1C55.3,69.5,55.3,69.5,55.3,69.4
                  C55.3,69.4,55.3,69.4,55.3,69.4z" />
                    <path className="st5" d="M55.6,69.4c-0.1,0-0.2-0.2-0.1-0.2C55.6,69.3,55.6,69.4,55.6,69.4C55.6,69.4,55.6,69.4,55.6,69.4z" />
                    <path className="st5" d="M55.7,69.2c0,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.1-0.2,0.1c0,0,0,0,0-0.1C55.8,69.4,55.7,69.3,55.7,69.2z" />
                    <path className="st5" d="M80.5,70.4c0.1,0,0.1,0.1,0.2,0.2C80.6,70.5,80.5,70.5,80.5,70.4z" />
                    <path className="st5" d="M81.6,70.7c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1c-0.1-0.1-0.1-0.1-0.1,0.1C81.6,70.8,81.6,70.7,81.6,70.7
                  z" />
                    <path className="st5" d="M79.8,69.6c0,0,0.1,0.1,0.2,0.1C80,69.8,79.8,69.7,79.8,69.6z" />
                    <path className="st5" d="M79.5,69.4c0-0.1,0.3,0,0.2,0.1C79.7,69.4,79.6,69.3,79.5,69.4z" />
                    <path className="st5" d="M78.1,69.7c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0.1,0.1,0.3c-0.1,0-0.1-0.1-0.2-0.1C78.1,69.8,78.1,69.8,78.1,69.7
                  C78.1,69.7,78.1,69.7,78.1,69.7z" />
                    <path className="st5" d="M77.8,69.8c0.1,0,0.1-0.1,0.2-0.2c0,0,0.1,0,0.1,0.1c-0.1,0-0.1,0.1-0.1,0.2C77.9,69.8,77.9,69.8,77.8,69.8
                  C77.8,69.8,77.8,69.8,77.8,69.8z" />
                    <path className="st5" d="M78.2,69.4C78.3,69.2,78.4,69.5,78.2,69.4C78.2,69.5,78.2,69.4,78.2,69.4z" />
                    <path className="st5" d="M79.5,69.4c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2,0.1,0.2c0,0.1,0.2,0.2,0.2,0.1c0.1-0.1,0.2-0.1,0.3-0.2
                  c0.4,0.2,0.8,0.3,1.2,0.6c0,0.2,0,0.4,0.2,0.6c0,0.1-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.2c0,0-0.1,0-0.1,0
                  c-0.3-0.2-0.3,0.2-0.6,0.2c0-0.1-0.1-0.1-0.2-0.2c-0.2,0-0.1,0.1-0.2,0c0-0.1,0.1,0,0.1-0.1c0-0.3-0.3-0.4-0.6-0.5
                  c0-0.1-0.2,0-0.2-0.1C79.7,69.5,79.4,69.5,79.5,69.4C79.5,69.4,79.5,69.4,79.5,69.4z" />
                    <path className="st5" d="M79.8,69.8c-0.1,0-0.2,0-0.2-0.1C79.6,69.8,79.8,69.7,79.8,69.8z" />
                    <path className="st5" d="M81.5,70.6c-0.1,0-0.1-0.1-0.2-0.2c0,0,0.1,0,0.1,0C81.4,70.5,81.4,70.5,81.5,70.6z" />
                    <path className="st5" d="M82,70c0-0.1,0.1-0.1,0.1-0.2C82.2,69.9,82.1,70.1,82,70C82,70,82,70,82,70z" />
                    <path className="st5" d="M82.3,69.9c-0.1,0-0.1-0.2-0.2-0.2C82.1,69.6,82.3,69.8,82.3,69.9z" />
                    <path className="st5" d="M77.9,69.9C77.9,70,77.9,70,77.9,69.9c0.1,0,0.1,0,0.1,0c0,0-0.1,0.1-0.1,0.1C77.8,70,77.8,69.9,77.9,69.9z" />
                    <path className="st5" d="M82.7,70.3c0,0-0.2-0.1-0.2,0C82.2,70.2,82.8,70.1,82.7,70.3C82.7,70.3,82.7,70.3,82.7,70.3z" />
                    <path className="st5" d="M76.4,70.3c-0.1,0-0.3,0-0.3-0.2c0.1,0,0.3,0,0.4,0.1C76.4,70.3,76.4,70.3,76.4,70.3z" />
                    <path className="st5" d="M76.9,70.3c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0c0,0,0,0,0-0.1C76.9,70.3,76.9,70.3,76.9,70.3z
                  " />
                    <path className="st5" d="M78.1,70.6c-0.1,0-0.1-0.1-0.2-0.1c0-0.1,0.1,0,0.2-0.1C78.1,70.5,78.1,70.5,78.1,70.6z" />
                    <path className="st5" d="M78.4,70.6c0-0.1,0.1,0,0.1-0.1c0,0,0,0,0,0.1C78.5,70.6,78.5,70.6,78.4,70.6C78.5,70.6,78.5,70.6,78.4,70.6z
                  " />
                    <path className="st5" d="M78.5,70.6C78.5,70.6,78.5,70.7,78.5,70.6c0,0.1-0.1,0.1-0.1,0.1C78.4,70.6,78.4,70.6,78.5,70.6
                  C78.5,70.6,78.5,70.6,78.5,70.6z" />
                    <path className="st5" d="M81.7,70.8C81.7,70.7,81.7,70.7,81.7,70.8C81.9,70.8,81.8,70.8,81.7,70.8z" />
                    <path className="st5" d="M80.4,71.8c0,0.1-0.2,0-0.2-0.1C80.2,71.7,80.3,71.8,80.4,71.8z" />
                    <path className="st5" d="M78.6,71.3c0.1-0.2,0.2,0,0.3,0.1c0,0,0,0,0,0.1C78.8,71.4,78.8,71.3,78.6,71.3z" />
                    <path className="st5" d="M77.8,72.1c0-0.1,0.1-0.1,0.2-0.2C77.9,72,77.9,72.1,77.8,72.1z" />
                    <path className="st5" d="M76.6,74c0,0.1,0,0.1,0.1,0.2C76.6,74.2,76.5,74,76.6,74z" />
                    <path className="st5" d="M78.8,73.8c0.1,0,0.1,0.1,0.2,0.2C78.9,74,78.9,73.9,78.8,73.8z" />
                    <path className="st5" d="M79.9,74.7c0.1-0.2,0.2,0.1,0.2,0.1C80,75,80,74.7,79.9,74.7z" />
                    <path className="st5" d="M80.7,74.5c0-0.1,0.1-0.1,0.2-0.2C80.8,74.4,80.7,74.5,80.7,74.5z" />
                    <path className="st5" d="M81.3,73.9c0-0.1,0.1-0.1,0.2-0.2C81.4,73.8,81.4,73.9,81.3,73.9z" />
                    <path className="st5" d="M81.3,72.1c-0.1,0-0.1-0.2-0.2-0.2c0-0.1,0-0.2,0.1-0.2C81.1,72,81.2,72,81.3,72.1z" />
                    <path className="st5" d="M81.1,71.9c0.1,0,0.1,0.2,0.2,0.2c0,0,0,0,0,0.1C81.2,72.1,81.1,72,81.1,71.9z" />
                    <path className="st5" d="M81.2,74c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0,0.1c-0.1,0-0.1,0.1-0.2,0.2C81.2,73.9,81.2,74,81.2,74z" />
                    <path className="st5" d="M79.1,71.2C79.1,71.2,79.1,71.2,79.1,71.2c0.1,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1-0.1
                  C79.1,71.4,79,71.3,79.1,71.2z" />
                    <path className="st5" d="M68,71.1C67.8,71.3,67.8,70.9,68,71.1L68,71.1z" />
                    <path className="st5" d="M68.2,71.5c0.1-0.1,0.1-0.2,0.2-0.2c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1C68.3,71.5,68.3,71.5,68.2,71.5z" />
                    <path className="st5" d="M68.4,71.3c0.1,0,0-0.2,0.2-0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1C68.5,71.2,68.5,71.3,68.4,71.3
                  c0,0.1,0,0.1-0.1,0.1C68.4,71.4,68.4,71.3,68.4,71.3z" />
                    <path className="st5" d="M68.5,71.9c0,0.2-0.1,0.2-0.1,0.4c0,0,0,0-0.1,0C68.3,72.1,68.4,72,68.5,71.9z" />
                    <path className="st5" d="M67.8,72.8C67.8,72.8,67.8,72.7,67.8,72.8C67.8,72.7,67.8,72.7,67.8,72.8c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0.1
                  C67.8,72.9,67.8,72.8,67.8,72.8z" />
                    <path className="st5" d="M67.8,72.4c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1C68.1,72.1,67.9,72.3,67.8,72.4z" />
                    <path className="st5" d="M67.9,71.9c0-0.2,0-0.3,0.1-0.3C68,71.7,67.9,71.8,67.9,71.9C67.9,71.9,67.9,71.9,67.9,71.9z" />
                    <path className="st5" d="M68.2,71.5c0.1,0,0.1,0,0.1-0.1c0,0.1-0.1,0.1-0.2,0.1C68.1,71.5,68.2,71.5,68.2,71.5z" />
                    <path className="st5" d="M64.6,74.1c0.2,0,0.1,0,0.3,0c0,0.1-0.1,0-0.1,0.1C64.7,74.2,64.6,74.2,64.6,74.1z" />
                    <path className="st5" d="M79.8,75.1c0.1,0.1,0.1,0,0.3,0c0,0.2-0.2,0.3-0.4,0.3c0-0.1,0-0.1,0-0.2C79.7,75.3,79.7,75.2,79.8,75.1
                  C79.8,75.1,79.8,75.1,79.8,75.1z" />
                    <path className="st5" d="M54.6,76.8C54.6,76.7,54.6,76.7,54.6,76.8c0,0.1,0.3,0.2,0.2,0.2c0,0-0.1,0-0.1,0
                  C54.6,76.9,54.6,76.9,54.6,76.8z" />
                    <path className="st5" d="M55.1,77c0,0.2-0.3,0-0.1-0.1C54.9,76.9,54.9,77,55.1,77C55,77,55,77,55.1,77z" />
                    <path className="st5" d="M60.8,58.2c0.1,0,0.2,0,0.2,0.1C60.9,58.2,60.8,58.2,60.8,58.2z" />
                    <path className="st5" d="M58,58.3c0-0.1,0.3-0.1,0.3,0C58.2,58.3,58.1,58.3,58,58.3z" />
                    <path className="st5" d="M58.5,58.3c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0.1C58.7,58.3,58.6,58.3,58.5,58.3C58.5,58.3,58.5,58.3,58.5,58.3z" />
                    <path className="st5" d="M55.5,58.6c-0.3,0,0.1-0.1,0.2-0.1C55.7,58.6,55.6,58.6,55.5,58.6C55.5,58.6,55.5,58.6,55.5,58.6z" />
                    <path className="st5" d="M56.6,58.7c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0.1C56.7,58.6,56.6,58.6,56.6,58.7z" />
                    <path className="st5" d="M54.5,59c0-0.1,0.1-0.1,0.1-0.2c0.2,0,0.2,0.2,0.5,0.1c0.1,0,0.4-0.1,0.5,0.1c-0.1,0-0.1,0.1-0.3,0.1
                  c0-0.1-0.3,0-0.4-0.1C54.7,59,54.6,59,54.5,59z" />
                    <path className="st5" d="M55.1,58.9c0-0.1,0.7-0.1,0.5,0.1C55.5,58.8,55.2,58.9,55.1,58.9z" />
                    <path className="st5" d="M70.6,58.9c0-0.1,0.1,0,0.2-0.1C70.8,58.9,70.7,58.9,70.6,58.9C70.6,58.9,70.6,58.9,70.6,58.9z" />
                    <path className="st5" d="M73,59.2c0-0.1,0.1,0,0.2-0.1C73.2,59.2,73.1,59.2,73,59.2z" />
                    <path className="st5" d="M73.7,59.2c0-0.1,0.4-0.1,0.4,0C74,59.2,73.9,59.2,73.7,59.2z" />
                    <path className="st5" d="M51.8,59.2c-0.1,0-0.2,0-0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0,0.1C51.9,59.2,51.8,59.2,51.8,59.2z" />
                    <path className="st5" d="M54.2,59.2c-0.2,0-0.2,0.1-0.3,0.1c0-0.2,0.1-0.1,0.2-0.2c0.1,0,0.1,0,0.2,0C54.2,59.1,54.2,59.1,54.2,59.2z" />
                    <path className="st5" d="M73.5,59.2c0.1,0,0.1,0,0.2,0C73.6,59.3,73.5,59.3,73.5,59.2z" />
                    <path className="st5" d="M64.7,59.5c0-0.1,0.1,0,0.2-0.1C64.9,59.5,64.8,59.5,64.7,59.5z" />
                    <path className="st5" d="M76.5,59.4c0.1,0,0.4-0.1,0.5,0.1C76.9,59.4,76.6,59.5,76.5,59.4z" />
                    <path className="st5" d="M77.3,59.5c0-0.1,0.2-0.1,0.2,0C77.4,59.5,77.3,59.5,77.3,59.5z" />
                    <path className="st5" d="M48.8,59.5c0.1,0,0.3-0.1,0.3,0.1C49,59.6,48.8,59.7,48.8,59.5z" />
                    <path className="st5" d="M50.3,59.5c0.1,0,0.1,0,0.2,0C50.4,59.6,50.3,59.6,50.3,59.5z" />
                    <path className="st5" d="M77.7,59.6c0.1,0,0.2,0,0.3,0C78,59.7,77.7,59.7,77.7,59.6z" />
                    <path className="st5" d="M78.3,59.6c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0.1C78.5,59.6,78.3,59.7,78.3,59.6z" />
                    <path className="st5" d="M79.2,59.6c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0.1C79.3,59.6,79.2,59.7,79.2,59.6z" />
                    <path className="st5" d="M80.2,59.8c0.1,0,0.2,0,0.2,0.1C80.3,59.9,80.2,59.9,80.2,59.8z" />
                    <path className="st5" d="M65.7,60c0,0-0.1,0-0.1-0.1c0.1,0,0.3-0.1,0.3,0.1C65.9,60,65.7,59.9,65.7,60z" />
                    <path className="st5" d="M66.8,59.8C66.8,59.8,66.8,59.8,66.8,59.8c0.1-0.2,0.1,0.1,0.3,0.1C67,60,66.9,59.8,66.8,59.8z" />
                    <path className="st5" d="M54.1,59.9c0,0,0.1,0.1,0.1,0.1c-0.1,0-0.1,0-0.2,0C54,59.9,54,59.9,54.1,59.9C54,59.9,54.1,59.9,54.1,59.9z" />
                    <path className="st5" d="M80.5,59.9c0.1,0,0.1,0,0.1,0.1c0,0-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.2-0.1C80.5,60,80.5,60,80.5,59.9z" />
                    <path className="st5" d="M60.8,60c0-0.1,0.2-0.1,0.2,0C60.9,60,60.9,60,60.8,60z" />
                    <path className="st5" d="M54.8,60.2c-0.3,0,0-0.2,0.2-0.1C55,60.2,54.8,60.1,54.8,60.2z" />
                    <path className="st5" d="M66.3,60.2C66.1,60.3,66.2,60,66.3,60.2C66.3,60.2,66.3,60.2,66.3,60.2z" />
                    <path className="st5" d="M81.1,60.2C81.1,60.2,81.1,60.2,81.1,60.2c0,0.1-0.1,0.1-0.1,0C81,60.2,81.1,60.2,81.1,60.2z" />
                    <path className="st5" d="M68.4,60.1c0.1,0,0.1,0.1,0.1,0.2" />
                    <path className="st5" d="M45.7,60.4c0-0.1,0.2,0,0.3-0.1C46,60.5,45.8,60.4,45.7,60.4z" />
                    <path className="st5" d="M53.3,60.3c-0.2-0.1,0,0.3-0.2,0.2C53.1,60.4,53.2,60.1,53.3,60.3z" />
                    <path className="st5" d="M46.9,60.7c0-0.1,0.2-0.1,0.2,0C47.1,60.7,47,60.7,46.9,60.7z" />
                    <path className="st5" d="M65.2,60.7c0-0.1,0.2-0.1,0.2,0C65.3,60.7,65.2,60.7,65.2,60.7z" />
                    <path className="st5" d="M78.7,60.8c-0.1,0-0.1-0.2,0-0.2C78.7,60.7,78.7,60.8,78.7,60.8z" />
                    <path className="st5" d="M79.7,60.8c0-0.1,0.1-0.1,0.2-0.1C79.9,60.8,79.8,60.8,79.7,60.8z" />
                    <path className="st5" d="M65,60.8c0.1,0,0.2,0,0.2,0.1C65.1,60.9,65.1,60.9,65,60.8C65,60.9,65,60.9,65,60.8z" />
                    <path className="st5" d="M77.6,60.9c0-0.1,0.3-0.1,0.2,0C77.8,60.9,77.7,60.9,77.6,60.9z" />
                    <path className="st5" d="M54.1,61.2c0-0.1,0-0.1,0-0.2C54.2,61,54.2,61.2,54.1,61.2z" />
                    <path className="st5" d="M77.4,61.1c0,0.1,0,0.2-0.1,0.2C77.3,61.2,77.3,61.1,77.4,61.1z" />
                    <path className="st5" d="M52.6,61.2c0.1,0,0.2,0,0.2,0.1C52.7,61.2,52.6,61.3,52.6,61.2z" />
                    <path className="st5" d="M45.2,61.3c-0.1,0-0.2,0.1-0.3,0.1C44.9,61.3,45,61.2,45.2,61.3C45.2,61.3,45.2,61.3,45.2,61.3z" />
                    <path className="st5" d="M61.6,61.4c0,0.1,0,0.1-0.1,0.1c0-0.1-0.1,0-0.1,0c0,0-0.1,0-0.1,0C61.4,61.5,61.5,61.4,61.6,61.4z" />
                    <path className="st5" d="M80.1,61.8c-0.1,0-0.1-0.3,0-0.3C80.1,61.6,80.1,61.7,80.1,61.8z" />
                    <path className="st5" d="M63,61.7c0-0.1,0.2,0,0.3-0.1C63.3,61.8,63.1,61.7,63,61.7z" />
                    <path className="st5" d="M68.5,61.8c-0.1,0.1-0.2,0.1-0.3,0.1C68.2,61.8,68.4,61.7,68.5,61.8z" />
                    <path className="st5" d="M55.5,62c0-0.1,0.1-0.1,0.2-0.1C55.7,62.1,55.6,62,55.5,62z" />
                    <path className="st5" d="M55.8,62.2c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0,0.1,0,0.2C55.9,62.2,55.8,62.2,55.8,62.2z" />
                    <path className="st5" d="M55.9,62.2c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0,0.1,0.1c0,0.1-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.3,0
                  c0-0.1-0.1,0-0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1C55.8,62.2,55.9,62.2,55.9,62.2z" />
                    <path className="st5" d="M48.2,62.4c0-0.1,0-0.2,0-0.2C48.3,62.1,48.3,62.4,48.2,62.4z" />
                    <path className="st5" d="M47.9,62.4c0-0.1,0.3-0.1,0.2,0.1C48,62.5,48,62.4,47.9,62.4z" />
                    <path className="st5" d="M68.4,62.6c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.2-0.1-0.2c0,0,0,0,0.1,0C68.4,62.3,68.3,62.5,68.4,62.6
                  C68.4,62.5,68.4,62.5,68.4,62.6z" />
                    <path className="st5" d="M78.9,62.6C78.8,62.6,78.8,62.6,78.9,62.6C78.8,62.5,78.9,62.5,78.9,62.6C78.9,62.6,78.9,62.6,78.9,62.6z" />
                    <path className="st5" d="M66.8,62.6c0,0,0,0.1-0.1,0.1C66.8,62.6,66.8,62.6,66.8,62.6C66.8,62.6,66.8,62.6,66.8,62.6z" />
                    <path className="st5" d="M55.1,62.7C55,62.6,55.4,62.8,55.1,62.7C55.1,62.7,55.1,62.7,55.1,62.7z" />
                    <path className="st5" d="M62.1,62.6c0.1,0,0.1,0.3,0,0.2C62.1,62.8,62.1,62.7,62.1,62.6z" />
                    <path className="st5" d="M52.3,62.8c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0.1C52.4,62.8,52.4,62.8,52.3,62.8z" />
                    <path className="st5" d="M66.4,62.7c0.2,0,0.1,0.1,0,0.1C66.4,62.8,66.4,62.8,66.4,62.7z" />
                    <path className="st5" d="M54.5,62.9C54.5,62.9,54.5,63,54.5,62.9C54.6,63.1,54.3,62.9,54.5,62.9z" />
                    <path className="st5" d="M79.2,63c0.1,0,0.2,0,0.2,0.1C79.3,63,79.2,63,79.2,63z" />
                    <path className="st5" d="M62.7,63.1c0-0.1,0.3-0.1,0.2,0C62.9,63.1,62.8,63.1,62.7,63.1z" />
                    <path className="st5" d="M79.4,63c0,0,0.1,0,0.1,0c0.1,0.2-0.2,0-0.1,0.2c-0.1-0.1-0.3,0-0.3-0.1c0-0.1,0-0.2,0.1-0.2c0,0,0,0,0.1,0
                  C79.2,63,79.3,63,79.4,63z" />
                    <path className="st5" d="M67.3,63.2c0.2,0,0.3,0,0.3,0.1C67.5,63.3,67.5,63.2,67.3,63.2c0,0.1,0,0.2-0.1,0.2
                  C67.2,63.3,67.3,63.3,67.3,63.2C67.3,63.2,67.3,63.2,67.3,63.2z" />
                    <path className="st5" d="M65.2,63.4c0-0.1,0.2-0.1,0.2,0C65.3,63.4,65.3,63.4,65.2,63.4z" />
                    <path className="st5" d="M65.6,63.4c0-0.1,0-0.1,0.1-0.1C65.8,63.3,65.7,63.4,65.6,63.4z" />
                    <path className="st5" d="M66.9,63.4c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0.1C67,63.5,66.9,63.5,66.9,63.4z" />
                    <path className="st5" d="M63.4,63.6c-0.1,0-0.2-0.3,0-0.2C63.4,63.5,63.4,63.6,63.4,63.6z" />
                    <path className="st5" d="M65,63.6c0.1,0,0.2,0.2,0.1,0.2c0,0,0-0.1-0.1-0.1C65,63.8,65,63.7,65,63.6c-0.1,0.1-0.2,0.1-0.2,0
                  C64.9,63.6,64.9,63.6,65,63.6z" />
                    <path className="st5" d="M79.5,63.6c0.1,0,0,0.1,0.1,0.2C79.4,63.9,79.5,63.7,79.5,63.6z" />
                    <path className="st5" d="M61.2,63.8c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1C61.2,63.9,61.2,63.8,61.2,63.8z" />
                    <path className="st5" d="M64.1,63.9c0,0-0.1-0.1-0.1-0.1C64.1,63.8,64.2,63.9,64.1,63.9z" />
                    <path className="st5" d="M61.7,64c0-0.1,0.2,0,0.3-0.1C62,64.1,61.8,64,61.7,64z" />
                    <path className="st5" d="M65.1,63.9c0,0,0,0.1,0,0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0-0.1,0.1-0.1C65,63.8,65,63.8,65.1,63.9
                  C65,63.8,65.1,63.8,65.1,63.9z" />
                    <path className="st5" d="M62.5,64c0-0.1,0.1,0,0.2-0.1c0,0,0,0.1-0.1,0.1C62.6,64,62.5,64,62.5,64z" />
                    <path className="st5" d="M62.8,64c0.2,0,0.3,0,0.5,0C63.2,64.1,62.8,64.1,62.8,64z" />
                    <path className="st5" d="M66.3,64c0.1,0,0.1,0,0.2,0C66.4,64.1,66.3,64.1,66.3,64z" />
                    <path className="st5" d="M65.3,64.2c0.1,0,0.2,0,0.2,0.1C65.4,64.2,65.3,64.2,65.3,64.2z" />
                    <path className="st5" d="M61.7,64.2c0.1,0,0.2,0,0.2,0.1c0,0,0,0-0.1,0C61.8,64.3,61.7,64.3,61.7,64.2C61.7,64.3,61.7,64.2,61.7,64.2z
                  " />
                    <path className="st5" d="M66.5,64.2c0,0.1-0.1,0.1-0.2,0.1C66.3,64.2,66.4,64.2,66.5,64.2z" />
                    <path className="st5" d="M78.8,64.4C79,64.6,78.6,64.6,78.8,64.4L78.8,64.4z" />
                    <path className="st5" d="M78.5,64.4c0,0,0.1,0.1,0.2,0.1c0,0.1,0,0.2,0,0.3c-0.1,0-0.1-0.1-0.1-0.2C78.5,64.5,78.5,64.5,78.5,64.4z" />
                    <path className="st5" d="M63.9,64.6c0.1,0,0.2,0,0.2,0.1c0,0,0,0-0.1,0C64,64.6,63.9,64.6,63.9,64.6C63.9,64.6,63.9,64.6,63.9,64.6z" />
                    <path className="st5" d="M66.7,64.5c0,0.1,0,0.2-0.1,0.2C66.6,64.6,66.6,64.5,66.7,64.5z" />
                    <path className="st5" d="M64.2,64.8c0.1,0,0.2,0,0.2,0.1c0,0,0,0-0.1,0C64.3,64.8,64.2,64.8,64.2,64.8C64.2,64.8,64.2,64.8,64.2,64.8z
                  " />
                    <path className="st5" d="M50.4,65c0-0.1,0.2-0.1,0.2,0C50.5,65,50.5,65,50.4,65z" />
                    <path className="st5" d="M48.1,65.1c-0.1,0,0-0.1-0.1-0.2C48.1,64.9,48.1,65,48.1,65.1z" />
                    <path className="st5" d="M66.7,65c0.1,0,0.1,0.1,0.1,0.2C66.6,65.2,66.7,65.1,66.7,65C66.6,65,66.6,65,66.7,65z" />
                    <path className="st5" d="M68.4,65c0,0.1,0,0.2,0,0.2C68.3,65.2,68.3,65,68.4,65C68.3,65,68.3,65,68.4,65z" />
                    <path className="st5" d="M52,65.4c-0.1,0-0.1-0.1-0.1-0.2C52,65.1,52,65.3,52,65.4z" />
                    <path className="st5" d="M48.1,65.8c0,0,0.1,0.1,0.1,0.2C48.1,65.9,48,65.9,48.1,65.8C48,65.8,48,65.7,48.1,65.8z" />
                    <path className="st5" d="M51.6,65.9c0.1,0,0.3-0.1,0.3,0.1c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0C51.7,66,51.6,66,51.6,65.9
                  C51.6,66,51.6,65.9,51.6,65.9z" />
                    <path className="st5" d="M51.7,66c-0.2,0-0.2,0.1-0.3,0.1c0-0.1,0.2-0.1,0.2-0.2c0,0,0,0,0,0.1C51.6,66,51.7,66,51.7,66z" />
                    <path className="st5" d="M73.5,66.1c0-0.1,0.1-0.1,0.2-0.1C73.7,66.1,73.6,66.1,73.5,66.1z" />
                    <path className="st5" d="M49.6,66c0.1,0,0,0.1,0.1,0.2C49.6,66.2,49.6,66.1,49.6,66z" />
                    <path className="st5" d="M60.1,66.3c-0.1,0-0.1-0.2,0-0.2C60.1,66.2,60.1,66.3,60.1,66.3z" />
                    <path className="st5" d="M71.2,66.2c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0.1C71.3,66.3,71.2,66.3,71.2,66.2z" />
                    <path className="st5" d="M71.6,66.3c0-0.1,0-0.1,0-0.2C71.6,66.2,71.6,66.3,71.6,66.3z" />
                    <path className="st5" d="M52.8,66.4c0-0.1,0.4-0.1,0.3,0C53,66.4,52.9,66.4,52.8,66.4z" />
                    <path className="st5" d="M76.3,66.4c0-0.1,0.3-0.1,0.2,0.1c0,0,0,0-0.1,0C76.4,66.5,76.4,66.4,76.3,66.4z" />
                    <path className="st5" d="M71.6,66.4c0.1,0,0.1,0.1,0.1,0.2C71.6,66.6,71.6,66.5,71.6,66.4z" />
                    <path className="st5" d="M52.6,66.6C52.4,66.6,52.5,66.5,52.6,66.6C52.7,66.6,52.6,66.6,52.6,66.6z" />
                    <path className="st5" d="M52.7,66.6C52.7,66.6,52.7,66.6,52.7,66.6c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1
                  C52.8,66.6,52.7,66.6,52.7,66.6z" />
                    <path className="st5" d="M53.1,66.6c0-0.1,0.2,0,0.2-0.1C53.5,66.6,53.2,66.7,53.1,66.6z" />
                    <path className="st5" d="M52,66.7c-0.1-0.2,0.2-0.1,0.2-0.1C52.2,66.7,52.1,66.7,52,66.7z" />
                    <path className="st5" d="M52.9,66.6c0.1,0,0.1,0,0.2,0C53,66.7,52.9,66.7,52.9,66.6z" />
                    <path className="st5" d="M54.3,66.8c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0C54.3,66.7,54.3,66.7,54.3,66.8z" />
                    <path className="st5" d="M54.3,67c0.2,0,0.1,0.1,0.2,0.2C54.3,67.2,54.3,67.1,54.3,67z" />
                    <path className="st5" d="M76.4,67.4c-0.1,0-0.1-0.1-0.1-0.2C76.4,67.3,76.4,67.3,76.4,67.4z" />
                    <path className="st5" d="M75.2,67.4c0.1,0.1,0,0.3-0.1,0.3c0,0,0,0-0.1,0c0,0,0,0,0-0.1C75.1,67.7,75,67.4,75.2,67.4z" />
                    <path className="st5" d="M51.2,67.6c0-0.1,0-0.2,0.1-0.2C51.3,67.4,51.3,67.6,51.2,67.6z" />
                    <path className="st5" d="M68.7,67.6c0-0.1,0.1,0,0.2-0.1c0,0.1,0,0.1,0,0.2C68.7,67.8,68.8,67.6,68.7,67.6z" />
                    <path className="st5" d="M78.4,67.5c0.1,0,0.2,0.1,0.2,0.2C78.5,67.7,78.4,67.6,78.4,67.5z" />
                    <path className="st5" d="M78.2,67.7c-0.1,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1C78.1,67.6,78.2,67.6,78.2,67.7C78.2,67.7,78.2,67.7,78.2,67.7
                  z" />
                    <path className="st5" d="M72.5,67.8c0-0.1-0.1-0.3,0.1-0.3C72.6,67.6,72.7,67.8,72.5,67.8z" />
                    <path className="st5" d="M52.9,67.8c0.2,0-0.2,0.3-0.1,0.1C52.8,67.8,52.8,67.8,52.9,67.8z" />
                    <path className="st5" d="M78.3,67.9c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0C78.3,67.9,78.3,67.9,78.3,67.9z" />
                    <path className="st5" d="M51.6,68.1c0,0,0.1,0,0.1-0.1c0,0,0,0,0.1,0C51.7,68.1,51.7,68.2,51.6,68.1C51.6,68.1,51.6,68.1,51.6,68.1z" />
                    <path className="st5" d="M78.6,68.3c0.1,0.3-0.2,0-0.2-0.1c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0.1,0,0.2-0.1,0.3,0c0,0,0,0,0,0.1
                  C78.7,68.2,78.6,68.2,78.6,68.3z" />
                    <path className="st5" d="M61.6,68.4c0.1,0,0.2,0,0.2,0.1C61.7,68.5,61.6,68.5,61.6,68.4C61.6,68.5,61.6,68.5,61.6,68.4z" />
                    <path className="st5" d="M75.6,68.4c0.2,0,0.1,0.3,0.2,0.5c0,0.1,0,0.1-0.1,0.1c-0.2-0.1-0.4-0.3-0.4-0.7
                  C75.4,68.2,75.5,68.5,75.6,68.4z" />
                    <path className="st5" d="M52,68.6c0-0.1,0-0.1,0-0.2C52.1,68.4,52.1,68.6,52,68.6z" />
                    <path className="st5" d="M62.9,68.4c0,0.1,0,0.1,0.1,0.1c0,0.1-0.1,0-0.2,0.1C62.9,68.6,62.9,68.5,62.9,68.4
                  C62.9,68.4,62.9,68.4,62.9,68.4z" />
                    <path className="st5" d="M71.7,68.7c-0.1,0-0.2,0-0.2-0.2c0.1,0,0.1,0.1,0.1,0C71.7,68.5,71.7,68.6,71.7,68.7z" />
                    <path className="st5" d="M77.6,68.7c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0.1,0.2c-0.1,0.2-0.2,0.5-0.3,0.7c-0.2,0-0.2-0.1-0.4-0.1
                  c0-0.1-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c0-0.2-0.1-0.4-0.1-0.5c0,0,0.1-0.1,0.1-0.1c0,0.1,0.2,0,0.2-0.1
                  c0.2-0.1,0.2-0.4,0.5-0.4c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0.1,0,0.1-0.1c0,0.1,0.1,0.1,0.1,0.2C77.7,68.6,77.6,68.6,77.6,68.7z" />
                    <path className="st5" d="M78.9,69.1C78.8,69,79.2,68.8,78.9,69.1C79,69.1,79,69.1,78.9,69.1z" />
                    <path className="st5" d="M78,69.1c0-0.1,0.1,0,0.1-0.1C78.1,69.1,78.1,69.1,78,69.1C78.1,69.2,78,69.2,78,69.1
                  C78,69.2,78,69.1,78,69.1z" />
                    <path className="st5" d="M79,69.2c-0.1,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0.1,0C79,69.1,79,69.2,79,69.2z" />
                    <path className="st5" d="M77.8,69.8c0-0.1,0-0.2,0.1-0.2c0.1,0,0.1-0.1,0.1-0.2c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0.1
                  c0,0,0,0,0,0.1c0,0.1,0,0.1-0.1,0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1C77.9,69.7,77.9,69.7,77.8,69.8z" />
                    <path className="st5" d="M79.1,69.6c0,0,0.1,0.1,0.1,0.1c-0.1,0-0.2,0-0.2-0.1C79,69.6,79.1,69.6,79.1,69.6z" />
                    <path className="st5" d="M79.2,69.8c0-0.1,0-0.1-0.1-0.1c0.1,0,0.2,0,0.2,0.1C79.2,69.8,79.2,69.8,79.2,69.8z" />
                    <path className="st5" d="M81.9,70.2C81.7,70.2,81.7,70.1,81.9,70.2C81.9,70.1,81.9,70.1,81.9,70.2z" />
                    <path className="st5" d="M63.9,70.5c0-0.1,0-0.1,0-0.2c0,0,0,0,0.1,0C64,70.4,64,70.4,63.9,70.5C63.9,70.5,63.9,70.5,63.9,70.5z" />
                    <path className="st5" d="M77,70.3c0.1,0,0.3,0.1,0.2,0.2c0-0.1-0.1-0.1-0.2-0.1C77,70.4,77,70.4,77,70.3z" />
                    <path className="st5" d="M77,70.4c0.1,0,0.2,0,0.2,0.1C77.1,70.5,76.9,70.5,77,70.4C77,70.4,77,70.4,77,70.4z" />
                    <path className="st5" d="M67.3,70.6c-0.1,0-0.1-0.2-0.1-0.3C67.3,70.3,67.3,70.5,67.3,70.6z" />
                    <path className="st5" d="M77.6,70.6c0,0-0.1,0-0.1,0c-0.1-0.2,0.1-0.1,0.2-0.1C77.7,70.5,77.6,70.5,77.6,70.6z" />
                    <path className="st5" d="M77.6,70.6c0-0.1,0.1-0.1,0.1-0.1C77.8,70.5,77.7,70.6,77.6,70.6z" />
                    <path className="st5" d="M78.5,70.6C78.5,70.5,78.5,70.5,78.5,70.6c0-0.1,0.1-0.1,0.2-0.1c0,0-0.1,0.1-0.1,0.1
                  C78.6,70.6,78.6,70.6,78.5,70.6z" />
                    <path className="st5" d="M68.7,71.4C68.7,71.3,68.7,71.3,68.7,71.4c0-0.1,0-0.1,0-0.1c0.1,0,0.1,0.3,0,0.2
                  C68.7,71.4,68.7,71.4,68.7,71.4z" />
                    <path className="st5" d="M78.9,71.4C78.9,71.4,78.9,71.4,78.9,71.4c0.1,0,0.1-0.2,0.2-0.2C79,71.3,79.1,71.4,78.9,71.4
                  C78.9,71.4,78.9,71.4,78.9,71.4z" />
                    <path className="st5" d="M81.1,71.6c-0.1,0-0.1-0.1-0.1-0.2C81.1,71.4,81.1,71.5,81.1,71.6z" />
                    <path className="st5" d="M68.7,71.4c-0.1,0,0,0.2-0.1,0.3c-0.1,0,0,0.3-0.1,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0,0,0.1,0,0.1
                  c-0.1,0,0,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2
                  c0.1,0,0.3-0.3,0.2-0.3c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.2,0.1-0.3c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2-0.1c0,0,0-0.1,0.1-0.1
                  c0,0,0.1,0,0.1-0.1C68.5,71.3,68.5,71.2,68.7,71.4C68.7,71.3,68.7,71.3,68.7,71.4z" />
                    <path className="st5" d="M68.6,71.6c0-0.1-0.1-0.3,0.1-0.3c0,0,0,0.1,0,0.1C68.7,71.6,68.7,71.6,68.6,71.6z" />
                    <path className="st5" d="M57,72.1c0-0.1,0-0.2,0.1-0.2C57.1,72,57.1,72.1,57,72.1z" />
                    <path className="st5" d="M67.9,72.2c0-0.1,0-0.2,0-0.2c0,0,0,0,0.1,0C68,72,68,72,67.9,72.2C68,72.1,67.9,72.2,67.9,72.2z" />
                    <path className="st5" d="M77.5,72.2c0-0.1,0.1,0,0.2-0.1C77.7,72.2,77.6,72.2,77.5,72.2z" />
                    <path className="st5" d="M53.2,72.3c-0.1,0,0-0.1-0.1-0.2C53.3,72.1,53.2,72.2,53.2,72.3z" />
                    <path className="st5" d="M68.3,72.5C68.3,72.5,68.3,72.5,68.3,72.5c0-0.1,0-0.1,0-0.2c0,0,0,0,0.1,0C68.4,72.4,68.4,72.5,68.3,72.5z" />
                    <path className="st5" d="M53.2,72.4c0,0.1,0,0.2-0.1,0.2C53.2,72.5,53.2,72.5,53.2,72.4z" />
                    <path className="st5" d="M56.6,72.5c0.1,0,0.1,0,0.2,0C56.8,72.6,56.6,72.6,56.6,72.5z" />
                    <path className="st5" d="M68.3,72.7c0-0.1,0-0.2,0.1-0.2c0,0,0,0,0,0.1C68.3,72.6,68.3,72.7,68.3,72.7z" />
                    <path className="st5" d="M76.8,72.8c-0.1,0-0.1-0.2,0-0.2C76.8,72.7,76.8,72.7,76.8,72.8z" />
                    <path className="st5" d="M68.1,72.8c0,0.1-0.2,0.2-0.2,0.1c0,0,0,0,0-0.1C68,72.8,68.1,72.8,68.1,72.8z" />
                    <path className="st5" d="M76.7,73.2c0-0.1-0.1-0.3,0.1-0.3C76.7,73,76.8,73.2,76.7,73.2z" />
                    <path className="st5" d="M64.2,73c0,0.1,0,0.2,0,0.2C64.1,73.2,64.1,73.1,64.2,73C64.1,73,64.1,73,64.2,73C64.2,73,64.2,73,64.2,73z" />
                    <path className="st5" d="M65.5,74c-0.1,0.1-0.2,0.1-0.3,0.1c0,0,0,0,0-0.1C65.3,74,65.4,74,65.5,74z" />
                    <path className="st5" d="M79.3,74.2c0.1,0,0.1,0.1,0.1,0.2C79.4,74.3,79.4,74.3,79.3,74.2c0,0,0,0.1,0,0.1
                  C79.3,74.3,79.3,74.2,79.3,74.2z" />
                    <path className="st5" d="M54.5,76.4c0-0.1,0-0.2,0.1-0.2C54.6,76.4,54.6,76.4,54.5,76.4z" />
                    <path className="st5" d="M54.5,76.6C54.6,76.6,54.6,76.6,54.5,76.6c0.1,0.1,0,0.1,0,0.1C54.5,76.7,54.5,76.7,54.5,76.6
                  C54.5,76.6,54.5,76.6,54.5,76.6z" />
                    <path className="st5" d="M54.4,76.6c0,0,0.1,0.1,0,0.1c-0.1,0-0.1,0-0.2,0C54.3,76.6,54.3,76.6,54.4,76.6
                  C54.4,76.6,54.4,76.6,54.4,76.6z" />
                    <path className="st5" d="M59.6,58.2c0.1,0,0.2,0,0.3,0c0,0,0,0,0.1,0c0,0.1,0.2,0.1,0.2,0c0.2,0,0.2-0.1,0.3-0.1c0,0.1,0.2,0,0.3,0.1
                  c0,0.1,0.1,0,0.2,0.1c0,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0,0.2,0.1c0,0,0,0,0,0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.2,0.1-0.2,0.2
                  c0,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0,0.1c-0.2,0.1-0.4,0.1-0.6,0.2c0,0.1,0.1,0.1,0,0.1c0,0,0,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.2
                  c0,0.1,0.6,0,0.2,0.1c-0.1,0-0.2,0-0.2,0.1c-0.2,0-0.2,0.1-0.4,0.1c0-0.1-0.3-0.1-0.2,0c-0.2,0.1-0.3,0.3-0.6,0.3
                  c-0.1,0-0.2,0-0.2,0.1c-0.4,0.4-1.3,1.2-1.3-0.1c0.1-0.2,0.3-0.2,0.4-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2
                  c0-0.1,0-0.2-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.2-0.2c-0.2,0-0.7,0.1-0.7-0.1c0,0,0.1-0.1,0.2-0.1
                  c0.1,0,0.2,0,0.2-0.1c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.3-0.1,0.5-0.2c0.1,0,0.2,0,0.2-0.1c0,0,0,0,0.1,0
                  c0.1,0,0.2,0,0.3,0c0,0.1,0.1,0,0.2,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.2-0.1c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2-0.1
                  c0.1,0,0.1,0,0.2,0C59.5,58.2,59.6,58.2,59.6,58.2z" />
                    <path className="st5" d="M57.1,58.2c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0.1c-0.1,0.1-0.3,0.1-0.3,0.2c-0.1,0-0.2,0-0.2,0.1c0,0-0.1,0-0.1,0
                  c-0.1,0-0.2,0-0.2,0.1c-0.4,0-0.7,0.5-1,0.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1-0.1c0,0-0.1-0.1-0.1-0.2c0.1,0,0.1,0,0.2,0
                  c0,0.1,0.4,0.1,0.5,0c0,0,0,0,0.1,0c0.2,0-0.1-0.1-0.1-0.2c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0C56.7,58.2,57,58.3,57.1,58.2z" />
                    <path className="st5" d="M55.3,58.3c0,0.2-0.3,0.2-0.5,0.2C54.9,58.3,55.1,58.3,55.3,58.3z" />
                    <path className="st5" d="M54.5,59.2c0.1,0,0.1-0.1,0.2-0.1c0,0.1-0.2,0.1-0.1,0.2c0,0,0,0.1,0.1,0.1c0.1,0,0.1-0.1,0.2-0.2
                  c0.3-0.2,0.4,0.3,0.7,0.1c0.1,0.2,0.3,0.3,0.5,0.3c-0.2,0.2,0.1,0.3,0.2,0.4c0,0.1-0.1,0.1-0.2,0.1C56,60.1,56,60,55.9,60
                  c-0.2,0.1,0.1,0.2,0,0.3c-0.2,0-0.2-0.1-0.3-0.1c-0.1,0.2,0.3,0.2,0.1,0.2c-0.1,0-0.1,0-0.2,0c0-0.1-0.1-0.1-0.2-0.1
                  c-0.1,0,0-0.1-0.1-0.1c-0.1-0.1,0-0.1,0-0.2c0.1-0.2,0.2-0.1,0.5-0.1c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1,0-0.1,0.1-0.3,0.1
                  c0-0.1-0.1,0-0.2-0.1c0-0.1-0.2-0.1-0.2,0c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0C54.3,59.3,54.4,59.2,54.5,59.2z" />
                    <path className="st5" d="M80.7,60c0.1-0.1,0.3-0.1,0.3,0c0,0.1,0.1,0.1,0.1,0.2c0,0-0.1,0-0.1,0C80.8,60.1,80.7,60.1,80.7,60
                  C80.6,60,80.6,60,80.7,60z" />
                    <path className="st5" d="M60.3,60.2c0-0.1,0.2-0.1,0.3-0.2c0,0.1,0.2,0.1,0.2,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0.1,0.1,0.1
                  c-0.2,0.1-0.5,0.3-0.7,0.2C60.4,60.2,60.3,60.2,60.3,60.2z" />
                    <path className="st5" d="M53.9,60.2c0.1-0.1,0.1,0,0.3,0c0,0,0.1,0.1,0.1,0.2c-0.1,0-0.3-0.1-0.4,0C53.9,60.3,53.9,60.2,53.9,60.2z" />
                    <path className="st5" d="M61.3,61.5c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.2c-0.2-0.1-0.2,0.1-0.4,0.1
                  C61.3,61.7,61.4,61.7,61.3,61.5z" />
                    <path className="st5" d="M48.1,62.3c-0.2,0-0.3-0.1-0.3-0.3C48,62.1,48.1,62.2,48.1,62.3z" />
                    <path className="st5" d="M52.8,66.4c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0,0.1-0.2,0-0.2,0.1c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0
                  c0,0,0,0-0.1,0C52.8,66.5,52.8,66.5,52.8,66.4C52.8,66.5,52.8,66.5,52.8,66.4z" />
                    <path className="st5" d="M76.3,66.4c0.1,0,0.1,0,0.1,0.1c0,0.1-0.1,0.1-0.2,0.1C76.2,66.5,76.2,66.5,76.3,66.4z" />
                    <path className="st5" d="M52.6,67.6c0.1,0,0.1-0.1,0.2-0.2c0.1,0,0.1,0,0.1,0.1c-0.1,0,0,0.1-0.1,0.2c-0.1,0.3,0.3,0,0.1-0.1
                  c0,0,0,0,0-0.1c0-0.1,0.2,0,0.2-0.2c0.3,0.2,0.6,0.3,0.9,0.3c0,0,0,0,0,0.1c0.1,0,0.1,0.1,0.2,0.1c0.3,0.2,0.5,0.6,1.1,0.5
                  c0,0,0,0,0,0.1c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0,0.1,0.1,0.1c0,0.2,0.1,0.2,0.1,0.3c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.2
                  c0,0,0,0,0,0.1c-0.2,0,0.1,0.2,0.1,0.1c0,0,0-0.1,0.1-0.1c0.1,0.2,0.4,0.1,0.5-0.1c0.2,0,0.2,0.1,0.3,0.1c0,0,0,0.1,0,0.1
                  c-0.2,0,0.1,0.2,0.1,0.1c0.1,0,0.1-0.1,0.2-0.1c0,0.1,0.1,0,0.2,0.1c0,0,0,0,0.1,0c0,0.1,0.1,0.1,0.2,0.1c0.1,0.2,0.3,0.2,0.5,0.3
                  c0,0.2,0,0.2,0,0.4c-0.1,0.3-0.5,0.5-0.5,0.9c0,0.1,0,0.1,0,0.2c0,0.2-0.1,0.2-0.1,0.4c-0.1,0,0,0.1-0.1,0.2
                  c0,0.2-0.1,0.3-0.2,0.4c-0.1,0-0.1,0-0.2,0c-0.2,0.1-0.4,0.2-0.5,0.3C56,72.8,55.9,73,56,73c0,0.2,0,0.4-0.1,0.5
                  c-0.1,0,0,0.1-0.1,0.2c-0.1,0.2-0.2,0.5-0.4,0.6c-0.2,0-0.2-0.2-0.5-0.1c0.1,0.2,0.3,0.2,0.2,0.6c-0.1,0-0.1,0.1-0.2,0.1
                  c-0.2-0.1-0.5,0.1-0.3,0.2c0.1,0.2-0.1,0.2-0.3,0.1c-0.1,0.3,0.3,0.3,0.1,0.5c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.2,0.2-0.1,0.2
                  c0.1,0.1,0.1,0.2,0.2,0.2c0,0.1,0,0.2-0.1,0.2c-0.1,0,0,0.1-0.1,0.2c-0.1,0,0,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.2
                  c-0.3-0.2-0.3-0.7-0.7-0.9c0.2-0.2,0.1-0.3,0.1-0.6c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2,0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.2
                  c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.2-0.1-0.2c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3
                  c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0.1,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.2c-0.1-0.6-0.8-0.5-0.9-1
                  c0-0.1-0.1-0.1-0.2-0.2c-0.2-0.3-0.2-0.7-0.5-0.9c0-0.7,0.2-0.9,0.5-1.4c0.1,0,0.1-0.2,0-0.2c0-0.1,0-0.1,0-0.2
                  c0-0.2,0.1-0.1,0.1-0.2C52.3,67.9,52.3,67.6,52.6,67.6z" />
                    <path className="st5" d="M74.8,68.6c-0.1-0.4,0.8,0.4,0.9,0.6c0,0.1,0,0.1,0.1,0.2c0.1,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.2,0.2
                  c0,0.1,0.1,0.1,0.1,0.2C76,69.9,76,70,75.9,70c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1,0-0.3-0.2-0.3
                  c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2C75,68.8,74.9,68.7,74.8,68.6z" />
                    <path className="st5" d="M55.6,69.2c0,0,0.1,0,0.1,0c0,0.1,0.1,0.1-0.1,0.1C55.6,69.4,55.6,69.3,55.6,69.2z" />
                    <path className="st5" d="M76.5,70.3c0,0,0.3-0.1,0.3,0c0,0,0.1,0.1,0.1,0.1c-0.1,0-0.2,0-0.3,0C76.6,70.4,76.6,70.3,76.5,70.3
                  C76.5,70.3,76.5,70.3,76.5,70.3z" />
                    <path className="st5" d="M79.1,71.2c0.1-0.3,0.5-0.3,0.8-0.2c-0.2,0.3,0,0.6,0.3,0.7c0,0.1,0.2,0.2,0.2,0.1c0.1,0,0.1-0.1,0.2-0.2
                  c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.1,0.1-0.3c0.2,0.1,0.2,0.4,0.3,0.5c0,0.1,0,0.2,0.1,0.2c0,0,0,0.1,0,0.1
                  c-0.1,0,0,0.1-0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0.4,0.6,1.1,0.2,1.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0-0.2,0.3-0.3,0.3
                  c-0.1,0-0.1,0.1-0.2,0.2c0,0.1-0.1,0.1-0.2,0.2c0-0.1-0.2-0.1-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.2-0.2-0.1
                  c-0.1,0.1-0.3-0.1-0.5-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c-0.2,0-0.2,0.1-0.3,0.2
                  c-0.1,0-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.2-0.2c0,0,0,0-0.1,0c0-0.1-0.1,0-0.2-0.1c-0.1,0-0.3-0.1-0.3,0.1c-0.2,0-0.4,0.1-0.6,0.2
                  c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0.1c-0.2,0-0.3,0.1-0.5,0.1c0,0-0.1-0.1-0.1-0.2c0.1,0,0.3-0.4,0.1-0.5c0-0.1,0-0.2,0-0.3
                  c0.1,0,0-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0.2-0.2,0.5-0.2,0.7-0.3c0.1,0,0.2,0,0.2-0.1
                  c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.4,0.6-0.6c0.2,0,0.2,0.1,0.3,0.1c0,0,0,0,0.1,0c0,0.1,0.1,0,0.1,0.1
                  C79.1,71.4,79.1,71.3,79.1,71.2z" />
                    <path className="st5" d="M60.5,58.1c0.1,0,0.3-0.1,0.3,0.1C60.7,58.1,60.5,58.2,60.5,58.1z" />
                    <path className="st5" d="M55.8,58.4c0.2,0,0.3,0,0.5,0C56.2,58.5,55.8,58.5,55.8,58.4z" />
                    <path className="st5" d="M61.1,58.4c0-0.1,0.1-0.1,0.2-0.1C61.3,58.5,61.1,58.4,61.1,58.4z" />
                    <path className="st5" d="M74.9,59.4c0-0.1,0.4-0.1,0.3,0C75.1,59.4,75,59.4,74.9,59.4z" />
                    <path className="st5" d="M54.8,62.1c0-0.1,0.4-0.1,0.3,0C55.1,62.1,54.9,62.1,54.8,62.1z" />
                    <path className="st5" d="M65.7,64c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1C65.7,64,65.7,64,65.7,64z" />
                    <path className="st5" d="M51.3,67.1c0,0.1,0,0.2-0.1,0.2C51.3,67.3,51.3,67.1,51.3,67.1z" />
                    <path className="st5" d="M52.8,67.6c0,0.1,0,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0-0.1,0.1-0.1,0.1C52.8,67.7,52.7,67.6,52.8,67.6z" />
                    <path className="st5" d="M78.1,70.4c0.2,0,0.1,0.2-0.1,0.1C78.1,70.5,78.1,70.5,78.1,70.4z" />
                    <path className="st5" d="M78.7,70.4c0.2,0,0.1,0.2-0.1,0.1C78.7,70.5,78.7,70.5,78.7,70.4z" />
                    <path className="st5" d="M64.1,72.7c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0.1c-0.1,0,0-0.3-0.1-0.5C64.1,72.6,64.1,72.6,64.1,72.7z" />
                    <path className="st5" d="M53.2,72.8c0.1,0,0.1,0.1,0.1,0.2c0,0,0,0-0.1,0C53.2,72.9,53.2,72.9,53.2,72.8z" />
                    <path className="st5" d="M53.2,73.3c0,0.1,0,0.2,0,0.2C53.1,73.5,53.2,73.4,53.2,73.3C53.2,73.3,53.2,73.3,53.2,73.3z" />
                    <path className="st5" d="M54.9,77.1c0-0.1,0.2,0,0.3-0.1c0,0,0,0,0,0.1C55.1,77.1,55,77.1,54.9,77.1z" />
                    <path className="st5" d="M47.7,59.5c0-0.1,0.1,0,0.2-0.1C47.9,59.5,47.8,59.5,47.7,59.5z" />
                    <path className="st5" d="M78.9,59.5c0.1,0,0.3-0.1,0.3,0.1C79.1,59.6,79,59.6,78.9,59.5C78.9,59.6,78.9,59.5,78.9,59.5z" />
                    <path className="st5" d="M46.7,59.7c0-0.1,0.2,0,0.3-0.1C46.9,59.8,46.7,59.7,46.7,59.7z" />
                    <path className="st5" d="M67.9,59.8c0-0.1,0.1,0,0.2-0.1C68.1,59.8,67.9,59.8,67.9,59.8z" />
                    <path className="st5" d="M80,59.8c0.1,0,0.2,0,0.2,0.1C80.1,59.8,80,59.8,80,59.8z" />
                    <path className="st5" d="M79.4,60.8c0,0.1,0,0.2-0.1,0.2C79.3,60.9,79.3,60.8,79.4,60.8z" />
                    <path className="st5" d="M51.7,62.4c-0.2-0.1,0.1-0.2,0.1-0.1C51.8,62.4,51.8,62.5,51.7,62.4z" />
                    <path className="st5" d="M63.5,63.1c0,0.1,0,0.2-0.1,0.2c0-0.1,0-0.1,0-0.2C63.4,63.1,63.4,63.1,63.5,63.1z" />
                    <path className="st5" d="M78.8,64.3c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0.1C78.9,64.3,78.8,64.3,78.8,64.3z" />
                    <path className="st5" d="M61.1,64.7c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0C61,64.9,61,64.7,61.1,64.7z" />
                    <path className="st5" d="M78.6,68.3c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0,0.1,0.1,0.2C78.7,68.3,78.7,68.3,78.6,68.3z" />
                    <path className="st5" d="M76.7,70.4c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0.1C76.9,70.4,76.6,70.5,76.7,70.4z" />
                    <path className="st5" d="M80.7,71.2c0,0.1,0,0.2,0,0.3c0,0,0,0-0.1,0C80.6,71.4,80.5,71.2,80.7,71.2z" />
                    <path className="st5" d="M79.8,75.1C79.8,75.1,79.8,75.1,79.8,75.1c-0.1,0.1,0,0.2-0.1,0.2C79.7,75.2,79.6,75,79.8,75.1
                  C79.8,75.1,79.8,75.1,79.8,75.1z" />
                    <path className="st5" d="M57.7,58.3c0-0.1,0.1,0,0.2-0.1C57.9,58.4,57.8,58.3,57.7,58.3z" />
                    <path className="st5" d="M68.7,61.8c0-0.1,0.2,0,0.2,0" />
                    <path className="st5" d="M55.5,62.4c0-0.1,0.1-0.1,0.1-0.1c0,0.1,0.1,0.1,0.1,0.1C55.7,62.4,55.6,62.4,55.5,62.4z" />
                    <path className="st5" d="M61.1,64.7c0-0.1,0-0.1,0.1-0.1c0,0.1,0,0.2-0.1,0.2C61.1,64.8,61.1,64.8,61.1,64.7z" />
                </g>
                <path className="st7" d="M85.6,61.5c0,11.3-9.2,20.4-20.5,20.4c-11.3,0-20.4-9.2-20.4-20.4c0-11.3,9.2-20.4,20.4-20.4
              C76.4,41,85.6,50.2,85.6,61.5z" />
                <path className="st7" d="M87.1,61.5c0,12.2-9.9,22-22,22c-12.2,0-22-9.9-22-22c0-12.2,9.9-22,22-22C77.3,39.5,87.1,49.3,87.1,61.5z" />
                <path className="st8" d="M95.5,61.7c0,17-13.8,30.7-30.7,30.7c-17,0-30.7-13.8-30.7-30.7c0-17,13.8-30.7,30.7-30.7
              C81.8,31,95.5,44.8,95.5,61.7z" />
                <g>
                    <path className="st2" d="M38.2,54.9L38,55.6l-0.5-0.1l0.6-2l0.5,0.1l-0.2,0.7l2.2,0.7l-0.2,0.5L38.2,54.9z" />
                    <path className="st2" d="M38.2,53.1l0.2-0.5l1,0.4l0.4-1l-1-0.4l0.2-0.5l2.6,1l-0.2,0.5l-1.2-0.5l-0.4,1l1.2,0.5l-0.2,0.5L38.2,53.1z" />
                    <path className="st2" d="M39.3,50.6l0.9-1.7l0.4,0.2L40,50.3l0.5,0.3l0.6-1.1l0.4,0.2l-0.6,1.1l0.7,0.3l0.6-1.3l0.5,0.2l-0.9,1.8
                  L39.3,50.6z" />
                    <path className="st2" d="M43.9,48.2c-0.4,0.7-1.1,0.7-1.9,0.2c-0.8-0.5-1-1.2-0.6-1.9c0.4-0.6,0.9-0.7,1.5-0.4l-0.3,0.4
                  c-0.3-0.1-0.5-0.1-0.7,0.2c-0.3,0.4-0.1,0.8,0.4,1.1c0.5,0.3,1,0.3,1.2-0.1c0.2-0.3,0.1-0.6-0.1-0.8l0.3-0.4
                  C44.2,47.1,44.3,47.7,43.9,48.2z" />
                    <path className="st2" d="M42.4,45.3l0.3-0.4l0.8,0.7l0.7-0.9L43.4,44l0.3-0.4l2.2,1.8l-0.3,0.4l-1-0.8L44,45.8l1,0.8L44.6,47
                  L42.4,45.3z" />
                    <path className="st2" d="M44.5,42.7l1.3,1.2c0.3,0.3,0.6,0.3,0.8,0.1c0.3-0.3,0.2-0.5-0.1-0.8L45.2,42l0.4-0.4l1.3,1.3
                  c0.5,0.5,0.5,1,0.1,1.5c-0.5,0.5-1,0.5-1.5,0l-1.3-1.3L44.5,42.7z" />
                    <path className="st2" d="M46,41.2l1-0.8c0.4-0.4,0.8-0.4,1.2,0c0.2,0.3,0.3,0.5,0.1,0.8c0.2-0.1,0.4,0,0.7,0.2l0.2,0.2
                  c0.1,0.1,0.2,0.2,0.3,0.2l0.1,0.1L49,42.3c-0.1,0-0.2-0.1-0.3-0.2l-0.2-0.2c-0.2-0.2-0.4-0.3-0.6-0.1l-0.4,0.4l0.7,0.8l-0.4,0.4
                  L46,41.2z M47.7,41.3c0.2-0.2,0.2-0.4,0.1-0.5c-0.2-0.2-0.3-0.2-0.5,0l-0.5,0.4l0.5,0.6L47.7,41.3z" />
                    <path className="st2" d="M50.6,41.1c-0.6,0.5-1.3,0.3-1.9-0.5c-0.6-0.8-0.5-1.5,0.2-2c0.6-0.4,1.1-0.3,1.5,0.2L50,39.2
                  c-0.2-0.2-0.5-0.3-0.8-0.1c-0.4,0.3-0.4,0.7,0,1.2c0.4,0.5,0.8,0.6,1.1,0.4c0.3-0.2,0.3-0.5,0.2-0.8l0.4-0.3
                  C51.3,40.1,51.2,40.7,50.6,41.1z" />
                    <path className="st2" d="M50.3,37.8l0.5-0.3l0.5,0.9l0.9-0.5L51.7,37l0.5-0.3l1.4,2.5l-0.5,0.3l-0.6-1.1l-0.9,0.5l0.6,1.1l-0.5,0.3
                  L50.3,37.8z" />
                    <path className="st2" d="M54.7,35.5c0.7-0.3,1.4,0.1,1.8,0.9c0.3,0.8,0.1,1.6-0.6,1.9c-0.7,0.3-1.4-0.1-1.8-0.9
                  C53.7,36.5,54,35.8,54.7,35.5z M55.6,37.8c0.4-0.2,0.6-0.6,0.3-1.2c-0.2-0.6-0.6-0.8-1.1-0.6c-0.4,0.2-0.5,0.6-0.3,1.2
                  C54.8,37.7,55.2,38,55.6,37.8z" />
                    <path className="st2" d="M56.4,34.9l1.8-0.5l0.1,0.5L57,35.2l0.2,0.6l1.1-0.3l0.1,0.5l-1.1,0.3l0.3,1.2l-0.5,0.2L56.4,34.9z" />
                    <path className="st2" d="M60.5,36.8L60,36.9l-0.5-2.8l1.2-0.2c0.5-0.1,0.9,0.2,1,0.8c0.1,0.6-0.1,0.9-0.6,1l-0.7,0.1L60.5,36.8z
                   M60.8,35.2c0.3,0,0.4-0.2,0.3-0.5c0-0.3-0.2-0.4-0.4-0.3l-0.6,0.1l0.1,0.8L60.8,35.2z" />
                    <path className="st2" d="M61.9,33.7l1.9-0.2l0,0.5l-1.4,0.1l0,0.6l1.3-0.1l0,0.5l-1.3,0.1l0.1,0.7l1.4-0.1l0,0.5l-2,0.2L61.9,33.7z" />
                    <path className="st2" d="M64.4,33.5l0.6,0l1,2l0-1.9l0.5,0l0,2.8l-0.6,0l-1-2l0,1.9l-0.5,0L64.4,33.5z" />
                    <path className="st2" d="M67.7,34.2l-0.8-0.1l0.1-0.5l2.1,0.2L69,34.3l-0.8-0.1l-0.3,2.3l-0.5-0.1L67.7,34.2z" />
                    <path className="st2" d="M69.4,33.9l1.9,0.4l-0.1,0.5l-1.4-0.3l-0.1,0.6l1.2,0.2l-0.1,0.5l-1.2-0.2l-0.1,0.7l1.4,0.3L70.8,37l-1.9-0.4
                  L69.4,33.9z" />
                    <path className="st2" d="M72,37.5c-0.7-0.2-1-0.9-0.8-1.8c0.3-0.9,0.9-1.3,1.7-1.1c0.7,0.2,0.9,0.7,0.8,1.3l-0.5-0.2
                  c0-0.3-0.1-0.5-0.4-0.6c-0.5-0.1-0.8,0.1-1,0.7c-0.2,0.6,0,1,0.4,1.1c0.3,0.1,0.6,0,0.7-0.3l0.5,0.2C73.2,37.4,72.7,37.7,72,37.5z
                  " />
                    <path className="st2" d="M75.6,35.6c0.7,0.3,1,1,0.6,1.9c-0.3,0.8-1,1.2-1.8,0.9c-0.7-0.3-1-1-0.6-1.9C74.2,35.6,74.8,35.3,75.6,35.6z
                   M74.6,37.9c0.4,0.2,0.8,0,1.1-0.6c0.2-0.6,0.1-1-0.3-1.2c-0.4-0.2-0.8,0-1.1,0.6C74.1,37.3,74.2,37.7,74.6,37.9z" />
                    <path className="st2" d="M76.6,38.3c-0.1,0.3,0,0.5,0.3,0.7c0.3,0.1,0.5,0.1,0.6-0.1c0.1-0.2,0-0.4-0.4-0.7c-0.2-0.1-0.3-0.3-0.4-0.5
                  c-0.1-0.2-0.1-0.4,0-0.7c0.3-0.5,0.7-0.6,1.3-0.3c0.5,0.3,0.7,0.8,0.5,1.3L78,37.7c0.1-0.3,0-0.5-0.3-0.6
                  c-0.3-0.1-0.5-0.1-0.6,0.1c-0.1,0.2,0.1,0.3,0.4,0.6c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0,0.7c-0.3,0.5-0.8,0.6-1.4,0.3
                  c-0.6-0.3-0.8-0.8-0.5-1.4L76.6,38.3z" />
                    <path className="st2" d="M79.5,38.3l-0.7-0.4l0.3-0.4l1.8,1.1L80.7,39L80,38.6l-1.3,2l-0.5-0.3L79.5,38.3z" />
                    <path className="st2" d="M82.4,39.6L81.3,41c-0.3,0.3-0.3,0.6,0,0.8c0.3,0.2,0.5,0.2,0.8-0.2l1.1-1.4l0.4,0.3l-1.2,1.4
                  c-0.4,0.5-1,0.6-1.5,0.2c-0.5-0.4-0.6-1-0.1-1.5l1.2-1.4L82.4,39.6z" />
                    <path className="st2" d="M82.4,42.6l0.4,0.4l-0.4,0.4L82,43L82.4,42.6z" />
                    <path className="st2" d="M83.5,43.3c-0.2,0.2-0.2,0.5,0.1,0.8c0.2,0.2,0.4,0.3,0.6,0.1c0.2-0.2,0.1-0.4-0.2-0.8
                  c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.2,0-0.4,0.3-0.7c0.4-0.4,0.9-0.3,1.3,0.1c0.4,0.4,0.5,0.9,0.1,1.3l-0.4-0.4
                  c0.2-0.2,0.2-0.4-0.1-0.7c-0.2-0.2-0.4-0.2-0.6-0.1c-0.2,0.2,0,0.3,0.2,0.7c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0,0.5-0.2,0.7
                  c-0.4,0.4-0.9,0.4-1.4-0.1c-0.5-0.5-0.5-1-0.1-1.4L83.5,43.3z" />
                    <path className="st2" d="M84.6,44.8l0.4,0.4l-0.4,0.4l-0.4-0.4L84.6,44.8z" />
                    <path className="st2" d="M87.8,45.1l-1.6,2.5l-0.4-0.4l0.3-0.5l-0.6-0.8l-0.6,0.2l-0.4-0.5l2.8-1L87.8,45.1z M86,45.7l0.5,0.6l0.7-1
                  L86,45.7z" />
                    <path className="st2" d="M86.7,47.4l0.3,0.4l-0.5,0.3l-0.3-0.4L86.7,47.4z" />
                    <path className="st2" d="M86.3,48.7c0.2,0.1,0.3,0.1,0.5,0c0,0,0,0,0,0l-0.2-0.2l0.5-0.3l0.3,0.5L87,48.9c-0.3,0.2-0.6,0.2-0.9-0.1
                  L86.3,48.7z" />
                    <path className="st2" d="M90.1,48.6l0.3,0.5l-2.5,1.4L87.6,50L90.1,48.6z" />
                    <path className="st2" d="M90.7,49.5l0.2,0.5l-1.3,1.8l1.8-0.9l0.2,0.5l-2.5,1.2l-0.2-0.5l1.3-1.8l-1.8,0.9l-0.2-0.5L90.7,49.5z" />
                    <path className="st2" d="M89.5,54.1c-0.3-0.7,0.1-1.4,0.9-1.7c0.9-0.3,1.6-0.1,1.9,0.7c0.2,0.6,0,1.1-0.5,1.4L91.6,54
                  c0.3-0.2,0.4-0.4,0.2-0.7c-0.2-0.4-0.6-0.6-1.2-0.3c-0.6,0.2-0.8,0.6-0.6,1c0.1,0.3,0.4,0.4,0.7,0.4l0.2,0.5
                  C90.3,55,89.8,54.8,89.5,54.1z" />
                    <path className="st2" d="M90.6,55.2l0.2,0.5l-0.6,0.2l-0.2-0.5L90.6,55.2z" />
                </g>
            </g>
            <g>
                <path className="st0" d="M220.6,27.5v18.7h29.7v9.7h-29.7v23.5h-11.4V17.9h44.9v9.6H220.6z" />
                <path className="st0" d="M263.7,22.9c-1.3-1.3-2-2.8-2-4.7c0-1.9,0.7-3.4,2-4.7c1.3-1.3,3-1.9,5-1.9c2,0,3.7,0.6,5,1.8
              c1.3,1.2,2,2.7,2,4.5c0,1.9-0.7,3.6-2,4.9s-3,2-5.1,2C266.8,24.7,265.1,24.1,263.7,22.9z M263.2,32.5h11v46.9h-11V32.5z" />
                <path className="st0" d="M329.5,37.1c3.6,3.5,5.4,8.6,5.4,15.4v26.9h-11V53.9c0-4.1-1-7.2-2.9-9.3c-1.9-2.1-4.7-3.1-8.3-3.1
              c-4,0-7.2,1.2-9.6,3.6c-2.3,2.4-3.5,5.9-3.5,10.5v23.7h-11V32.5h10.5v6.1c1.8-2.2,4.1-3.8,6.9-4.9c2.8-1.1,5.9-1.7,9.3-1.7
              C321.1,31.9,325.9,33.7,329.5,37.1z" />
                <path className="st0" d="M382.6,36.9c3.7,3.3,5.5,8.3,5.5,15v27.5h-10.4v-5.7c-1.3,2.1-3.3,3.6-5.8,4.7c-2.5,1.1-5.5,1.6-9,1.6
              s-6.6-0.6-9.2-1.8s-4.7-2.9-6.1-5c-1.4-2.1-2.2-4.6-2.2-7.2c0-4.2,1.6-7.6,4.7-10.1c3.1-2.5,8.1-3.8,14.8-3.8h12.1v-0.7
              c0-3.3-1-5.8-2.9-7.6c-2-1.8-4.9-2.6-8.7-2.6c-2.6,0-5.2,0.4-7.8,1.2c-2.5,0.8-4.7,2-6.5,3.4l-4.3-8c2.5-1.9,5.4-3.3,8.9-4.3
              c3.5-1,7.1-1.5,11-1.5C373.6,31.9,378.9,33.6,382.6,36.9z M372.5,70.1c2.1-1.3,3.6-3.1,4.5-5.4v-5.4h-11.3c-6.3,0-9.5,2.1-9.5,6.2
              c0,2,0.8,3.6,2.4,4.7c1.6,1.2,3.8,1.8,6.6,1.8C368,72,370.4,71.4,372.5,70.1z" />
                <path className="st0" d="M443,37.1c3.6,3.5,5.4,8.6,5.4,15.4v26.9h-11V53.9c0-4.1-1-7.2-2.9-9.3c-1.9-2.1-4.7-3.1-8.3-3.1
              c-4,0-7.2,1.2-9.6,3.6c-2.3,2.4-3.5,5.9-3.5,10.5v23.7h-11V32.5h10.5v6.1c1.8-2.2,4.1-3.8,6.9-4.9c2.8-1.1,5.9-1.7,9.3-1.7
              C434.6,31.9,439.4,33.7,443,37.1z" />
                <path className="st0" d="M471.2,76.9c-3.9-2-6.9-4.9-9-8.6c-2.2-3.7-3.2-7.8-3.2-12.4c0-4.6,1.1-8.8,3.2-12.4c2.2-3.6,5.2-6.5,9-8.5
              c3.8-2,8.2-3.1,13.1-3.1c4.6,0,8.7,0.9,12.2,2.8c3.5,1.9,6.1,4.6,7.9,8.1l-8.4,4.9c-1.3-2.2-3-3.8-5.1-4.9c-2-1.1-4.2-1.6-6.6-1.6
              c-4.1,0-7.5,1.3-10.2,4c-2.7,2.7-4,6.2-4,10.7c0,4.5,1.3,8,4,10.7c2.7,2.7,6.1,4,10.2,4c2.4,0,4.6-0.5,6.6-1.6
              c2-1.1,3.7-2.7,5.1-4.9l8.4,4.9c-1.8,3.5-4.5,6.2-8,8.1c-3.5,1.9-7.5,2.9-12.1,2.9C479.4,80,475,79,471.2,76.9z" />
                <path className="st0" d="M557,59.4h-36.8c0.6,3.5,2.3,6.2,5.1,8.2c2.7,2,6.1,3,10.1,3c5.2,0,9.4-1.7,12.7-5.1l5.9,6.8
              c-2.1,2.5-4.8,4.4-8,5.7c-3.2,1.3-6.9,1.9-10.9,1.9c-5.2,0-9.7-1-13.6-3.1c-3.9-2-7-4.9-9.1-8.6c-2.1-3.7-3.2-7.8-3.2-12.4
              c0-4.6,1-8.7,3.1-12.3c2.1-3.7,5-6.5,8.7-8.6c3.7-2,7.8-3.1,12.5-3.1c4.6,0,8.7,1,12.3,3c3.6,2,6.4,4.9,8.4,8.5c2,3.7,3,7.9,3,12.7
              C557.2,57,557.1,58,557,59.4z M524.5,43.9c-2.4,2.1-3.9,4.8-4.4,8.3h26.6c-0.5-3.4-1.9-6.1-4.3-8.3c-2.4-2.1-5.4-3.2-9-3.2
              C529.9,40.7,527,41.8,524.5,43.9z" />
            </g>
            <g>
                <path className="st9" d="M227.6,91.1l-3,14.8h-2l2.2-10.7l-7,8.9h-1L213,95l-2.2,10.9h-2l3-14.8h1.7l4.1,10.4l8.1-10.4H227.6z" />
                <path className="st9" d="M244.9,102.2H237l-2.4,3.7h-2.3l9.7-14.8h2.1l3.7,14.8h-2.1L244.9,102.2z M244.5,100.5l-1.8-7.3l-4.7,7.3
              H244.5z" />
                <path className="st9" d="M271.3,91.1l-3,14.8h-1.7L260,94.5l-2.3,11.4h-2.1l3-14.8h1.7l6.6,11.4l2.3-11.4H271.3z" />
                <path className="st9" d="M288.6,102.2h-7.9l-2.4,3.7H276l9.7-14.8h2.1l3.7,14.8h-2.1L288.6,102.2z M288.2,100.5l-1.8-7.3l-4.7,7.3
              H288.2z" />
                <path className="st9" d="M310.8,98.4h2l-1.2,5.8c-0.7,0.6-1.5,1-2.5,1.4c-0.9,0.3-1.9,0.5-2.9,0.5c-1.4,0-2.7-0.3-3.8-0.8
              c-1.1-0.5-1.9-1.3-2.5-2.2s-0.9-2-0.9-3.3c0-1.7,0.4-3.2,1.1-4.5c0.7-1.4,1.8-2.4,3.2-3.2c1.4-0.8,3-1.2,4.8-1.2
              c1.3,0,2.4,0.2,3.4,0.6c1,0.4,1.7,1,2.3,1.7l-1.5,1.3c-0.5-0.6-1.1-1-1.8-1.3c-0.7-0.3-1.5-0.4-2.5-0.4c-1.4,0-2.6,0.3-3.6,0.9
              c-1,0.6-1.9,1.4-2.4,2.5c-0.6,1.1-0.9,2.2-0.9,3.5c0,1.4,0.4,2.5,1.3,3.3c0.9,0.8,2.2,1.2,3.9,1.2c1.2,0,2.3-0.3,3.3-0.9
              L310.8,98.4z" />
                <path className="st9" d="M325.6,92.9l-0.9,4.6h7.5l-0.4,1.8h-7.5l-1,4.8h8.7l-0.4,1.8h-10.8l3-14.8h10.5l-0.4,1.8H325.6z" />
                <path className="st9" d="M359.3,91.1l-3,14.8h-2l2.2-10.7l-7,8.9h-1l-3.7-9.1l-2.2,10.9h-2l3-14.8h1.7l4.1,10.4l8.1-10.4H359.3z" />
                <path className="st9" d="M371,92.9l-0.9,4.6h7.5l-0.4,1.8h-7.5l-1,4.8h8.7l-0.4,1.8h-10.8l3-14.8h10.5l-0.4,1.8H371z" />
                <path className="st9" d="M401.7,91.1l-3,14.8H397l-6.6-11.4l-2.3,11.4H386l3-14.8h1.7l6.6,11.4l2.3-11.4H401.7z" />
                <path className="st9" d="M414.3,92.9h-5.1l0.4-1.8h12.3l-0.4,1.8h-5.1l-2.6,13h-2.1L414.3,92.9z" />
                <path className="st9" d="M439.2,105.6c-1-0.3-1.8-0.8-2.4-1.3l0.9-1.7c0.6,0.5,1.3,0.9,2.1,1.2c0.9,0.3,1.8,0.5,2.7,0.5
              c1.2,0,2.2-0.2,2.9-0.7c0.7-0.4,1.1-1.1,1.1-1.9c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.6-0.5-1-0.7s-1-0.4-1.7-0.6
              c-0.9-0.3-1.7-0.6-2.3-0.8c-0.6-0.3-1.1-0.6-1.5-1.1c-0.4-0.5-0.6-1.2-0.6-2c0-0.9,0.3-1.7,0.8-2.4s1.2-1.2,2.2-1.6s2-0.6,3.2-0.6
              c0.9,0,1.8,0.1,2.6,0.3c0.8,0.2,1.5,0.5,2.1,0.9l-0.8,1.7c-0.5-0.4-1.2-0.7-1.9-0.9s-1.4-0.3-2.2-0.3c-1.2,0-2.2,0.2-2.9,0.7
              s-1.1,1.1-1.1,1.9c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.5,1.1,0.7c0.4,0.2,1,0.4,1.8,0.6c0.9,0.3,1.7,0.5,2.3,0.8
              c0.6,0.2,1.1,0.6,1.5,1.1c0.4,0.5,0.6,1.1,0.6,1.9c0,0.9-0.3,1.8-0.8,2.5s-1.3,1.2-2.2,1.6c-0.9,0.4-2,0.6-3.3,0.6
              C441.3,106.1,440.3,105.9,439.2,105.6z" />
                <path className="st9" d="M462.9,100.8l-1,5.1h-2.1l1-5.2l-3.9-9.6h2.1l3.2,7.9l6.3-7.9h2.2L462.9,100.8z" />
                <path className="st9" d="M476.5,105.6c-1-0.3-1.8-0.8-2.4-1.3l0.9-1.7c0.6,0.5,1.3,0.9,2.1,1.2c0.9,0.3,1.8,0.5,2.7,0.5
              c1.2,0,2.2-0.2,2.9-0.7c0.7-0.4,1.1-1.1,1.1-1.9c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.6-0.5-1-0.7s-1-0.4-1.7-0.6
              c-0.9-0.3-1.7-0.6-2.3-0.8c-0.6-0.3-1.1-0.6-1.5-1.1c-0.4-0.5-0.6-1.2-0.6-2c0-0.9,0.3-1.7,0.8-2.4s1.2-1.2,2.2-1.6s2-0.6,3.2-0.6
              c0.9,0,1.8,0.1,2.6,0.3c0.8,0.2,1.5,0.5,2.1,0.9l-0.8,1.7c-0.5-0.4-1.2-0.7-1.9-0.9s-1.4-0.3-2.2-0.3c-1.2,0-2.2,0.2-2.9,0.7
              s-1.1,1.1-1.1,1.9c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.5,1.1,0.7c0.4,0.2,1,0.4,1.8,0.6c0.9,0.3,1.7,0.5,2.3,0.8
              c0.6,0.2,1.1,0.6,1.5,1.1c0.4,0.5,0.6,1.1,0.6,1.9c0,0.9-0.3,1.8-0.8,2.5s-1.3,1.2-2.2,1.6c-0.9,0.4-2,0.6-3.3,0.6
              C478.6,106.1,477.5,105.9,476.5,105.6z" />
                <path className="st9" d="M499.1,92.9H494l0.4-1.8h12.3l-0.4,1.8h-5.1l-2.6,13h-2.1L499.1,92.9z" />
                <path className="st9" d="M516.2,92.9l-0.9,4.6h7.5l-0.4,1.8h-7.5l-1,4.8h8.7l-0.4,1.8h-10.8l3-14.8h10.5l-0.4,1.8H516.2z" />
                <path className="st9" d="M549.9,91.1l-3,14.8h-2l2.2-10.7l-7,8.9h-1l-3.7-9.1l-2.2,10.9h-2l3-14.8h1.7l4.1,10.4l8.1-10.4H549.9z" />
            </g>
            <line className="st10" x1="199.5" y1={14} x2="199.5" y2={110} />
        </svg>

    )
}

export default Logo
