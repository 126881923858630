import React, {useEffect, useState} from 'react'
import axios from "axios";
import Config from "../../util/config";

const DocContent = (props) => {
  let propData = props.data;
  // console.log(data);
    let marked = window.marked;
  
  const [article, setArticle] = useState({});
  const [details, setDetails] = useState("");
  const [images, setImages] = useState([]);

  const getActionData = () => {
    axios.get(Config.apiserver+"getarticledata/?mdl_id="+propData.mdl_id+"&&article_id="+propData.article_id)
        .then(res => {
          console.log(atob(res.data.data.details))
          setArticle(res.data.data);
          setDetails(atob(res.data.data.details));
          setImages(res.data.images);
        })
        .catch(error => console.log(error))
  }

  useEffect(() => {
      getActionData()
  }, []);
  
  return (
    <>
    <div className="col-lg-9 mt-4 mt-lg-0">
            <article className="docs-content">
                <p style={{ fontWeight: "bold", fontSize: "16px",color: "#333" }}>{article.title}</p>
                <div dangerouslySetInnerHTML={{ __html: marked.parse(details) }} />
              {/*<h1 className="mb-1">Welcome</h1>*/}
              {/*<p>Get started with this powerful theme based on Bootstrap that follows the latest design system*/}
              {/*  principles so you can supercharge your development and design workflow today.</p>*/}
              {/*<hr className="my-4 my-lg-5" />*/}
              {/*<h2 id="licenses">Licenses</h2>*/}
              {/*<p>Currently, on Bootstrap Themes you can get the products with two types of licenses: Single or*/}
              {/*  Extended. If you are making a purchase, be sure to go through the table with the rights and the*/}
              {/*  guidelines, so you can know what*/}
              {/*  is the best fit for you. View the rights table and the description for each license on our by*/}
              {/*  clicking the button below.</p>*/}
              {/*<p><a href="https://themes.getbootstrap.com/licenses/" className target="_blank">See licenses</a></p>*/}
              {/*<h2 id="download">Download</h2>*/}
              {/*<p>In order to get started and use quick, you will have to get a license from our official distributor,*/}
              {/*  Bootstrap Themes. Click the button below and then proceed to the next steps. After making your*/}
              {/*  purchase you will receive*/}
              {/*  an email with the download link or you can access it from your account.</p>*/}
              {/*<p><a href="https://themes.getbootstrap.com/product/quick-website-ui-kit-2/" className="btn btn-primary" target="_blank">Purchase now</a></p>*/}
            </article>
          </div>

    </>
  )
}

export default DocContent
